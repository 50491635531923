import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Endpoints } from "../../../api/EndPoints";
import axios from "axios";
import { toast } from "react-toastify";

const initialState: any = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null,
  data: [],
};

export const GetMerchantInformation = createAsyncThunk(
  "merchantinformation",
  async (credential: any, thunkAPI) => {
    const data = {
      merchandId: credential.merchandId,
      businessName: credential.businessName,
    };
    try {
      const response = await axios.get(
        Endpoints.ManageRates.MerchantInformation,
        {
          params: {
            merchandId: data.merchandId,
            businessName: data.businessName,
          },
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // toast.success("SUCCESS Merchant Information"); 
      return response.data;
    } catch (error: any) {
      // toast.error("ERROR Merchant Information");
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const getMerchantInformationSlice = createSlice({
  name: "merchantinformation",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetMerchantInformation.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        GetMerchantInformation.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = null;
          state.data = action.payload;
        }
      )
      .addCase(
        GetMerchantInformation.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
          state.data = [];
        }
      );
  },
});

export default getMerchantInformationSlice.reducer;

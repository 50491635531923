import React, { useState, useEffect } from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Typography,
  CardMedia,
  Divider,
  Button,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ClearIcon from "@mui/icons-material/Clear";
import { FilterIcon } from "../../../../common/assets/icons";
import { ValidateTopup } from "../../../../api/Data";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ValidateTopupModal from "../../../modals/ValidateTopupModal";

const Row = ({ key, row, title }: any) => {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openValidateTopupModal, setOpenValidateTopupModal] = useState(false);
  const [credentials, setCredentials] = React.useState({});

  const handleCloseValidateTopupModal = () => setOpenValidateTopupModal(false);

  const handleValidateTopup = async (userId: any, status: string) => {
    setLoading(true);
    setOpenValidateTopupModal(true);
      const credentials = {
        topId: userId,
        status: status,
      };
    setCredentials(credentials)
  };
  return (
    <React.Fragment>
      <TableRow
        key={row.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {row.transactionId === null ? "N/A" : row.transactionId}
        </TableCell>
        <TableCell align="left">
          <Box>{row.businessName === null ? "N/A" : row.businessName}</Box>
        </TableCell>
        <TableCell align="left">
          {row.amount === null ? "N/A" : row.amount}
        </TableCell>{" "}
        {title === "Recent Topup List" && (
          <TableCell align="left">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "left",
                width: "100px",
              }}
            >
              {row.status === "VALID" ? (
                <Box
                  sx={{
                    fontSize: "11px",
                    borderRadius: "10px",
                    padding: "2px 10px",
                    backgroundColor: "#F2F0F9",
                    display: "flex",
                    alignItems: "center",
                    width: "100px",
                    marginBottom: "2px",
                    color: "#6E6893",
                  }}
                >
                  <Box
                    sx={{
                      height: "7px",
                      width: "7px",
                      borderRadius: "50%",
                      backgroundColor: "#6E6893",
                      marginRight: "5px",
                    }}
                  ></Box>
                  {row.status}
                </Box>
              ) : row.status === "NOVALID" ? (
                <Box
                  sx={{
                    fontSize: "11px",
                    borderRadius: "10px",
                    padding: "2px 10px",
                    backgroundColor: "lightgray",
                    display: "flex",
                    alignItems: "center",
                    width: "100px",
                    marginBottom: "2px",
                    color: "grey",
                  }}
                >
                  <Box
                    sx={{
                      height: "8px",
                      width: "8px",
                      borderRadius: "50%",
                      backgroundColor: "grey",
                      marginRight: "5px",
                    }}
                  ></Box>
                  N/A
                </Box>
              ) : (
                <Box
                  sx={{
                    fontSize: "11px",
                    borderRadius: "10px",
                    padding: "2px 10px",
                    backgroundColor: "#D8FFD8",
                    display: "flex",
                    alignItems: "center",
                    width: "100px",
                    marginBottom: "2px",
                    color: "#07751A",
                  }}
                >
                  <Box
                    sx={{
                      height: "8px",
                      width: "8px",
                      borderRadius: "50%",
                      backgroundColor: "#07751A",
                      marginRight: "5px",
                    }}
                  ></Box>
                  {row.status}
                </Box>
              )}
              <Box sx={{ fontSize: "12px" }}>
                <b>Date:</b> {row.validateAt}
              </Box>
            </Box>
          </TableCell>
        )}
        <TableCell align="center">
          {row.account === null ? "N/A" : row.account}
        </TableCell>
        <TableCell align="right">
          {row.channel === null ? "N/A" : row.channel}
        </TableCell>
        <TableCell align="right">
          {row.dateRequest === null ? "N/A" : row.dateRequest}
        </TableCell>
        <TableCell align="center">
          {row.country === null ? "N/A" : row.country}
        </TableCell>
        {title === "Topup Request List" && (
          <TableCell align="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpenCollapse(!openCollapse)}
            >
              {openCollapse ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <Box sx={{ margin: "20px auto" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  width: "400px",
                  marginLeft: "auto",
                }}
              >
                  <Button
                    sx={{
                      color: "#F59E0B",
                      height: "35px",
                      border: "solid 1px #F59E0B",
                      backgroundColor: "#FFF",
                      "&:hover": {
                        backgroundColor: "whitesmoke", // Change this to your desired hover color
                      },
                    }}
                    fullWidth
                    onClick={() => handleValidateTopup(row.id, "REJECTED")}
                  >
                    Reject
                  </Button>
                  <Button
                    sx={{
                      height: "35px",
                      backgroundColor: "#F59E0B",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#E18D00", // Change this to your desired hover color
                      },
                    }}
                    fullWidth
                    onClick={() => handleValidateTopup(row.id, "APPROVED")}
                  >
                    Approve
                  </Button>
              </Box>
            </Box>
            <ValidateTopupModal
              open={openValidateTopupModal}
              handleCloseValidateTopupModal={handleCloseValidateTopupModal}
              credentials={credentials}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const TopupTable = ({ data, title }: any) => {
  const [rows, setRow] = useState<any>([]);
  const [count, setCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState<any>("");
  const [clearField, setClearField] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    const row = data;
    setRow(row);
  }, [data]);

  // PAGINATION
  const handleChange = (e: any) => {
    const selectedPage = parseInt(e.target.value);
    setCurrentPage(selectedPage);
  };
  const getTotalPages = () => Math.ceil(rows.length / itemsPerPage);

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const filterObjects = () => {
    const filtered: any = data.filter((object: any) => {
      return object.transactionId
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
    });
    setRow(filtered);
  };

  useEffect(() => {
    filterObjects();
  }, [count]);

  const handleSearch = () => {
    filterObjects();
    setClearField(true);
  };
  const clearSearchField = () => {
    setSearchQuery("");
    setCount((prevCount) => prevCount + 1);
    setClearField(false);
  };
  // Handling input change in the search bar
  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default form submission behavior
      handleSearch(); // Call the search function when Enter key is pressed
    }
  };
  return (
    <Box sx={{ boxShadow: 1 }}>
      <ToastContainer />
      <Box
        sx={{
          padding: "15px",
          display: "flex",
          gap: "1rem",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Typography
          sx={{ textAlign: "left", fontSize: "25px", fontWeight: 500 }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: { xs: "100%", md: "400px" },
          }}
        >
          {/* Search Bar */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
              marginLeft: "auto",
              width: "300px",
              // padding: "3px 0px 3px 2px",
              position: "relative",
            }}
          >
            {" "}
            <FormControl fullWidth>
              <OutlinedInput
                id="outlined-adornment-amount"
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyDown={handleKeyPress} // Add this line to handle key press
                startAdornment={
                  <InputAdornment position="start">
                    <SearchRoundedIcon
                      onClick={handleSearch}
                      sx={{
                        cursor: "pointer",
                      }}
                    />
                  </InputAdornment>
                }
                sx={{ height: "40px" }}
              />
            </FormControl>
            {clearField ? (
              <ClearIcon
                onClick={clearSearchField}
                sx={{
                  cursor: "pointer",
                  position: "absolute",
                  left: "248px",
                }}
              />
            ) : (
              ""
            )}
          </Box>
          {/* Search Bar */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "110px",
              height: "40px",
              border: "1px solid black",
              borderRadius: "6px",
              padding: "1px 4px",
              cursor: "pointer",
            }}
          >
            {" "}
            <CardMedia
              component="img"
              height="20px"
              image={FilterIcon}
              alt="Icon"
              sx={{
                objectFit: "contain",
                width: "20px",
              }}
            />{" "}
            <Typography>Filter</Typography>{" "}
          </Box>
        </Box>
      </Box>
      <Divider />
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          overflowX: "auto",
          borderRadius: "0",
          border: "none",
          boxShadow: 0,
        }}
      >
        <Table aria-label="collapsible table" sx={{ padding: 0, margin: 0 }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#FCDFAE1A", textTransform: "" }}>
              <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>
                Transaction ID
              </TableCell>
              <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>
                Business Name
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Amount
              </TableCell>
              {title === "Recent Topup List" && (
                <TableCell
                  align="center"
                  sx={{ fontWeight: 600, fontSize: "14px" }}
                >
                  Status
                </TableCell>
              )}
              <TableCell
                align="right"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Account
              </TableCell>
              <TableCell
                align="right"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Channel
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Req. Date
              </TableCell>
              <TableCell
                align="right"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Country
              </TableCell>
              {title === "Topup Request List" && (
                <TableCell
                  align="center"
                  sx={{ fontWeight: 600, fontSize: "14px" }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
              )
              .map((row: any) => (
                <Row key={row.email} row={row} title={title} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          gap: "20px",
          fontSize: "13px",
          backgroundColor: "#FCDFAE1A",
          color: "#6E6893",
          padding: "10px 0",
          borderRadius: " 0 0 10px 10px",
        }}
      >
        <Typography sx={{ fontWeight: 600, fontSize: "13px" }}>
          Rows per page: {itemsPerPage}
        </Typography>
        <Box>
          <select
            id="pageDropdown"
            onChange={handleChange}
            value={currentPage}
            style={{ border: "none", width: "17px" }}
          >
            {Array.from({ length: getTotalPages() }).map(
              (_: any, index): any => (
                <option key={index} value={index + 1}>
                  {index + 1}
                </option>
              )
            )}
          </select>
        </Box>
        <label htmlFor="pageDropdown">
          {currentPage} of {getTotalPages()}
        </label>
        <Button disabled={currentPage === 1} onClick={goToPreviousPage}>
          <NavigateBeforeIcon />
        </Button>
        <Button
          disabled={currentPage === getTotalPages()}
          onClick={goToNextPage}
        >
          <NavigateNextIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default TopupTable;

import { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  TextField,
  Paper,
  Modal,
  Divider,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// STYLE MODAL
const styleModal = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
  borderRadius: 2,
};
// STYLED TABLE
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const DocumentList: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState(1);
  const [noData, setNoData] = useState<any>(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [clearField, setClearField] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalDocumentName, setmodalDocumentName] = useState("");
  const [modalDocumentType, setmodalDocumentType] = useState("");
  const [modalDocumentStatus, setmodalDocumentStatus] = useState("");
  const [modalDocumentUploadDate, setmodalDocumentUploadDate] = useState("");
  const [modalDocumentActivationDate, setmodalDocumentActivationDate] =
    useState("");
  const [modalDocumentDescription, setmodalDocumentDescription] = useState("");
  const [modalDocumentComment, setmodalDocumentComment] = useState("");
  const [modalDocumentId, setmodalDocumentId] = useState("");
  const [activeState, setActiveState] = useState(false);
  const [activationMessage, setActivationMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Set the number of items per page according to your requirement

  const userToken = localStorage.getItem("token");
  const token = userToken;
  const fetchDocumentListData = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        "http://74.208.84.251:9016/api/v1/documents/list",
        { headers }
      );
      setData(response.data.datas.items);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setNoData(true);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDocumentListData();
  }, [count]);

  const getTotalPages = () => Math.ceil(data.length / itemsPerPage);

  const filterObjects = () => {
    const filtered: any = data.filter((object: any) => {
      return object.document_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
    });
    setData(filtered);
  };

  useEffect(() => {
    filterObjects();
  }, [count]);
  const handleSearch = () => {
    filterObjects();
    setClearField(true);
  };
  const clearSearchField = () => {
    setSearchQuery("");

    setCount((prevCount) => prevCount + 1);
    setClearField(false);
  };
  // Handling input change in the search bar
  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const handleOpen = (
    documentName: any,
    documentType: any,
    documentStatus: any,
    uploadDate: any,
    activationDate: any,
    documentDescription: any,
    documentID: any,
    documentCommentaire: any
  ) => {
    setmodalDocumentName(documentName);
    setmodalDocumentType(documentType);
    setmodalDocumentStatus(documentStatus);
    setmodalDocumentUploadDate(uploadDate);
    setmodalDocumentActivationDate(activationDate);
    setmodalDocumentDescription(documentDescription);

    setmodalDocumentId(documentID);
    setmodalDocumentComment(documentCommentaire);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const handleUnhide = () => setActiveState(true);
  const handleHide = () => setActiveState(false);

  const handleActivationChange = (event: any) => {
    setActivationMessage(event.target.value);
    console.log(event.target.value);
  };

  const handleActivation = async (documentId: any) => {
    setLoadingButton(true);
    const data = {
      documentId: documentId,
      status: "approved",
      commentaire: activationMessage,
    };
    console.log(documentId);
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      // Make the POST request
      const response = await axios.post(
        "http://74.208.84.251:9016/api/v1/documents/valide",
        data,
        {
          headers,
        }
      );
      // Handle the response
      toast.success("Document Activation Successful");
      console.log(response.data);
      setLoadingButton(false);
      window.location.reload();
      setActivationMessage("");
    } catch (error) {
      // Handle the error
      console.error("Error:", error);
      toast.error("Something Went Wrong");
      setLoadingButton(false);
    }
  };

  const handleDeactivation = async (documentId: any) => {
    setLoadingButton(true);
    const data = {
      documentId: documentId,
      status: "rejected",
      commentaire: activationMessage,
    };

    console.log(documentId);
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      // Make the POST request
      const response = await axios.post(
        "http://74.208.84.251:9016/api/v1/documents/valide",
        data,
        {
          headers,
        }
      );
      // Handle the response
      toast.success("Document Deactivation Successful");
      console.log(response.data);
      setLoadingButton(false);
      setActivationMessage("");
      window.location.reload();
    } catch (error) {
      // Handle the error
      toast.error("Something Went Wrong");
      console.error("Error:", error);
      setLoadingButton(false);
    }
  };

  const goToPage = (page: number) => {
    setCurrentPage(page);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      <ToastContainer />
      <Box
        sx={{
          width: "100%",
          height: "3.5rem",
          boxShadow: 1,
          backgroundColor: "white",
          borderLeft: "orange 12px solid",
          borderRadius: 2,
          display: "flex",
          marginBottom: "1rem",
        }}
      >
        <Typography variant="h6" sx={{ margin: "auto 30px", fontWeight: 500 }}>
          DOCUMENT OVERVIEW
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          boxShadow: 1,
          backgroundColor: "white",
          borderRadius: 2,
          display: "grid",
          gridTemplateColumns: "repeat(4,1fr)",
          gridGap: 10,
          marginBottom: 7,
          padding: 1,
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            height: "150px",
            boxShadow: 1,
            borderRadius: 1,
            textAlign: "left",
            display: "flex",
          }}
        >
          <Box sx={{ margin: "auto auto 28px 30px" }}>
            <Typography
              variant="h4"
              sx={{
                position: "relative",
                "::after": {
                  content: "''",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "5px",
                  background: "orange",
                },
              }}
            >
              4400000
            </Typography>
            <Typography sx={{ fontSize: "14.5px", marginTop: "8px" }}>
              Total Document
            </Typography>
          </Box>
        </Box>{" "}
        <Box
          sx={{
            backgroundColor: "white",
            height: "150px",
            boxShadow: 1,
            borderRadius: 1,
            textAlign: "left",
            display: "flex",
          }}
        >
          <Box sx={{ margin: "auto auto 28px 30px" }}>
            <Typography
              variant="h4"
              sx={{
                position: "relative",
                "::after": {
                  content: "''",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "5px",
                  background: "green",
                },
              }}
            >
              440
            </Typography>
            <Typography sx={{ fontSize: "14.5px", marginTop: "8px" }}>
              Active Document
            </Typography>
          </Box>
        </Box>{" "}
        <Box
          sx={{
            backgroundColor: "white",
            height: "150px",
            boxShadow: 1,
            borderRadius: 1,
            textAlign: "left",
            display: "flex",
          }}
        >
          <Box sx={{ margin: "auto auto 28px 30px" }}>
            <Typography
              variant="h4"
              sx={{
                position: "relative",
                "::after": {
                  content: "''",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "5px",
                  background: "gray",
                },
              }}
            >
              440
            </Typography>
            <Typography sx={{ fontSize: "14.5px", marginTop: "8px" }}>
              Pending Document
            </Typography>
          </Box>
        </Box>{" "}
        <Box
          sx={{
            backgroundColor: "white",
            height: "150px",
            boxShadow: 1,
            borderRadius: 1,
            textAlign: "left",
            display: "flex",
          }}
        >
          <Box sx={{ margin: "auto auto 28px 30px" }}>
            <Typography
              variant="h4"
              sx={{
                position: "relative",
                "::after": {
                  content: "''",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "5px",
                  background: "red",
                },
              }}
            >
              440
            </Typography>
            <Typography sx={{ fontSize: "14.5px", marginTop: "8px" }}>
              Blocked Document
            </Typography>
          </Box>
        </Box>{" "}
      </Box>
      {/* TABLE DOCUMENTS LIST */}
      <Box>
        <Box
          sx={{
            width: "100%",
            height: "3.5rem",
            boxShadow: 1,
            backgroundColor: "white",
            borderLeft: "orange 12px solid",
            borderRadius: 2,
            display: "flex",
            marginBottom: "1rem",
          }}
        >
          <Typography
            variant="h6"
            sx={{ margin: "auto 30px", fontWeight: 500 }}
          >
            DOCUMENT LIST
          </Typography>
        </Box>
        {/* Search Bar */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "10px",
            marginLeft: "auto",
            backgroundColor: "#f6f6f6",
            width: "300px",
            padding: "3px 0px 3px 2px",
            position: "relative",
          }}
        >
          <Box>
            <TextField
              id="input-with-icon-textfield"
              label="Search"
              value={searchQuery}
              onChange={handleSearchChange}
              variant="outlined"
            />
          </Box>
          <Button
            variant="contained"
            sx={{ cursor: "pointer", marginLeft: "5px" }}
            onClick={handleSearch}
          >
            <SearchIcon sx={{ cursor: "pointer", fontSize: "40px" }} />
          </Button>{" "}
          {clearField ? (
            <ClearIcon
              onClick={clearSearchField}
              sx={{
                cursor: "pointer",
                position: "absolute",
                left: "195px",
              }}
            />
          ) : (
            ""
          )}
        </Box>
        {/* Search Bar */}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Document Name</StyledTableCell>
                <StyledTableCell>Document Type</StyledTableCell>
                <StyledTableCell>Activation Status</StyledTableCell>
                <StyledTableCell>Date of Submission</StyledTableCell>
                <StyledTableCell>Date of Activation</StyledTableCell>
                <StyledTableCell>View</StyledTableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>
                    <CircularProgress />
                  </StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </StyledTableRow>
              </TableBody>
            ) : noData ? (
              <TableBody
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ margin: "20px auto" }}>NO DATA</Typography>
              </TableBody>
            ) : (
              <TableBody>
                {data
                  .slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  )
                  .map((item: any, index: any) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row" key={index}>
                        {item.document_name}
                      </StyledTableCell>
                      <StyledTableCell key={index}>
                        {item.document_type}
                      </StyledTableCell>{" "}
                      <StyledTableCell key={index}>
                        {item.document_status}
                      </StyledTableCell>
                      <StyledTableCell key={index}>
                        {item.upload_date}
                      </StyledTableCell>
                      <StyledTableCell key={index}>
                        {item.activation_date}
                      </StyledTableCell>
                      <StyledTableCell key={index}>
                        <Button
                          onClick={() =>
                            handleOpen(
                              String(item.document_name),
                              String(item.document_type),
                              String(item.document_status),
                              String(item.upload_date),
                              String(item.activation_date),
                              String(item.document_description),
                              String(item.documentId),
                              String(item.comment)
                            )
                          }
                        >
                          OPEN
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            )}
          </Table>
          <Divider />
          <Box sx={{ padding: "0.8rem" }}>
            <Button disabled={currentPage === 1} onClick={goToPreviousPage}>
              Previous Page
            </Button>
            {Array.from({ length: getTotalPages() }).map((_, index) => (
              <Button
                key={index}
                onClick={() => goToPage(index + 1)}
                disabled={currentPage === index + 1}
              >
                {index + 1}
              </Button>
            ))}
            <Button
              disabled={currentPage === getTotalPages()}
              onClick={goToNextPage}
            >
              Next Page
            </Button>
          </Box>
        </TableContainer>
        <Modal
          // key={modalIndex}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleModal}>
            <Box sx={{ marginBottom: "2rem" }}>
              {activeState ? (
                <Typography sx={{ fontWeight: 600, marginBottom: "0.7rem" }}>
                  {modalDocumentStatus === "ACCEPTED"
                    ? "Deactivate Account"
                    : "Activate Account"}
                </Typography>
              ) : (
                <Typography sx={{ fontWeight: 600, marginBottom: "0.7rem" }}>
                  Document Information
                </Typography>
              )}
              {activeState ? (
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      sm: "repeat(1, 1fr)",
                    },
                    gridGap: "1rem",
                  }}
                >
                  <TextField
                    id="outlined-basic"
                    label="Message"
                    variant="outlined"
                    value={activationMessage}
                    onChange={handleActivationChange}
                  />{" "}
                  <Typography
                    sx={{
                      color: "grey",
                      margin: "-5px 0px 0px 5px",
                      fontSize: "12px",
                    }}
                  >
                    {modalDocumentStatus === "ACCEPTED"
                      ? "Enter your reason for Deactivating Account"
                      : "Enter your reason for Activating Account"}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "1rem",
                    }}
                  >
                    {modalDocumentStatus === "ACCEPTED" ? (
                      <Box>
                        {loadingButton ? (
                          <Button
                            variant="contained"
                            color="error"
                            sx={{
                              marginRight: "5px",
                              width: "110px",
                              height: "37px",
                            }}
                          >
                            <CircularProgress size={20} color="inherit" />
                          </Button>
                        ) : (
                          <Button
                            onClick={() => handleDeactivation(modalDocumentId)}
                            variant="contained"
                            color="error"
                            sx={{ marginRight: "5px" }}
                          >
                            Deactivate
                          </Button>
                        )}
                        <Button
                          onClick={handleHide}
                          variant="contained"
                          color="primary"
                        >
                          Cancel
                        </Button>
                      </Box>
                    ) : (
                      <Box>
                        {loadingButton ? (
                          <Button
                            variant="contained"
                            color="success"
                            sx={{
                              marginRight: "5px",
                              width: "110px",
                              height: "37px",
                            }}
                          >
                            <CircularProgress size={20} color="inherit" />
                          </Button>
                        ) : (
                          <Button
                            onClick={() => handleActivation(modalDocumentId)}
                            variant="contained"
                            color="success"
                            sx={{ marginRight: "5px" }}
                          >
                            Activate
                          </Button>
                        )}
                        <Button
                          onClick={handleHide}
                          variant="contained"
                          color="primary"
                        >
                          Cancel
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        sm: "repeat(1, 1fr)",
                        md: "repeat(2, 1fr)",
                      },
                      gridGap: "1rem",
                    }}
                  >
                    {" "}
                    {modalDocumentId === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Document ID:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Document ID:"
                        value={modalDocumentId}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalDocumentName === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Document Name:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Document Name:"
                        value={modalDocumentName}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalDocumentType === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Document Type:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Document Type:"
                        value={modalDocumentType}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalDocumentUploadDate === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Upload Date:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Upload Date:"
                        value={modalDocumentUploadDate}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalDocumentActivationDate === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Activation Date:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Activation Date:"
                        value={modalDocumentActivationDate}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalDocumentDescription === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Document Description:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Document Description:"
                        value={modalDocumentDescription}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalDocumentStatus === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Status:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Status:"
                        value={modalDocumentStatus}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalDocumentComment === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Document Comment:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Document Comment:"
                        value={modalDocumentComment}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "1rem",
                    }}
                  >
                    {modalDocumentStatus === "ACCEPTED" ? (
                      <Button
                        // onClick={() => handleDeactivation(modalDocumentId)}
                        onClick={handleUnhide}
                        variant="contained"
                        color="error"
                      >
                        Deactivate
                      </Button>
                    ) : (
                      <Button
                        // onClick={() => handleActivation(modalDocumentId)}
                        onClick={handleUnhide}
                        variant="contained"
                        color="success"
                      >
                        Activate
                      </Button>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default DocumentList;

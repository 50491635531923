export const MerchantIcon = `${process.env.PUBLIC_URL}/assets/images/merchant.svg`;
export const ActiveIcon = `${process.env.PUBLIC_URL}/assets/images/active.svg`;
export const InactiveIcon = `${process.env.PUBLIC_URL}/assets/images/inactive.svg`;
export const SuspendedIcon = `${process.env.PUBLIC_URL}/assets/images/suspended.svg`;


export const TogoIcon = `${process.env.PUBLIC_URL}/assets/images/togo.svg`;
export const BeninIcon = `${process.env.PUBLIC_URL}/assets/images/benin.svg`;
export const CotedivoireIcon = `${process.env.PUBLIC_URL}/assets/images/cotedivoire.svg`;
export const FilterIcon = `${process.env.PUBLIC_URL}/assets/images/filter.svg`;
export const LoginArrow = `${process.env.PUBLIC_URL}/assets/images/loginarrow.svg`;
export const QLogo = `${process.env.PUBLIC_URL}/assets/images/QOSPAY.png`;
export const individualBT = `${process.env.PUBLIC_URL}/assets/images/individualBT.png`;
export const registeredBT = `${process.env.PUBLIC_URL}/assets/images/registeredBT.png`;
export const noBT = `${process.env.PUBLIC_URL}/assets/images/noBT.png`;
export const activeKYC = `${process.env.PUBLIC_URL}/assets/images/activeKYC.png`;
export const inactiveKYC = `${process.env.PUBLIC_URL}/assets/images/inactiveKYC.png`;
export const activeStatus = `${process.env.PUBLIC_URL}/assets/images/activeStatus.png`;
export const inactiveStatus = `${process.env.PUBLIC_URL}/assets/images/inactiveStatus.png`;
export const suspendedStatus = `${process.env.PUBLIC_URL}/assets/images/suspendedStatus.png`;
export const verifiedEmail = `${process.env.PUBLIC_URL}/assets/images/verifiedEmail.png`;
export const unverifiedEmail = `${process.env.PUBLIC_URL}/assets/images/unverifiedEmail.png`;
export const twoFALock = `${process.env.PUBLIC_URL}/assets/images/2FALock.png`;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Endpoints } from "../../../api/EndPoints";
import axios from "axios";
import { toast } from "react-toastify";

const initialState: any = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null,
  data: [],
}; 
// Helper function to convert JSON data to CSV
const convertToCSV = (data: any) => {
  const array = [Object.keys(data[0])].concat(data);

  return array
    .map((row) => {
      return Object.values(row).toString();
    })
    .join("\n");
};

// Helper function to remove specific columns from the data
const removeColumns = (data: any[], columnsToRemove: string[]): any[] => {
  return data.map((row) => {
    const newRow = { ...row };
    columnsToRemove.forEach((col) => delete newRow[col]);
    return newRow;
  });
};

export const GetTransactionLogDownload = createAsyncThunk(
  "transactionlogdownload",
  async (credential: any) => {
    try {
      const response = await axios.post(
        Endpoints.Transaction.TransactionLogDownload,
        {
          operator: credential.operator,
          operationType: credential.operationType,
          status: credential.status,
          startDate: credential.startDate,
          endDate: credential.endDate,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.data.datas.length === 0) {
        toast.success("No Transaction Found");
      } else {
        toast.success("Transaction Found");
      }
      return response.data.datas;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (error.response && error.response.data.message) {
        return error.response.data.message;
      } else {
        return error.message;
      }
    }
  }
);

export const transactionLogDownloadSlice = createSlice({
  name: "transactionlogdownload",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetTransactionLogDownload.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        GetTransactionLogDownload.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = null;
          state.data = action.payload; 
            // Specify columns to remove
            const columnsToRemove = ["transactionid", "processingnumber", "status", "specialfield1", "narration"];
          
            // Remove specified columns
            const filteredData = removeColumns(action.payload, columnsToRemove);
          // Trigger the download
          if (action.payload.length > 0) {
            const csvData = convertToCSV(filteredData);
            const blob = new Blob([csvData], {
              type: "text/csv",
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Transactions ${sessionStorage.getItem("transactionlogstartDate")} - ${sessionStorage.getItem("transactionlogendDate")}.csv`); // Specify the file name
            document.body.appendChild(link);
            link.click(); 
            // Ensure the link has a parent node before removing it
            if (link.parentNode) {
              link.parentNode.removeChild(link);
            } 
            window.URL.revokeObjectURL(url);
          }
        }
      )
      .addCase(
        GetTransactionLogDownload.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
          state.data = [];
        }
      );
  },
});

export default transactionLogDownloadSlice.reducer;

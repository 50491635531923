import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Endpoints } from "../../../api/EndPoints";
import axios from "axios";
import { toast } from "react-toastify";

const initialState: any = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null,
  data: null,
};

export const GetTransaction = createAsyncThunk(
  "transaction",
  async (credential: any) => {
    try {
      const response = await axios.get(
        `${Endpoints.Transaction.Transaction}/${credential.transactionId}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Transaction Found");
      return response.data.datas;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (error.response && error.response.data.message) {
        return error.response.data.message;
      } else {
        return error.message;
      }
    }
  }
);

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetTransaction.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        GetTransaction.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = null;
          state.data = action.payload;
        }
      )
      .addCase(GetTransaction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
        state.data = null;
      });
  },
});

export default transactionSlice.reducer;

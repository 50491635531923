import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Endpoints } from "../../../api/EndPoints";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
const initialState: any = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null,
  data: [],
}; 

export const Validate2FA = createAsyncThunk(
  "validate2fa",
  async (credential: any, thunkAPI) => {
    try {
      const response = await axios.post(
        `${Endpoints.Auth.Validate2FA}/${credential.username}/${credential.code}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      ); 
      toast.success("SUCCESS: Validate2FA");
      return response.data;
    } catch (error: any) {
      console.log(error.response.data.message);
      if (error.response.data.message === "Code is incorrect") {
        toast.error("Code is incorrect");
      } else {
        toast.error("ERROR: ", error.response.data.message);
      }
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const validate2FASlice = createSlice({
  name: "validate2fa",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(Validate2FA.pending, (state) => {
        state.loading = true;
      })
      .addCase(Validate2FA.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(Validate2FA.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
        state.data = [];
      });
  },
});

export default validate2FASlice.reducer;

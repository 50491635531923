import React, { useState, useEffect } from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Typography,
  CardMedia,
  Divider,
  Button,
  Skeleton,
  CircularProgress,
  TextField,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Link } from "react-router-dom";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ClearIcon from "@mui/icons-material/Clear";
import { ROUTES } from "../../../constants/RouteData";
import { FilterIcon } from "../../../../common/assets/icons";
import { UpdateMerchantRate } from "../../../../state/feautures/manageRates/updateMerchantRateSlice";
import { useAppDispatch, useAppSelector } from "../../../../state/hooks";
type EditModes = {
  [key: string]: boolean;
};
const Row = ({ key, row, title }: any) => {
  const dispatch = useAppDispatch();
  const updateMerchantRateData: any = useAppSelector(
    (state) => state.updateMerchantRate
  );
  console.log("updateMerchantRateData", updateMerchantRateData);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [editModes, setEditModes] = useState<EditModes>({
    requestPayment: true,
    topup: true,
    makeDeposit: true,
    transfer: true,
    cardPayment: true,
    refund: true,
    chargeBack: true,
    payout: true,
    // Add other sections as needed
  });
  const [requestData, setRequestData] = useState("");
  const [topupData, setTopupData] = useState("");
  const [makeDeopsitData, setMakeDeopsitData] = useState("");
  const [transferData, setTransferData] = useState("");
  const [refundData, setRefundData] = useState("");
  const [cardPaymentData, setCardPaymentData] = useState("");
  const [chargeBackData, setchargeBackData] = useState("");
  const [payoutData, setPayoutData] = useState("");
  // Function to toggle edit mode for a specific section
  // const toggleEditMode = (section: any) => {
  //   setEditModes((prevModes: any) => ({
  //     ...prevModes,
  //     [section]: !prevModes[section],
  //   }));
  // };
  // Function to toggle edit mode for all section
  const toggleAllEditModes = () => {
    const updatedModes: EditModes = {};
    for (const section in editModes) {
      updatedModes[section] = !editModes[section];
    }
    setEditModes(updatedModes);
  };
  const handleSetMerchant = (
    merchandId: any,
    clientId: any,
    businessName: any,
    contactInformation: any,
    type: any,
    country: any,
    operator: any
  ) => {
    const credentials = {
      merchandId: merchandId,
      clientId: clientId,
      businessName: businessName,
      contactInformation: contactInformation,
      type: type,
      country: country,
      requestPayment: requestData,
      makeDeposit: makeDeopsitData,
      cardPayment: cardPaymentData,
      chargeBack: chargeBackData,
      topUp: topupData,
      transfert: transferData,
      refund: refundData,
      payout: payoutData,
      modifiedAt: "string",
      modifiedBy: "string",
      operator: operator,
    };
    dispatch(UpdateMerchantRate(credentials));
  };
  return (
    <React.Fragment>
      <TableRow
        key={row.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {row.merchandId === null ? "N/A" : row.merchandId}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.clientId === null ? "N/A" : row.clientId}
        </TableCell>
        {/* <TableCell>{row.name}{row.email}</TableCell> */}
        <TableCell align="left">
          <Box>{row.businessName === null ? "N/A" : row.businessName}</Box>
        </TableCell>
        <TableCell
          align="left"
          // sx={{ display: "flex", flexDirection: "column" }}
        >
          {/* <Box> */}
          {row.contactInformation === null ? (
            "N/A"
          ) : (
            <b>{row.contactInformation}</b>
          )}
          {/* </Box> */}
          {/* <Box>{row.contactInformation}</Box> */}
          {/* <Box>{data.userProfile}</Box> */}
        </TableCell>
        <TableCell align="center">
          {row.type === "individual" ? (
            <Box
              sx={{
                backgroundColor: "#F59E0B",
                padding: "3px 10px",
                border: "1px solid #F59E0B",
                borderRadius: "10px",
                color: "#FFEEE5",
                textAlign: "center",
              }}
            >
              IND
            </Box>
          ) : row.type === null ? (
            <Box
              sx={{
                backgroundColor: "lighgrey",
                padding: "3px 10px",
                border: "1px solid grey",
                borderRadius: "10px",
                color: "grey",
                textAlign: "center",
              }}
            >
              N/A
            </Box>
          ) : (
            <Box
              sx={{
                backgroundColor: "#FFEEE5",
                padding: "3px 10px",
                border: "1px solid #F59E0B",
                borderRadius: "10px",
                color: "#F59E0B",
                textAlign: "center",
              }}
            >
              REG
            </Box>
          )}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.operator === null ? "N/A" : row.operator}
        </TableCell>
        <TableCell align="center">
          {row.country === null ? "N/A" : row.country}
        </TableCell>
        <TableCell align="center">
          {row.modifiedBy === null ? "N/A" : row.modifiedBy}
        </TableCell>
        <TableCell align="center">
          {row.modifiedAt === null ? "N/A" : row.modifiedAt}
        </TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenCollapse(!openCollapse)}
          >
            {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <Box sx={{ padding: "20px 0" }}>
              <Typography>Set Fees</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                gap: "50px",
                // margin: "20px auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  // margin: "20px auto",
                  flexDirection: "column",
                  gap: "50px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                    }}
                  >
                    Request Payment
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      width: "200px",
                      marginLeft: "auto",
                      alignItems: "center",
                    }}
                  >
                    {editModes.requestPayment ? (
                      <Typography sx={{ color: "#000" }}>
                        {row.requestPayment === null
                          ? "N/A"
                          : row.requestPayment}
                      </Typography>
                    ) : (
                      <TextField
                        id="outlined-number"
                        label="Set fee"
                        type="text"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        value={requestData}
                        onChange={(e) => setRequestData(e.target.value)}
                      />
                    )}
                    {/*<Button
                      sx={{
                        width: "100px",
                        backgroundColor: "#F59E0B",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#E18D00", // Change this to your desired hover color
                        },
                      }}
                      fullWidth
                      onClick={() => toggleEditMode("requestPayment")}
                    >
                      {editModes.requestPayment ? "edit" : "cancel"}
                    </Button>*/}
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                    }}
                  >
                    Topup
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      width: "200px",
                      marginLeft: "auto",
                      alignItems: "center",
                    }}
                  >
                    {editModes.topup ? (
                      <Typography sx={{ color: "#000" }}>
                        {row.topUp === null ? "N/A" : row.topUp}
                      </Typography>
                    ) : (
                      <TextField
                        id="outlined-number"
                        label="Set fee"
                        type="text"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        value={topupData}
                        onChange={(e) => setTopupData(e.target.value)}
                      />
                    )}
                    {/*<Button
                      sx={{
                        width: "100px",
                        backgroundColor: "#F59E0B",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#E18D00", // Change this to your desired hover color
                        },
                      }}
                      fullWidth
                      onClick={() => toggleEditMode("topup")}
                    >
                      {editModes.topup ? "edit" : "cancel"}
                    </Button>*/}
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                    }}
                  >
                    Make Deposit
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      width: "200px",
                      marginLeft: "auto",
                      alignItems: "center",
                    }}
                  >
                    {editModes.makeDeposit ? (
                      <Typography sx={{ color: "#000" }}>
                        {row.makeDeposit === null ? "N/A" : row.makeDeposit}
                      </Typography>
                    ) : (
                      <TextField
                        id="outlined-number"
                        label="Set fee"
                        type="text"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        value={makeDeopsitData}
                        onChange={(e) => setMakeDeopsitData(e.target.value)}
                      />
                    )}
                    {/*<Button
                      sx={{
                        width: "100px",
                        backgroundColor: "#F59E0B",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#E18D00", // Change this to your desired hover color
                        },
                      }}
                      fullWidth
                      onClick={() => toggleEditMode("makeDeposit")}
                    >
                      {editModes.makeDeposit ? "edit" : "cancel"}
                    </Button>*/}
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                    }}
                  >
                    Transfer
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      width: "200px",
                      marginLeft: "auto",
                      alignItems: "center",
                    }}
                  >
                    {editModes.transfer ? (
                      <Typography sx={{ color: "#000" }}>
                        {row.transfert === null ? "N/A" : row.transfert}
                      </Typography>
                    ) : (
                      <TextField
                        id="outlined-number"
                        label="Set fee"
                        type="text"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        value={transferData}
                        onChange={(e) => setTransferData(e.target.value)}
                      />
                    )}
                    {/*<Button
                      sx={{
                        width: "100px",
                        backgroundColor: "#F59E0B",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#E18D00", // Change this to your desired hover color
                        },
                      }}
                      fullWidth
                      onClick={() => toggleEditMode("transfer")}
                    >
                      {editModes.transfer ? "edit" : "cancel"}
                    </Button>*/}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  flexDirection: "column",
                  gap: "10px",
                  // margin: "20px auto",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                    }}
                  >
                    Card Payment
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      width: "200px",
                      marginLeft: "auto",
                      alignItems: "center",
                    }}
                  >
                    {editModes.cardPayment ? (
                      <Typography sx={{ color: "#000" }}>
                        {row.cardPayment === null ? "N/A" : row.cardPayment}
                      </Typography>
                    ) : (
                      <TextField
                        id="outlined-number"
                        label="Set fee"
                        type="text"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        value={cardPaymentData}
                        onChange={(e) => setCardPaymentData(e.target.value)}
                      />
                    )}
                    {/*<Button
                      sx={{
                        width: "100px",
                        backgroundColor: "#F59E0B",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#E18D00", // Change this to your desired hover color
                        },
                      }}
                      fullWidth
                      onClick={() => toggleEditMode("cardPayment")}
                    >
                      {editModes.cardPayment ? "edit" : "cancel"}
                    </Button>*/}
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                    }}
                  >
                    Refund
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      width: "200px",
                      marginLeft: "auto",
                      alignItems: "center",
                    }}
                  >
                    {editModes.refund ? (
                      <Typography sx={{ color: "#000" }}>
                        {row.refund === null ? "N/A" : row.refund}
                      </Typography>
                    ) : (
                      <TextField
                        id="outlined-number"
                        label="Set fee"
                        type="text"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        value={refundData}
                        onChange={(e) => setRefundData(e.target.value)}
                      />
                    )}
                    {/*<Button
                      sx={{
                        width: "100px",
                        backgroundColor: "#F59E0B",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#E18D00", // Change this to your desired hover color
                        },
                      }}
                      fullWidth
                      onClick={() => toggleEditMode("refund")}
                    >
                      {editModes.refund ? "edit" : "cancel"}
                    </Button>*/}
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                    }}
                  >
                    Charge Back
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      width: "200px",
                      marginLeft: "auto",
                      alignItems: "center",
                    }}
                  >
                    {editModes.chargeBack ? (
                      <Typography sx={{ color: "#000" }}>
                        {row.chargeBack === null ? "N/A" : row.chargeBack}
                      </Typography>
                    ) : (
                      <TextField
                        id="outlined-number"
                        label="Set fee"
                        type="text"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        onChange={(e) => setchargeBackData(e.target.value)}
                        value={chargeBackData}
                      />
                    )}
                    {/*<Button
                      sx={{
                        width: "100px",
                        backgroundColor: "#F59E0B",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#E18D00", // Change this to your desired hover color
                        },
                      }}
                      fullWidth
                      onClick={() => toggleEditMode("chargeBack")}
                    >
                      {editModes.chargeBack ? "edit" : "cancel"}
                    </Button>*/}
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                    }}
                  >
                    Payout
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      width: "200px",
                      marginLeft: "auto",
                      alignItems: "center",
                    }}
                  >
                    {editModes.payout ? (
                      <Typography sx={{ color: "#000" }}>
                        {row.payout === null ? "N/A" : row.payout}
                      </Typography>
                    ) : (
                      <TextField
                        id="outlined-number"
                        label="Set fee"
                        type="text"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                        onChange={(e) => setPayoutData(e.target.value)}
                        value={payoutData}
                      />
                    )}
                    {/*<Button
                      sx={{
                        width: "100px",
                        backgroundColor: "#F59E0B",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#E18D00", // Change this to your desired hover color
                        },
                      }}
                      fullWidth
                      onClick={() => toggleEditMode("payout")}
                    >
                      {editModes.payout ? "edit" : "cancel"}
                    </Button>*/}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                margin: "20px",
                width: "400px",
                marginLeft: "auto",
                marginRight: "0",
                gap: "20px",
              }}
            >
              <Button
                sx={{
                  backgroundColor: "#F59E0B",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#F59E0B", // Change this to your desired hover color
                  },
                }}
                fullWidth
                onClick={toggleAllEditModes}
              >
                {editModes.payout ? "Edit" : "Cancel"}
              </Button>
              <Button
                sx={{
                  backgroundColor: "#F59E0B",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#F59E0B", // Change this to your desired hover color
                  },
                }}
                fullWidth
                onClick={() =>
                  handleSetMerchant(
                    row.merchandId,
                    row.clientId,
                    row.businessName,
                    row.contactInformation,
                    row.type,
                    row.country,
                    row.operator
                  )
                }
              >
                Set Fee
              </Button>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
const MerchantRates = ({ data, title }: any) => {
  const [rows, setRow] = useState<any>([]);
  const [count, setCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState<any>("");
  const [clearField, setClearField] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  useEffect(() => {
    if (Array.isArray(data)) {
      setRow(data);
    }
  }, [data]);

  // PAGINATION
  const handleChange = (e: any) => {
    const selectedPage = parseInt(e.target.value);
    setCurrentPage(selectedPage);
  };
  const getTotalPages = () => Math.ceil(rows.length / itemsPerPage);

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // const filterObjects = () => {
  //   const filtered: any = data.filter((object: any) => {
  //     return object.businessName
  //       .toLowerCase()
  //       .includes(searchQuery.toLowerCase());
  //   });
  //   setRow(filtered);
  // };

  // useEffect(() => {
  //   filterObjects();
  // }, [count]);

  // const handleSearch = () => {
  //   filterObjects();
  //   setClearField(true);
  // };
  // const clearSearchField = () => {
  //   setSearchQuery("");
  //   setCount((prevCount) => prevCount + 1);
  //   setClearField(false);
  // };
  // // Handling input change in the search bar
  // const handleSearchChange = (event: any) => {
  //   setSearchQuery(event.target.value);
  // };

  // const handleKeyPress = (event: any) => {
  //   if (event.key === "Enter") {
  //     event.preventDefault(); // Prevent default form submission behavior
  //     handleSearch(); // Call the search function when Enter key is pressed
  //   }
  // };
  return (
    <Box sx={{ boxShadow: 1 }}>
      <Box
        sx={{
          padding: "15px",
          display: "flex",
          gap: "1rem",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Typography
          sx={{ textAlign: "left", fontSize: "25px", fontWeight: 500 }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: { xs: "100%", md: "400px" },
          }}
        >
          {/* Search Bar */}
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
              marginLeft: "auto",
              width: "300px",
              // padding: "3px 0px 3px 2px",
              position: "relative",
            }}
          >
            {" "}
            <FormControl fullWidth>
              <OutlinedInput
                id="outlined-adornment-amount"
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyDown={handleKeyPress} // handle key press
                startAdornment={
                  <InputAdornment position="start">
                    <SearchRoundedIcon
                      onClick={handleSearch}
                      sx={{
                        cursor: "pointer",
                      }}
                    />
                  </InputAdornment>
                }
                sx={{ height: "40px" }}
              />
            </FormControl>
            {clearField ? (
              <ClearIcon
                onClick={clearSearchField}
                sx={{
                  cursor: "pointer",
                  position: "absolute",
                  left: "248px",
                }}
              />
            ) : (
              ""
            )}
          </Box> */}
          {/* Search Bar */}
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "110px",
              height: "40px",
              border: "1px solid black",
              borderRadius: "6px",
              padding: "1px 4px",
              cursor: "pointer",
            }}
          >
            {" "}
            <CardMedia
              component="img"
              height="20px"
              image={FilterIcon}
              alt="Icon"
              sx={{
                objectFit: "contain",
                width: "20px",
              }}
            />{" "}
            <Typography>Filter</Typography>{" "}
          </Box> */}
        </Box>
      </Box>
      <Divider />
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          overflowX: "auto",
          borderRadius: "0",
          border: "none",
          boxShadow: 0,
        }}
      >
        <Table aria-label="collapsible table" sx={{ padding: 0, margin: 0 }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#FCDFAE1A", textTransform: "" }}>
              <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>
                Merchant ID
              </TableCell>
              <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>
                Client ID
              </TableCell>
              <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>
                Business Name
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Contact Information
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Type
              </TableCell>
              <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>
                Operator
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Country
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Modified By
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Modified At
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
              )
              .map((row: any) => (
                <Row key={row.email} row={row} title={title} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          gap: "20px",
          fontSize: "13px",
          backgroundColor: "#FCDFAE1A",
          color: "#6E6893",
          padding: "10px 0",
          borderRadius: " 0 0 10px 10px",
        }}
      >
        <Typography sx={{ fontWeight: 600, fontSize: "13px" }}>
          Rows per page: {itemsPerPage}
        </Typography>
        <Box>
          <select
            id="pageDropdown"
            onChange={handleChange}
            value={currentPage}
            style={{ border: "none", width: "17px" }}
          >
            {Array.from({ length: getTotalPages() }).map(
              (_: any, index): any => (
                <option key={index} value={index + 1}>
                  {index + 1}
                </option>
              )
            )}
          </select>
        </Box>
        <label htmlFor="pageDropdown">
          {currentPage} of {getTotalPages()}
        </label>
        <Button disabled={currentPage === 1} onClick={goToPreviousPage}>
          <NavigateBeforeIcon />
        </Button>
        <Button
          disabled={currentPage === getTotalPages()}
          onClick={goToNextPage}
        >
          <NavigateNextIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default MerchantRates;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Endpoints } from "../../../api/EndPoints";
import axios from "axios";
import { toast } from "react-toastify";

const initialState: any = {
  isAuthenticated: false, 
  loading: false,
  error: null,
  data: [],
};

export const SignIn = createAsyncThunk(
  "signin",
  async (credential: any, thunkAPI) => {
    delete axios.defaults.headers.common["Authorization"];
    try {
      const response = await axios.post(
        Endpoints.Auth.signin,
        {
          password: credential.password,
          rememberMe: credential.rememberMe,
          username: credential.username,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      ); 
      const userToken = response.data.datas.token;
      localStorage.setItem("token", userToken);
      localStorage.setItem("userEmail", credential.username);
      if (localStorage.getItem("token")) {
        return response.data;
      }
    } catch (error: any) {
      toast.error("ERROR: Signin");
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const signInSlice = createSlice({
  name: "signin",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(SignIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(SignIn.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(SignIn.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
        state.data = [];
      });
  },
});

export default signInSlice.reducer;

import Index from "../../components/pages/dashboard/Index";
const Dashboard: React.FC = () => {

  return (
    <>
      <Index />
    </>
  );
};

export default Dashboard;

import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Modal,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import axios from "axios";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// STYLE MODAL
const styleModal = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
  borderRadius: 2,
};
// STYLED TABLE
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const IndividualCustomers: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [individualCustomerData, setIndividualCustomerData] = useState<any>([]);
  const [noData, setNoData] = useState<any>(false);
  const [count, setCount] = useState(1);
  const [searchIndividualQuery, setSearchIndividualQuery] = useState("");
  const [clearIndividualField, setClearIndividualField] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeState, setActiveState] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [activationMessage, setActivationMessage] = useState("");
  // INDIVIDUAL STATES
  const [individualActivityDescription, setIndividualActivityDescription] =
    useState("");
  const [individualCategory, setIndividualCategory] = useState("");
  const [individualCompanyName, setIndividualCompanyName] = useState("");
  const [individualCountry, setIndividualCountry] = useState("");
  const [individualDateOfBirth, setIndividualDateOfBirth] = useState("");
  const [individualDescription, setIndividualDescription] = useState("");
  const [
    individualDocumentIdentificationUrl,
    setIndividualDocumentIdentificationUrl,
  ] = useState("");
  const [individualDocumentUrl, setIndividualDocumentUrl] = useState("");
  const [individualEmail, setIndividualEmail] = useState("");
  const [individualFirstName, setIndividualFirstName] = useState("");
  const [individualIdentificationNumber, setIndividualIdentificationNumber] =
    useState("");
  const [individualIndividualId, setIndividualIndividualId] = useState("");
  const [individualIndustry, setIndividualIndustry] = useState("");
  const [individualLastName, setIndividualLastName] = useState("");
  const [individualmerchantnumbermoov, setIndividualmerchantnumbermoov] =
    useState("");
  const [individualmtnphonenumber, setIndividualmtnphonenumber] = useState("");
  const [individualNationality, setIndividualNationality] = useState("");
  const [individualOfficeAddress, setIndividualOfficeAddress] = useState("");
  const [individualPayAccountList, setIndividualPayAccountList] = useState("");
  const [individualPhoneNumber, setIndividualPhoneNumber] = useState("");
  const [individualsmaFacebook, setIndividualsmaFacebook] = useState("");
  const [individualsmaInstagram, setIndividualsmaInstagram] = useState("");
  const [individualsmaTwitter, setIndividualsmaTwitter] = useState("");
  const [individualstaffSize, setIndividualstaffSize] = useState("");
  const [individualstate, setIndividualstate] = useState("");
  const [individualtradingName, setIndividualtradingName] = useState("");
  const [individualtypeIdentification, setIndividualtypeIdentification] =
    useState("");
  const [individualuserId, setIndividualuserId] = useState("");
  const [individualwebSite, setIndividualwebSite] = useState("");
  const [individualIndex, setIndividualIndex] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Set the number of items per page according to your requirement

  // Get User Auth Token
  const userToken = localStorage.getItem("token");
  const token = userToken;

  // INDIVIDUAL START
  const handleOpenIndividual = (
    activityDescription: any,
    category: any,
    companyname: any,
    country: any,
    dateOfBirth: any,
    description: any,
    documentIdentificationUrl: any,
    documentUrl: any,
    email: any,
    firstname: any,
    identificationNumber: any,
    individualId: any,
    industry: any,
    lastname: any,
    merchantnumbermoov: any,
    mtnphonenumber: any,
    nationality: any,
    officeAddress: any,
    payAccountList: any,
    phoneNumber: any,
    smaFacebook: any,
    smaInstagram: any,
    smaTwitter: any,
    staffSize: any,
    state: any,
    tradingName: any,
    typeIdentification: any,
    userId: any,
    webSite: any,
    index: any
  ) => {
    setOpen(true);
    setIndividualActivityDescription(activityDescription);
    setIndividualCategory(category);
    setIndividualCompanyName(companyname);
    setIndividualCountry(country);
    setIndividualDateOfBirth(dateOfBirth);
    setIndividualDescription(description);
    setIndividualDocumentIdentificationUrl(documentIdentificationUrl);
    setIndividualEmail(email);
    setIndividualFirstName(firstname);
    setIndividualIdentificationNumber(identificationNumber);
    setIndividualIndividualId(individualId);
    setIndividualIndustry(industry);
    setIndividualLastName(lastname);
    setIndividualmerchantnumbermoov(merchantnumbermoov);
    setIndividualmtnphonenumber(mtnphonenumber);
    setIndividualNationality(nationality);
    setIndividualOfficeAddress(officeAddress);
    setIndividualPayAccountList(payAccountList);
    setIndividualPhoneNumber(phoneNumber);
    setIndividualsmaFacebook(smaFacebook);
    setIndividualsmaInstagram(smaInstagram);
    setIndividualsmaTwitter(smaTwitter);
    setIndividualstaffSize(staffSize);
    setIndividualstate(state);
    setIndividualtradingName(tradingName);
    setIndividualtypeIdentification(typeIdentification);
    setIndividualuserId(userId);
    setIndividualwebSite(webSite);
    setIndividualIndex(index);
  };
  const handleCloseIndividual = () => setOpen(false);
  const handleUnhide = () => setActiveState(true);
  const handleHide = () => setActiveState(false);
  // INDIVIDUAL CUSTOMERS API CALL
  const fetchIndividualCustomerData = async () => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        "http://74.208.84.251:9016/api/v1/customer/individualCustomers",
        { headers }
      );
      setIndividualCustomerData(response.data.datas);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setNoData(true);
      setLoading(false);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchIndividualCustomerData();
  }, [count]);
  const filterIndividualObjects = () => {
    const filtered: any = individualCustomerData.filter((object: any) => {
      return object.email
        .toLowerCase()
        .includes(searchIndividualQuery.toLowerCase());
    });
    setIndividualCustomerData(filtered);
  };

  useEffect(() => {
    filterIndividualObjects();
  }, [count]);

  const handleIndividualSearch = () => {
    filterIndividualObjects();
    setClearIndividualField(true);
  };
  const clearSearchIndividualField = () => {
    setSearchIndividualQuery("");
    setCount((prevCount) => prevCount + 1);
    setClearIndividualField(false);
  };
  // Handling input change in the search bar
  const handleSearchIndividualChange = (event: any) => {
    setSearchIndividualQuery(event.target.value);
  };

  const handleActivation = (userId: any) => {
    const data = {
      userId: userId,
      status: "approved",
      commentaire: activationMessage,
    };

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    // Make the POST request
    axios
      .post("http://74.208.84.251:9016/api/v1/customer/activeCustomers", data, {
        headers,
      })
      .then((response) => {
        // Handle the response
        toast.success("Account Activation Successful");
        setLoadingButton(false);
        console.log("Response:", response.data);
        window.location.reload();
      })
      .catch((error) => {
        // Handle the error
        setLoadingButton(false);
        toast.error("Something Went Wrong");
        console.error("Error:", error);
      });
  };
  const handleDeactivation = (userId: any) => {
    const data = {
      userId: userId,
      status: "rejected",
      commentaire: activationMessage,
    };
    const token =
      "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJyZW1lbWJlcnlvQHlvcG1haWwuY29tIiwiaWF0IjoxNjg1NzA1OTYyLCJleHAiOjE2ODU3MDc3NjJ9.RvvLKKgfkxtzjh_8g6-jCzc-hGScCdlShwkh3ckj2u8";
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    // Make the POST request
    axios
      .post("http://74.208.84.251:9016/api/v1/customer/activeCustomers", data, {
        headers,
      })
      .then((response) => {
        // Handle the response
        toast.success("Account Deactivation Successful");
        setLoadingButton(false);
        console.log("Response:", response.data);
        window.location.reload();
      })
      .catch((error) => {
        // Handle the error
        setLoadingButton(false);
        toast.error("Something Went Wrong");
        console.error("Error:", error);
      });
  };
  const handleActivationChange = (event: any) => {
    setActivationMessage(event.target.value);
    console.log(event.target.value);
  };

  const getTotalPages = () =>
    Math.ceil(individualCustomerData.length / itemsPerPage);

  const goToPage = (page: number) => {
    setCurrentPage(page);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          height: "3.5rem",
          boxShadow: 1,
          backgroundColor: "white",
          borderLeft: "orange 12px solid",
          borderRadius: 2,
          display: "flex",
          marginBottom: "1rem",
        }}
      >
        <Typography variant="h6" sx={{ margin: "auto 30px", fontWeight: 500 }}>
          INDIVIDUAL CUSTOMERS LIST
        </Typography>
      </Box>
      {/* Search Bar */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "10px",
          marginLeft: "auto",
          backgroundColor: "#f6f6f6",
          width: "300px",
          padding: "3px 0px 3px 2px",
          position: "relative",
        }}
      >
        <Box>
          <TextField
            id="input-with-icon-textfield"
            label="Search"
            value={searchIndividualQuery}
            onChange={handleSearchIndividualChange}
            variant="outlined"
          />
        </Box>
        <Button
          variant="contained"
          sx={{ cursor: "pointer", marginLeft: "5px" }}
          onClick={handleIndividualSearch}
        >
          <SearchIcon sx={{ cursor: "pointer", fontSize: "40px" }} />
        </Button>{" "}
        {clearIndividualField ? (
          <ClearIcon
            onClick={clearSearchIndividualField}
            sx={{
              cursor: "pointer",
              position: "absolute",
              left: "195px",
            }}
          />
        ) : (
          ""
        )}
      </Box>
      {/* Search Bar */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Company Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Phone Number</StyledTableCell>
              <StyledTableCell>Country</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>View</StyledTableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <StyledTableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell>
                  <CircularProgress />
                </StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </StyledTableRow>
            </TableBody>
          ) : noData ? (
            <TableBody
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ margin: "20px auto" }}>NO DATA</Typography>
            </TableBody>
          ) : (
            <TableBody>
              {individualCustomerData
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((item: any, index: any): any => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row" key={index}>
                      {item.companyname}
                    </StyledTableCell>
                    <StyledTableCell key={index}>{item.email}</StyledTableCell>
                    <StyledTableCell key={index}>
                      {item.mtnphonenumber}
                    </StyledTableCell>
                    <StyledTableCell key={index}>
                      {item.country}
                    </StyledTableCell>
                    <StyledTableCell key={index}>{item.state}</StyledTableCell>
                    <StyledTableCell key={index}>
                      <Button
                        onClick={() =>
                          handleOpenIndividual(
                            String(item.activityDescription),
                            String(item.category),
                            String(item.companyname),
                            String(item.country),
                            String(item.dateOfBirth),
                            String(item.description),
                            String(item.documentIdentificationUrl),
                            String(item.documentUrl),
                            String(item.email),
                            String(item.firstname),
                            String(item.identificationNumber),
                            String(item.individualId),
                            String(item.industry),
                            String(item.lastname),
                            String(item.merchantnumbermoov),
                            String(item.mtnphonenumber),
                            String(item.nationality),
                            String(item.officeAddress),
                            String(item.payAccountList),
                            String(item.phoneNumber),
                            String(item.smaFacebook),
                            String(item.smaInstagram),
                            String(item.smaTwitter),
                            String(item.staffSize),
                            String(item.state),
                            String(item.tradingName),
                            String(item.typeIdentification),
                            String(item.userId),
                            String(item.webSite),
                            String(item.index)
                          )
                        }
                      >
                        Open modal
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          )}
        </Table>
        <Divider />
        <Box sx={{ padding: "0.8rem" }}>
          <Button disabled={currentPage === 1} onClick={goToPreviousPage}>
            Previous Page
          </Button>
          {Array.from({ length: getTotalPages() }).map((_, index) => (
            <Button
              key={index}
              onClick={() => goToPage(index + 1)}
              disabled={currentPage === index + 1}
            >
              {index + 1}
            </Button>
          ))}
          <Button
            disabled={currentPage === getTotalPages()}
            onClick={goToNextPage}
          >
            Next Page
          </Button>
        </Box>
      </TableContainer>
      <Modal
        key={individualIndex}
        open={open}
        onClose={handleCloseIndividual}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Box sx={{ marginBottom: "2rem" }}>
            {activeState ? (
              <Typography sx={{ fontWeight: 600, marginBottom: "0.7rem" }}>
                {individualstate === "ACTIVE"
                  ? "Deactivate Account"
                  : "Activate Account"}
              </Typography>
            ) : (
              <Typography sx={{ fontWeight: 600, marginBottom: "0.7rem" }}>
                Business Profile
              </Typography>
            )}
            {activeState ? (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    sm: "repeat(1, 1fr)",
                  },
                  gridGap: "1rem",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Message"
                  variant="outlined"
                  value={activationMessage}
                  onChange={handleActivationChange}
                />{" "}
                <Typography
                  sx={{
                    color: "grey",
                    margin: "-5px 0px 0px 5px",
                    fontSize: "12px",
                  }}
                >
                  {individualstate === "ACTIVE"
                    ? "Enter your reason for Deactivating Account"
                    : "Enter your reason for Activating Account"}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "1rem",
                  }}
                >
                  {individualstate === "ACTIVE" ? (
                    // <Button
                    //   onClick={() => handleDeactivation(modalDocumentId)}
                    //   variant="contained"
                    //   color="error"
                    // >
                    //   Deactivate
                    // </Button>
                    <Box>
                      {loadingButton ? (
                        <Button
                          variant="contained"
                          color="success"
                          sx={{
                            marginRight: "5px",
                            width: "95px",
                            height: "37px",
                          }}
                        >
                          <CircularProgress size={20} color="inherit" />
                        </Button>
                      ) : (
                        <Button
                          onClick={() => handleDeactivation(individualuserId)}
                          variant="contained"
                          color="error"
                          sx={{ marginRight: "5px" }}
                        >
                          Deactivate
                        </Button>
                      )}
                      <Button
                        onClick={handleHide}
                        variant="contained"
                        color="primary"
                      >
                        Cancel
                      </Button>
                    </Box>
                  ) : (
                    <Box>
                      {loadingButton ? (
                        <Button
                          variant="contained"
                          color="success"
                          sx={{
                            marginRight: "5px",
                            width: "95px",
                            height: "37px",
                          }}
                        >
                          <CircularProgress size={20} color="inherit" />
                        </Button>
                      ) : (
                        <Button
                          onClick={() => handleActivation(individualuserId)}
                          variant="contained"
                          color="success"
                          sx={{ marginRight: "5px" }}
                        >
                          Activate
                        </Button>
                      )}
                      <Button
                        onClick={handleHide}
                        variant="contained"
                        color="primary"
                      >
                        Cancel
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      sm: "repeat(1, 1fr)",
                      md: "repeat(2, 1fr)",
                    },
                    gridGap: "1rem",
                  }}
                >
                  {individualuserId === "null" ? (
                    <TextField
                      id="input-with-icon-textfield"
                      label="User ID:"
                      value="N/A"
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  ) : (
                    <TextField
                      id="input-with-icon-textfield"
                      label="User ID:"
                      value={individualuserId}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  )}
                  {individualCompanyName === "null" ? (
                    <TextField
                      id="input-with-icon-textfield"
                      label="Company Name:"
                      value="N/A"
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  ) : (
                    <TextField
                      id="input-with-icon-textfield"
                      label="Company Name:"
                      value={individualCompanyName}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  )}
                  {individualtradingName === "null" ? (
                    <TextField
                      id="input-with-icon-textfield"
                      label="Trading Name:"
                      value="N/A"
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  ) : (
                    <TextField
                      id="input-with-icon-textfield"
                      label="Trading Name:"
                      value={individualtradingName}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  )}
                  {individualstaffSize === "null" ? (
                    <TextField
                      id="input-with-icon-textfield"
                      label="Staff Size:"
                      value="N/A"
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  ) : (
                    <TextField
                      id="input-with-icon-textfield"
                      label="Staff Size:"
                      value={individualstaffSize}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  )}
                  {individualIndustry === "null" ? (
                    <TextField
                      id="input-with-icon-textfield"
                      label="Industry:"
                      value="N/A"
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  ) : (
                    <TextField
                      id="input-with-icon-textfield"
                      label="Industry:"
                      value={individualIndustry}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  )}
                  {individualCategory === "null" ? (
                    <TextField
                      id="input-with-icon-textfield"
                      label="Category:"
                      value="N/A"
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  ) : (
                    <TextField
                      id="input-with-icon-textfield"
                      label="Category:"
                      value={individualCategory}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  )}
                  {individualDescription === "null" ? (
                    <TextField
                      id="input-with-icon-textfield"
                      label="Description:"
                      value="N/A"
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  ) : (
                    <TextField
                      id="input-with-icon-textfield"
                      label="Description:"
                      value={individualDescription}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  )}
                </Box>

                <Box sx={{ marginBottom: "2rem" }}>
                  <Typography sx={{ fontWeight: 600, marginBottom: "0.7rem" }}>
                    Contact Information
                  </Typography>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        sm: "repeat(1, 1fr)",
                        md: "repeat(2, 1fr)",
                      },
                      gridGap: "1rem",
                    }}
                  >
                    {individualEmail === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Email:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Email:"
                        value={individualEmail}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {individualPhoneNumber === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Phone Number:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Phone Number:"
                        value={individualPhoneNumber}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {individualOfficeAddress === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Office Address:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Office Address:"
                        value={individualOfficeAddress}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {individualwebSite === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Website"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Website:"
                        value={individualwebSite}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {individualsmaFacebook === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Facebook Url:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Facebook Url:"
                        value={individualsmaFacebook}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {individualsmaInstagram === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Instagram Url:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Instagram Url:"
                        value={individualsmaInstagram}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {individualsmaTwitter === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Twitter Url:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Twitter Url:"
                        value={individualsmaTwitter}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                  </Box>
                </Box>
                <Box sx={{ marginBottom: "2rem" }}>
                  <Typography sx={{ fontWeight: 600, marginBottom: "0.7rem" }}>
                    Owner Information
                  </Typography>
                  <Box>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: {
                          sm: "repeat(1, 1fr)",
                          md: "repeat(2, 1fr)",
                        },
                        gridGap: "1rem",
                      }}
                    >
                      {individualFirstName === "null" ? (
                        <TextField
                          id="input-with-icon-textfield"
                          label="Legal Name:"
                          value="N/A"
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="outlined"
                        />
                      ) : (
                        <TextField
                          id="input-with-icon-textfield"
                          label="Legal Name:"
                          value={individualFirstName + " " + individualLastName}
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="outlined"
                        />
                      )}
                      {individualFirstName === "null" ? (
                        <TextField
                          id="input-with-icon-textfield"
                          label="First Name:"
                          value="N/A"
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="outlined"
                        />
                      ) : (
                        <TextField
                          id="input-with-icon-textfield"
                          label="First Name:"
                          value={individualFirstName}
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="outlined"
                        />
                      )}
                      {individualLastName === "null" ? (
                        <TextField
                          id="input-with-icon-textfield"
                          label="Last Name:"
                          value="N/A"
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="outlined"
                        />
                      ) : (
                        <TextField
                          id="input-with-icon-textfield"
                          label="Last Name:"
                          value={individualLastName}
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="outlined"
                        />
                      )}
                      {individualDateOfBirth === "null" ? (
                        <TextField
                          id="input-with-icon-textfield"
                          label="Date Of Birth:"
                          value="N/A"
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="outlined"
                        />
                      ) : (
                        <TextField
                          id="input-with-icon-textfield"
                          label="Date Of Birth:"
                          value={individualDateOfBirth}
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="outlined"
                        />
                      )}
                      {individualNationality === "null" ? (
                        <TextField
                          id="input-with-icon-textfield"
                          label="Nationality:"
                          value="N/A"
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="outlined"
                        />
                      ) : (
                        <TextField
                          id="input-with-icon-textfield"
                          label="Nationality:"
                          value={individualNationality}
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="outlined"
                        />
                      )}
                      {individualDocumentIdentificationUrl === "null" ? (
                        <TextField
                          id="input-with-icon-textfield"
                          label="Government Issued ID Url:"
                          value="N/A"
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="outlined"
                        />
                      ) : (
                        <TextField
                          id="input-with-icon-textfield"
                          label="Government Issued ID Url:"
                          value={individualDocumentIdentificationUrl}
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="outlined"
                        />
                      )}
                      {individualIdentificationNumber === "null" ? (
                        <TextField
                          id="input-with-icon-textfield"
                          label="Identification Number:"
                          value="N/A"
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="outlined"
                        />
                      ) : (
                        <TextField
                          id="input-with-icon-textfield"
                          label="Identification Number:"
                          value={individualIdentificationNumber}
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="outlined"
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ marginBottom: "2rem" }}>
                  <Typography sx={{ fontWeight: 600, marginBottom: "0.7rem" }}>
                    Payout Information
                  </Typography>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        sm: "repeat(1, 1fr)",
                        md: "repeat(2, 1fr)",
                      },
                      gridGap: "1rem",
                    }}
                  >
                    {individualmtnphonenumber === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Number (MTN):"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Number (MTN):"
                        value={individualmtnphonenumber}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {individualmerchantnumbermoov === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Number (MOOV):"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Number (MOOV):"
                        value={individualmerchantnumbermoov}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "1rem",
                  }}
                >
                  {individualstate === "ACTIVE" ? (
                    <Button
                      // onClick={() => handleDeactivation(modalDocumentId)}
                      onClick={handleUnhide}
                      variant="contained"
                      color="error"
                    >
                      Deactivate
                    </Button>
                  ) : (
                    <Button
                      // onClick={() => handleActivation(modalDocumentId)}
                      onClick={handleUnhide}
                      variant="contained"
                      color="success"
                    >
                      Activate
                    </Button>
                  )}
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default IndividualCustomers;

import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Container,
  CardMedia,
  CircularProgress, 
} from "@mui/material";
import { ManualPayout } from "../../api/Data";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QLogo } from "../../common/assets/icons"; 

const ValidatePayoutModal = ({
  open,
  handleCloseValidatePayoutModal,
  credentials,
}: any) => {
  const [loading, setLoading] = React.useState(false);
 
 
  const handleValidatePayout = async (e: any) => {
    e.preventDefault();
    setLoading(true); 

    try {
      const result = await ManualPayout(credentials); 
      if (result.responseMessage === "Successful") { 
        toast.success(
            `Payout ${result.responseMessage}`
          );
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 3400);
      } else if (result.responseMessage === "Failed"){
        toast.error(
          `Payout ${result.responseMessage}`
        );
        setLoading(false);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 3400);
      }
      else {
        toast(
          `Payout ${result.responseMessage}`
        );
        setLoading(false);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 3400);
      }
    } catch (error) {
      toast.error(
        "Something went wrong, Please Try Again"
      );
      setLoading(false);
    }
  };
  return (
    <div>
      <ToastContainer />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleCloseValidatePayoutModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box>
            <Box
              sx={{
                transform: "translate(0)",
                marginTop: "20px",
                margin: "auto",
                borderRadius: "8px",
                boxShadow: 24,
                p: 4,
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "50px",
              }}
            >
              <Container
                sx={{ height: "100vh", paddingTop: "0px" }}
                maxWidth="sm"
              >
                <Box
                  sx={{
                    height: "max-content",
                    boxShadow: 4,
                    padding: "90px 40px",
                    margin: "auto",
                    backgroundColor: "white",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        marginBottom: "25px",
                        marginLeft: "0px",
                        width: "100%",
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="30px"
                        image={QLogo}
                        alt="QOS Logo"
                        sx={{ objectFit: "contain" }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Typography sx={{ textAlign: "center" }}>
                       Manual Payout
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ color: "#B2B2B2" }}>
                          Are you sure you want to send <b style={{color: "black"}}>XOF {credentials.amount}</b> to user with client ID <b style={{color: "black"}}>{credentials.clientId}</b> ?
                        </Typography>
                      </Box>
                      <form onSubmit={handleValidatePayout}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          {loading ? (
                            <Button
                              sx={{
                                height: "50px",
                                marginTop: "20px",
                                backgroundColor: "#F59E0B",
                                "&:hover": {
                                  backgroundColor: "#E18D00", // Change this to your desired hover color
                                },
                              }}
                              variant="contained"
                              color="primary"
                              disabled
                              fullWidth
                            >
                              <CircularProgress />
                            </Button>
                          ) : (
                            <Button
                              sx={{
                                height: "50px",
                                marginTop: "20px",
                                backgroundColor: "#F59E0B",
                                "&:hover": {
                                  backgroundColor: "#E18D00", // Change this to your desired hover color
                                },
                              }}
                              variant="contained"
                              color="primary"
                              type="submit"
                              fullWidth
                            >
                              <Typography sx={{ display: "flex" }}>
                                Confirm
                              </Typography>
                            </Button>
                          )}
                          <Button
                            sx={{ color: "#000", textDecoration: "underline" }}
                            onClick={handleCloseValidatePayoutModal}
                          >
                            Close
                          </Button>
                        </Box>
                      </form>
                    </Box>
                  </Box>
                </Box>
              </Container>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ValidatePayoutModal;

import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button, CardMedia, CircularProgress, TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ActivateDocument } from "../../api/Data";
import { Endpoints } from "../../api/EndPoints";

const LoginArrow = `${process.env.PUBLIC_URL}/assets/images/loginarrow.svg`;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: { xs: "90%", md: "87%" },
  width: { xs: "85%", md: "80%" },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
  borderRadius: 2,
  scrollbarWidth: "none", // Hide the scrollbar in Firefox
  msOverflowStyle: "none", // Hide the scrollbar in IE and Edge
  "&::-webkit-scrollbar": {
    display: "none", // Hide the scrollbar in Chrome, Safari, and Opera
  },
};
const documentUrl = "https://example.com/sample.pdf";

const SummaryModal = ({ open, onClose, data }: any) => {
  const [loadingDocCall, setLoadingDocCall] = useState(false);
  const [documentActivationMessage, setDocumentActivationMessage] =
    useState("Correct Document");
  const [documentDeactivationMessage, setDocumentDeactivationMessage] =
    useState("Incorrect Document");
  const [addComment, setAddComment] = useState(false);
  const [showDocument, setShowDocument] = useState(false);

  const handleAddComment = () => setAddComment(!addComment);

  const handleShowDocument = () => setShowDocument(!showDocument);

  const handleDocumentActivationChange = (event: any) => {
    setDocumentActivationMessage(event.target.value);
    setDocumentDeactivationMessage(event.target.value);
  };

  const handleDocumentDeactivation = async (documentId: any) => {
    setLoadingDocCall(true);
    const data = {
      documentId: documentId,
      status: "rejected",
      commentaire: documentDeactivationMessage,
    };
    try {
      const result = await ActivateDocument(data);
      setLoadingDocCall(false);
      // Handle the response
      toast.error("Document Rejected");
      setTimeout(() => {
        window.location.reload();
      }, 3400);
    } catch (error) {
      // Handle the error
      setLoadingDocCall(false);
      toast.error("Something Went Wrong");
    }
  };

  const handleDocumentActivation = async (documentId: any) => {
    setLoadingDocCall(true);
    const data = {
      documentId: documentId,
      status: "approved",
      commentaire: documentActivationMessage,
    };
    try {
      const result = await ActivateDocument(data);
      setLoadingDocCall(false);
      // Handle the response
      toast.success("Document Accepted");
      setTimeout(() => {
        window.location.reload();
      }, 3400);
    } catch (error) {
      // Handle the error
      setLoadingDocCall(false);
      toast.error("Something Went Wrong");
    }
  };

  const viewDocumentUrl = `${Endpoints.Document.View}/${data.docName}`;

  return (
    <div>
      <ToastContainer />
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              border: "1px solid #F1F3F9",
              borderRadius: "8px",
              padding: "22px 40px",
              marginBottom: "20px",
            }}
          >
            <Typography
              sx={{ fontSize: "18px", fontWeight: 600, color: "#45464E" }}
            >
              Step 1. View Document
            </Typography>
            <Box sx={{ display: "flex", gap: "10px", margin: "12px auto" }}>
              <Button variant="outlined">
                <a
                  style={{ textDecoration: "none" }}
                  href={viewDocumentUrl}
                  target="_blank"
                >
                 View Document
                </a>
              </Button>
              {/* <Button variant="outlined" onClick={handleShowDocument}>
                {showDocument ? "Hide Document" : "Show Document"}
              </Button> */}
            </Box>
            {/* {showDocument && (
              <embed
                src={viewDocumentUrl}
                type="application/pdf"
                width="100%"
                height="600px"
              />
            )} */}
          </Box>
          <Box
            sx={{
              border: "1px solid #F1F3F9",
              borderRadius: "8px",
              padding: "22px 40px",
            }}
          >
            <Typography
              sx={{ fontSize: "18px", fontWeight: 600, color: "#45464E" }}
            >
              Step 2. Document Information
            </Typography>
            <Box
              sx={{
                marginTop: "20px",
                display: "grid",
                gridTemplateColumns: { xs: "1fr", md: "repeat(3, 1fr)" },
                gridGap: "2rem",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                  Document Name
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#45464E",
                    marginTop: "12px",
                  }}
                >
                  {data.docName}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                  Document Type
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#45464E",
                    marginTop: "12px",
                  }}
                >
                  {data.docType}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                  Upload Date
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#45464E",
                    marginTop: "12px",
                  }}
                >
                  {data.docUpDate}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                  Document Description
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#45464E",
                    marginTop: "12px",
                  }}
                >
                  {data.docDescription === "" ? "N/A" : data.docDescription}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                  Comment
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#45464E",
                    marginTop: "12px",
                  }}
                >
                  {data.comment === "" ? "N/A" : data.comment}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                  Status
                </Typography>

                <Typography
                  sx={{
                    fontSize: "16px",
                    marginTop: "12px",
                    color: data.docStatus === "ACCEPTED" ? "white" : "white",
                    backgroundColor:
                      data.docStatus === "ACCEPTED"
                        ? "green"
                        : data.docStatus === "REJECTED"
                        ? "red"
                        : "gray",
                    padding: "5px 20px",
                    width: "max-content",
                  }}
                >
               {data.docStatus === "ACCEPTED"
                        ? "APPROVED"
                        : data.docStatus === "REJECTED"
                        ? "REJECTED"
                        : "PROCESSING"}
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* COMMENT */}
          {addComment ? (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "90% 10%",
                margin: "20px 0 20px auto",
              }}
            >
              <TextField
                id="outlined-multiline-static"
                label="Add comment"
                onChange={handleDocumentActivationChange}
                fullWidth
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  height: "max-content",
                }}
              >
                <Button onClick={handleAddComment} variant="outlined">
                  Cancel
                </Button>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "20px 0 20px auto",
              }}
            >
              <Button onClick={handleAddComment} variant="outlined">
                Add Comment
              </Button>
            </Box>
          )}

          {/* API Buttons */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "center",
              margin: "0px auto 0px auto",
              width: { xs: "100%", md: "60%" },
            }}
          >
            <Button
              sx={{
                marginBottom: { xs: "20px", md: "auto" },
                marginRight: "20px",
                color: "#F59E0B",
                height: "50px",
                border: "solid 1px #F59E0B",
                backgroundColor: "#FFF",
                "&:hover": {
                  backgroundColor: "whitesmoke", // Change this to your desired hover color
                },
              }}
              type="submit"
              variant="contained"
              fullWidth
              onClick={onClose}
            >
              Cancel
            </Button>
            {data.docStatus === "ACCEPTED" ? (
              <Button
                sx={{
                  height: "50px",
                  backgroundColor: "red",
                  "&:hover": {
                    backgroundColor: "red", // Change this to your desired hover color
                  },
                }}
                variant="contained"
                onClick={() => handleDocumentDeactivation(data.docId)}
                fullWidth
              >
                {loadingDocCall ? (
                  <CircularProgress sx={{ width: "10px", color: "white" }} />
                ) : (
                  <Typography sx={{ display: "flex" }}>
                    Reject
                    <CardMedia
                      component="img"
                      width="40px"
                      image={LoginArrow}
                      alt="QOS Logo"
                      sx={{
                        objectFit: "contain",
                        marginLeft: "8px",
                        "&:hover": {
                          marginLeft: "8.5px",
                        },
                      }}
                    />
                  </Typography>
                )}
              </Button>
            ) : data.docStatus === "REJECTED" ? (
              <Button
                sx={{
                  height: "50px",
                  backgroundColor: "#F59E0B",
                  "&:hover": {
                    backgroundColor: "#E18D00", // Change this to your desired hover color
                  },
                }}
                variant="contained"
                onClick={() => handleDocumentActivation(data.docId)}
                fullWidth
              >
                {loadingDocCall ? (
                  <CircularProgress sx={{ width: "10px", color: "white" }} />
                ) : (
                  <Typography sx={{ display: "flex" }}>
                    Accept
                    <CardMedia
                      component="img"
                      width="40px"
                      image={LoginArrow}
                      alt="QOS Logo"
                      sx={{
                        objectFit: "contain",
                        marginLeft: "8px",
                        "&:hover": {
                          marginLeft: "8.5px",
                        },
                      }}
                    />
                  </Typography>
                )}
              </Button>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "center",
                  margin: "0px auto 0px auto",
                  gap: "20px",
                  width: { xs: "100%", md: "100%" },
                }}
              >
                <Button
                  sx={{
                    height: "50px",
                    backgroundColor: "#F59E0B",
                    "&:hover": {
                      backgroundColor: "#E18D00", // Change this to your desired hover color
                    },
                  }}
                  variant="contained"
                  onClick={() => handleDocumentActivation(data.docId)}
                  fullWidth
                >
                  {loadingDocCall ? (
                    <CircularProgress sx={{ width: "10px", color: "white" }} />
                  ) : (
                    <Typography sx={{ display: "flex" }}>
                      Accept
                      <CardMedia
                        component="img"
                        width="40px"
                        image={LoginArrow}
                        alt="QOS Logo"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "8px",
                          "&:hover": {
                            marginLeft: "8.5px",
                          },
                        }}
                      />
                    </Typography>
                  )}
                </Button>
                <Button
                  sx={{
                    height: "50px",
                    backgroundColor: "red",
                    "&:hover": {
                      backgroundColor: "red", // Change this to your desired hover color
                    },
                  }}
                  variant="contained"
                  onClick={() => handleDocumentDeactivation(data.docId)}
                  fullWidth
                >
                  {loadingDocCall ? (
                    <CircularProgress sx={{ width: "10px", color: "white" }} />
                  ) : (
                    <Typography sx={{ display: "flex" }}>
                      Reject
                      <CardMedia
                        component="img"
                        width="40px"
                        image={LoginArrow}
                        alt="QOS Logo"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "8px",
                          "&:hover": {
                            marginLeft: "8.5px",
                          },
                        }}
                      />
                    </Typography>
                  )}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default SummaryModal;

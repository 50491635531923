import PayoutOverview from "../../../components/pages/finance/payout/PayoutOverview"; 

const Payout = () => {
  return (
    <div>
      <PayoutOverview />
    </div>
  );
};

export default Payout;

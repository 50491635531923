import Index from "../../../components/pages/settings/Index";

const Settings = () => {
  return (
    <div>
      <Index />
    </div>
  );
};

export default Settings;

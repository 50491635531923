import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Endpoints } from "../../../api/EndPoints";
import axios from "axios";
import { toast } from "react-toastify";

const initialState: any = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null,
  data: [],
};

export const GetMerchantTransactionLog = createAsyncThunk(
  "merchanttransactionlog",
  async (credential: any) => {
    try {
      const response = await axios.post(
        `${Endpoints.Transaction.MerchantTransactionLog}?page=${credential.page}&size=${credential.size}`,
        {
          clientId: credential.identifier,
          operator: credential.operator,
          // operationType: credential.operationType,
          status: credential.status,
          startDate: credential.startDate,
          endDate: credential.endDate,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("SUCCESS");
      return response.data.datas;
    } catch (error: any) {
      toast.error(error.response.data.message);
      console.log("error.response.data.message", error.response.data.message);
      if (error.response && error.response.data.message) {
        return error.response.data.message;
      } else {
        return error.message;
      }
    }
  }
);

export const merchantTransactionLogSlice = createSlice({
  name: "merchanttransactionlog",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetMerchantTransactionLog.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        GetMerchantTransactionLog.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = null;
          state.data = action.payload;
        }
      )
      .addCase(
        GetMerchantTransactionLog.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
          state.data = [];
        }
      );
  },
});

export default merchantTransactionLogSlice.reducer;

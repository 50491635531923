import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CardMedia,
  Skeleton,
  CircularProgress,
  Button,
  Paper,
  InputBase,
  Divider,
  TextField,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import DateRangePicker from "../../../constants/DateRangePicker";
import TransactionLogTable from "./TransactionLogTable";
import MerchantTransactionLogTable from "./MerchantTransactionLogTable";
import { useAppDispatch, useAppSelector } from "../../../../state/hooks";
import { GetAllTransactionLog } from "../../../../state/feautures/transactionLogSlice/getAllTransactionLogSlice";
import { GetTransactionLog } from "../../../../state/feautures/transactionLogSlice/getTransactionLogSlice";
import { GetTransactionLogDownload } from "../../../../state/feautures/transactionLogSlice/getAllTransactionLogDownloadSlice";
import { GetTransactionLogToEmail } from "../../../../state/feautures/transactionLogSlice/getTransactionLogToEmailSlice";
import { GetMerchantTransactionLogToEmail } from "../../../../state/feautures/transactionLogSlice/getMerchantTransactionToEmailSlice";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { GetTransaction } from "../../../../state/feautures/transactionLogSlice/getTransactionSlice";
import { GetMerchantTransactionLog } from "../../../../state/feautures/transactionLogSlice/getMerchantTransactionLogSlice";
import { GetMerchantTransactionLogDownload } from "../../../../state/feautures/transactionLogSlice/getMerchantTransactionLogDownloadSlice";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { GetCustomerInformation } from "../../../../state/feautures/customer/getCustomerInformationSlice";
import DownloadPDF from "../../../../services/helper/DownloadPDF";
// import { downloadCSV } from "../../../../services/helper/DownloadCSV";
import DownloadCSV from "../../../../services/helper/DownloadCSV";
import { UpdateTransactionStatus } from "../../../../state/feautures/transactionLogSlice/updateTransactionStatusSlice";

// Tab Functions
function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TransactionsLog = () => {
  const dispatch = useAppDispatch();
  const Transaction = useAppSelector((state) => state.getTransaction);
  const TransactionData = Transaction.data;
  const TransactionLoading = Transaction.loading;
  const TransactionLog = useAppSelector((state) => state.getTransactionLog);
  const TransactionLogData = TransactionLog.data.content;
  const TransactionLogLoading = TransactionLog.loading;

  const TransactionLogDownload = useAppSelector(
    (state) => state.transactionLogDownload
  );
  const TransactionLogDownloadData = TransactionLogDownload.data;
  const TransactionLogDownloadLoading = TransactionLogDownload.loading;

  const MerchantTransactionLog = useAppSelector(
    (state) => state.getMerchantTransactionLog
  );
  const MerchantTransactionLogData = MerchantTransactionLog.data.content;
  const MerchantTransactionLogLoading = MerchantTransactionLog.loading;

  const MerchantTransactionLogDownload = useAppSelector(
    (state) => state.merchantTransactionLogDownload
  );
  const MerchantTransactionLogDownloadData =
    MerchantTransactionLogDownload.data;
  const MerchantTransactionLogDownloadLoading =
    MerchantTransactionLogDownload.loading;


  const UpdateTransactionStatusData = useAppSelector(
    (state) => state.updateTransactionStatus
  );

  const UpdateTransactionStatusDataLoading =
    UpdateTransactionStatusData.loading;

  const TransactionToEmail = useAppSelector(
    (state) => state.getTransactionLogToEmail
  );
  const TransactionToEmailLoading = TransactionToEmail.loading;

  const MerchantTransactionToEmail = useAppSelector(
    (state) => state.getMerchantTransactionToEmail
  );
  const MerchantTransactionToEmailLoading = MerchantTransactionToEmail.loading;
 

  const [value, setValue] = useState(0);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [age, setAge] = React.useState("");
  const [operator, setOperator] = useState<any>("");
  const [mOperator, setMOperator] = useState<any>("");
  const [transactionType, setTransactionType] = useState("");
  const [statusType, setStatusType] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [mTransactionType, setMTransactionType] = useState("");
  const [mStatusType, setMStatusType] = useState("");
  const [mDocumentType, setMDocumentType] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [errorDate, setErrorDate] = useState<any>("");
  const [transactionId, setTransactionId] = React.useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [operatorValue, setOperatorValue] = useState<any>([]);

  const resetAllInput = () => {
    setOperator("");
    setMOperator("");
    setTransactionType("");
    setStatusType("");
    setDocumentType("");
    setMTransactionType("");
    setMStatusType("");
    setMDocumentType("");
    setStartDate(null);
    setEndDate(null);
    setTransactionId("");
    setSearchQuery("");
    setBusinessName("");
    setErrorDate("");
  };
  const formatDate = (date: Date | null) => {
    return date ? dayjs(date).format("YYYY-MM-DD") : "";
  };

  const handleStartDateChange = (date: Date | null) => {
    if (
      endDate &&
      date &&
      dayjs(date).add(1, "month").isBefore(dayjs(endDate))
    ) {
      setStartDate(null);
      setErrorDate("The date range cannot exceed one month.");
    } else {
      setStartDate(date);
      setErrorDate("");
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (
      startDate &&
      date &&
      dayjs(date).subtract(1, "month").isAfter(dayjs(startDate))
    ) {
      setEndDate(null);
      setErrorDate("The date range cannot exceed one month.");
    } else {
      setEndDate(date);
      setErrorDate("");
    }
  };
  const handleTransactionIdTextChange = (event: any) => {
    setTransactionId(event.target.value);
  };
  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };
  const handleOperatorChange = (event: SelectChangeEvent) => {
    setOperator(event.target.value);
  };
  const handleMOperatorChange = (event: SelectChangeEvent) => {
    setMOperator(event.target.value);
  };
  const handleTransactionTypeChange = (event: SelectChangeEvent) => {
    setTransactionType(event.target.value);
  };
  const handleStatusTypeChange = (event: SelectChangeEvent) => {
    setStatusType(event.target.value);
  };
  const handleDocumentTypeChange = (event: SelectChangeEvent) => {
    setDocumentType(event.target.value);
  };
  const handleMTransactionTypeChange = (event: SelectChangeEvent) => {
    setMTransactionType(event.target.value);
  };
  const handleMStatusChange = (event: SelectChangeEvent) => {
    setMStatusType(event.target.value);
  };
  const handleMDocumentTypeChange = (event: SelectChangeEvent) => {
    setMDocumentType(event.target.value);
  };
  const handleTabChange = (event: any, newValue: any) => {
    resetAllInput();
    setValue(newValue);
  };

  const handleGetTransaction = () => {
    const credential = {
      transactionId: transactionId,
    };
    dispatch(GetTransaction(credential));
  };

  // const handleSearchTransactionLog = () => {
  //   sessionStorage.setItem("transactionlogOperator", operator);
  //   sessionStorage.setItem("transactionlogtransactionType", transactionType);
  //   sessionStorage.setItem("transactionlogstartDate", formatDate(startDate));
  //   sessionStorage.setItem("transactionlogendDate", formatDate(endDate));
  //   const credential = {
  //     operator: operator,
  //     status: statusType,
  //     // operationType: transactionType,
  //     startDate: formatDate(startDate),
  //     endDate: formatDate(endDate),
  //     page: 0,
  //     size: 5,
  //   };
  //   dispatch(GetTransactionLog(credential));
  // };

  const handleTransactionLogDownload = () => {
    sessionStorage.setItem("transactionlogstartDate", formatDate(startDate));
    sessionStorage.setItem("transactionlogendDate", formatDate(endDate));
    const credential = {
      operator: operator,
      status: statusType,
      operationType: transactionType,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    };
    dispatch(GetTransactionLogDownload(credential));
  };
  // const handleMerchantTransactionLog = () => {
  //   sessionStorage.setItem("transactionlogmOperator.name", mOperator.name);
  //   sessionStorage.setItem(
  //     "transactionlogmOperator.operator",
  //     mOperator.operator
  //   );
  //   sessionStorage.setItem("transactionlogmTransactionType", mTransactionType);
  //   sessionStorage.setItem("transactionlogstartDate", formatDate(startDate));
  //   sessionStorage.setItem("transactionlogendDate", formatDate(endDate));
  //   const credential = {
  //     identifier: mOperator.name,
  //     operator: mOperator.operator,
  //     operationType: mTransactionType,
  //     startDate: formatDate(startDate),
  //     endDate: formatDate(endDate),
  //     page: 0,
  //     size: 5,
  //   };
  //   dispatch(GetMerchantTransactionLog(credential));
  // };
  const handleMerchantTransactionLogDownload = () => {
    sessionStorage.setItem("transactionlogstartDate", formatDate(startDate));
    sessionStorage.setItem("transactionlogendDate", formatDate(endDate));
    const credential = {
      identifier: mOperator.name,
      operator: mOperator.operator,
      status: mStatusType,
      operationType: mTransactionType,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    };
    dispatch(GetMerchantTransactionLogDownload(credential));
  };

  const handleTransactionLogDownloadToEmail = () => {
    const credential = {
      operator: operator,
      status: statusType,
      // operationType: transactionType,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    };
    dispatch(GetTransactionLogToEmail(credential));
  };
  const handleMerchantTransactionLogDownloadToEmail = () => {
    const credential = {
      identifier: mOperator.name,
      operator: mOperator.operator,
      // operationType: mTransactionType,
      status: mStatusType,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    };
    dispatch(GetMerchantTransactionLogToEmail(credential));
  };
  const CustomerInformationData: any = useAppSelector(
    (state) => state.customerInformation
  );
  const CustomerInformationloading = CustomerInformationData.loading;
  useEffect(() => {
    if (CustomerInformationData.data !== null) {
      setBusinessName(CustomerInformationData.data.datas.businessName);
    sessionStorage.setItem("MbusinessName", CustomerInformationData.data.datas.businessName);
      setOperatorValue(CustomerInformationData.data.datas.clientId);
    }
  }, [CustomerInformationData]);
  const handleSearchQuery = (e: any) => {
    setErrorMessage("");
    setSearchQuery(e.target.value);
  };
  const handleGetCustomerInformation = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(searchQuery)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }
    const credential = {
      email: searchQuery,
    };
    dispatch(GetCustomerInformation(credential));
  };
  const handleUpdateTransactionStatus = () => {
    const credential = {
      clientId: TransactionData.clientId,
      transactionId: TransactionData.transactionId,
      operator: TransactionData.operator,
    };
    dispatch(UpdateTransactionStatus(credential));
  };

  return (
    <div>
      {/* Heading */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          marginBottom: "20px",
        }}
      >
        <Typography
          sx={{ fontSize: "24px", color: "#45464E", marginBottom: "-5px" }}
        >
          Transaction Log
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            color: "#B2B2B2",
            fontWeight: 500,
            marginTop: "0",
          }}
        >
          View and search Transaction log
        </Typography>
      </Box>
      {/* Tabs Head*/}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: 0,
          marginBottom: "10px",
          marginTop: "30px",
        }}
      >
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          sx={{
            borderBottom: "none", // Remove the default bottom border
          }}
        >
          <Tab
            label="Transaction Log"
            className={value === 0 ? "activeTab" : ""}
            sx={{
              border: value === 0 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 0 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(0)}
          />
          <Tab
            label="Merchant Transaction Log"
            className={value === 1 ? "activeTab" : ""}
            sx={{
              border: value === 1 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 1 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(1)}
          />
          <Tab
            label="Search Transaction"
            className={value === 2 ? "activeTab" : ""}
            sx={{
              border: value === 2 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 2 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <Box sx={{ width: "100%" }} aria-label="scrollable auto tabs example">
        {/* General Report */}
        <TabPanel value={value} index={0}>
          {/* {loading ? ( */}
          {/* <Box sx={{}}>
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "140px",
                  backgroundColor: "#FFFFFF",
                  border: "solid 1px #E0E0E0",
                  borderRadius: "8px",
                }}
                animation="wave"
              />
            </Box> */}
          {/* // ) : ( */}
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "25px",
                justifyContent: "flex-start",
                padding: "30px",
                margin: "30px auto ",
                alignItems: "flex-start",
                boxShadow: 1,
                borderRadius: "8px",
              }}
            >
              <Typography
                sx={{ fontSize: "14px", fontWeight: 600, marginBottom: 0 }}
              >
               General Transaction Log
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  width: "100%",
                  gap: "50px",
                  // margin: "20px auto",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: "10px",
                    gap: "25px",
                  }}
                >
                  <FormControl sx={{ marginLeft: "0", width: "100%" }}>
                    <InputLabel id="demo-select-small-label">
                      Operator
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={operator}
                      label="Operator"
                      onChange={handleOperatorChange}
                    >
                      <MenuItem value="MTN">MTN</MenuItem>
                      <MenuItem value="MOOV">MOOV</MenuItem>
                      <MenuItem value="MOOVTG">MOOVTG</MenuItem>
                      <MenuItem value="TOGOCEL">TOGOCEL</MenuItem>
                      <MenuItem value="HUBCI">HUBCI</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={{ marginLeft: "0", width: "100%" }}>
                    <InputLabel id="demo-select-small-label">
                      Select Transaction Type
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={transactionType}
                      label="Select Transaction Type"
                      onChange={handleTransactionTypeChange}
                    >
                      <MenuItem value={undefined}>All</MenuItem>
                      <MenuItem value="REQUESTPAYMENT">
                        Request Payment
                      </MenuItem>
                      <MenuItem value="MAKEDEPOSIT">Make Deposit</MenuItem>
                      <MenuItem value="TOPUP">Topup</MenuItem>
                    </Select>
                  </FormControl>
            
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "start",
                    flexDirection: "column",
                    gap: "25px",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={handleStartDateChange}
                          />
                        </DemoContainer>
                        <Box sx={{ width: "15px", border: "#000 solid 1px" }} />
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={handleEndDateChange}
                          />
                        </DemoContainer>
                      </Box>
                      {errorDate && (
                        <p
                          style={{
                            fontSize: "12px",
                            color: "red",
                            textAlign: "left",
                            position: "absolute",
                            bottom: "-34px",
                          }}
                        >
                          {errorDate}
                        </p>
                      )}
                    </Box>
                  </LocalizationProvider>
                  {/* <FormControl sx={{ marginLeft: "0", width: "100%" }}>
                    <InputLabel id="demo-select-small-label">
                      Document type
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={documentType}
                      label="Document type"
                      onChange={handleDocumentTypeChange}
                    >
                      <MenuItem value="CSV">CSV</MenuItem>
                        <MenuItem value="PDF">PDF</MenuItem>  
                    </Select>
                  </FormControl> */}
                        <FormControl sx={{ marginLeft: "0", width: "100%" }}>
                    <InputLabel id="demo-select-small-label">Status</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={statusType}
                      label="Select Status"
                      onChange={handleStatusTypeChange}
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="successful">Successful</MenuItem> 
                      <MenuItem value="failed">Failed</MenuItem> 
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              {/* Buttons */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto",
                  width: "40%",
                  gap: "30px",
                }}
              >
                {TransactionLogDownloadLoading ? (
                  <Button
                    sx={{
                      height: "55px",
                      backgroundColor: "#F59E0B",
                      "&:hover": {
                        backgroundColor: "#E18D00", // Change this to your desired hover color
                      },
                    }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    <CircularProgress sx={{ width: "10px", color: "white" }} />
                  </Button>
                ) : (
                  <Button
                    sx={{
                      height: "55px",
                      backgroundColor: "#F59E0B",
                      "&:hover": {
                        backgroundColor: "#E18D00", // Change this to your desired hover color
                      },
                    }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    // onClick={handleSearchTransactionLog}
                    onClick={handleTransactionLogDownload}
                    disabled={
                      !statusType || !operator || !startDate || !endDate
                    }
                  >
                    <Typography sx={{ display: "flex" }}>Download</Typography>
                  </Button>
                )}
                {/* {documentType === "PDF" ? (
                  <>
                    {TransactionLogDownloadData.length === 0 ? (
                      <Button
                        sx={{
                          height: "55px",
                          backgroundColor: "#FFF",
                          border: "2px solid #F59E0B",
                          color: "#F59E0B",
                          "&:hover": {
                            border: "2px solid #F59E0B",
                          },
                        }}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        disabled={!documentType}
                        onClick={handleTransactionLogDownload}
                      >
                        <Typography sx={{ display: "flex" }}>
                          Download
                        </Typography>
                      </Button>
                    ) : TransactionLogDownloadLoading ? (
                      <Button
                        sx={{
                          height: "55px",
                          backgroundColor: "#FFF",
                          border: "2px solid #F59E0B",
                          color: "#F59E0B",
                          "&:hover": {
                            border: "2px solid #F59E0B",
                          },
                        }}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        disabled
                      >
                        <CircularProgress />
                      </Button>
                    ) : (
                      <Button
                        sx={{
                          height: "55px",
                          backgroundColor: "#FFF",
                          border: "2px solid #F59E0B",
                          color: "#F59E0B",
                          "&:hover": {
                            border: "2px solid #F59E0B",
                          },
                        }}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        disabled={!documentType}
                      >
                        <Typography sx={{ display: "flex" }}>
                          <DownloadPDF jsonData={TransactionLogDownloadData} />
                        </Typography>
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    {TransactionLogDownloadData.length === 0 ? (
                      <Button
                        sx={{
                          height: "55px",
                          backgroundColor: "#FFF",
                          border: "2px solid #F59E0B",
                          color: "#F59E0B",
                          "&:hover": {
                            border: "2px solid #F59E0B",
                          },
                        }}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        disabled={!documentType}
                        onClick={handleTransactionLogDownload}
                      >
                        <Typography sx={{ display: "flex" }}>
                          Download
                        </Typography>
                      </Button>
                    ) : TransactionLogDownloadLoading ? (
                      <Button
                        sx={{
                          height: "55px",
                          backgroundColor: "#FFF",
                          border: "2px solid #F59E0B",
                          color: "#F59E0B",
                          "&:hover": {
                            border: "2px solid #F59E0B",
                          },
                        }}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        disabled
                      >
                        <CircularProgress />
                      </Button>
                    ) : (
                      <Button
                        sx={{
                          height: "55px",
                          backgroundColor: "#FFF",
                          border: "2px solid #F59E0B",
                          color: "#F59E0B",
                          "&:hover": {
                            border: "2px solid #F59E0B",
                          },
                        }}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        disabled={!documentType}
                      >
                        <Typography sx={{ display: "flex" }}>
                          <DownloadCSV jsonData={TransactionLogDownloadData} />
                        </Typography>
                      </Button>
                    )}
                  </>
                )} */}

                {/* {TransactionToEmailLoading ? (
                  <Button
                    sx={{
                      height: "55px",
                      backgroundColor: "#FFF",
                      border: "2px solid #F59E0B",
                      color: "#F59E0B",
                      "&:hover": {
                        border: "2px solid #F59E0B",
                      },
                    }}
                    type="submit"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    disabled
                  >
                    <CircularProgress sx={{ width: "10px", color: "gray" }} />
                  </Button>
                ) : (
                  <Button
                    sx={{
                      height: "55px",
                      backgroundColor: "#FFF",
                      border: "2px solid #F59E0B",
                      color: "#F59E0B",
                      "&:hover": {
                        border: "2px solid #F59E0B",
                      },
                    }}
                    type="submit"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    disabled={
                      !statusType || !operator || !startDate || !endDate
                    }
                    onClick={handleTransactionLogDownloadToEmail}
                  >
                    <Typography sx={{ display: "flex" }}>
                      Send To Email
                    </Typography>
                  </Button>
                )} */}
              </Box>
            </Box>
            {/* <TransactionLogTable
              data={TransactionLogData}
              title="Transaction Log"
            /> */}
          </Box>
          {/* )} */}
        </TabPanel>

        {/* Merchant Transaction Log */}
        <TabPanel value={value} index={1}>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "25px",
                justifyContent: "flex-start",
                padding: "30px",
                margin: "30px auto ",
                alignItems: "flex-start",
                boxShadow: 1,
                borderRadius: "8px",
              }}
            >
              <Typography
                sx={{ fontSize: "14px", fontWeight: 600, marginBottom: 0 }}
              >
                Merchant Transaction Log
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "50px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                  }}
                >
                  <Paper
                    component="form"
                    sx={{
                      p: "0px 4px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      height: "40px",
                      boxShadow: 0,
                      border: 1,
                      borderColor: "gray",
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search by Email"
                      onChange={handleSearchQuery}
                      value={searchQuery}
                      inputProps={{ "aria-label": "search for merchant" }}
                      type="email"
                    />
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="search"
                      onClick={handleGetCustomerInformation}
                      disabled={CustomerInformationloading}
                    >
                      {CustomerInformationloading ? (
                        <CircularProgress
                          sx={{ width: "3px", color: "gray" }}
                        />
                      ) : (
                        <SearchIcon />
                      )}
                    </IconButton>
                  </Paper>
                  {errorMessage && (
                    <Typography
                      sx={{
                        textAlign: "left",
                        width: "100%",
                        fontSize: "12px",
                        color: "red",
                      }}
                    >
                      {errorMessage}
                    </Typography>
                  )}
                </Box>
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    justifySelf: "flex-start",
                    width: "100%",
                    height: "40px",
                    boxShadow: 0,
                    border: 1,
                    borderColor: "gray",
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Business Name"
                    value={
                      businessName === ""
                        ? ""
                        : businessName === null
                        ? "N/A"
                        : businessName
                    }
                    inputProps={{ "aria-label": "search google maps" }}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "10px", color: "red" }}
                    aria-label="search"
                    onClick={() => setBusinessName("")}
                  >
                    <CancelOutlinedIcon />
                  </IconButton>
                </Paper>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  width: "100%",
                  gap: "50px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: "10px",
                    gap: "25px",
                  }}
                >
                  <FormControl sx={{ marginLeft: "0", width: "100%" }}>
                    <InputLabel id="demo-select-small-label">
                      Operator
                    </InputLabel>
                    {operatorValue !== null && (
                      <Select
                        value={mOperator}
                        onChange={handleMOperatorChange}
                        displayEmpty
                        fullWidth
                      >
                        <MenuItem value="" disabled>
                          Select an option
                        </MenuItem>
                        {operatorValue.map((item: any, index: any) => (
                          <MenuItem key={index} value={item}>
                            {item.operator}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                        <FormControl sx={{ marginLeft: "0", width: "100%" }}>
                    <InputLabel id="demo-select-small-label">
                      Select Transaction Type
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={mTransactionType}
                      label="Select Transaction Type"
                      onChange={handleMTransactionTypeChange}
                    >
                      <MenuItem value={undefined}>All</MenuItem>
                      <MenuItem value="REQUESTPAYMENT">
                        Request Payment
                      </MenuItem>
                      <MenuItem value="MAKEDEPOSIT">Make Deposit</MenuItem>
                      <MenuItem value="TOPUP">Topup</MenuItem>
                    </Select>
                  </FormControl> 
             
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "25px",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        position: 'relative'
                      }}
                    >
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="Start Date"
                          value={startDate}
                          onChange={handleStartDateChange}
                        />
                      </DemoContainer>
                      <Box sx={{ width: "15px", border: "#000 solid 1px" }} />
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="End Date"
                          value={endDate}
                          onChange={handleEndDateChange}
                        />
                      </DemoContainer>
                      {errorDate && (
                        <p
                          style={{
                            fontSize: "12px",
                            color: "red",
                            textAlign: "left",
                            position: "absolute",
                            bottom: "-34px",
                          }}
                        >
                          {errorDate}
                        </p>
                      )}
                    </Box>
                  </LocalizationProvider>
                  {/* <FormControl sx={{ marginLeft: "0", width: "100%" }}>
                    <InputLabel id="demo-select-small-label">
                      Document type
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={mDocumentType}
                      label="Document type"
                      onChange={handleMDocumentTypeChange}
                    >
                      <MenuItem value={20}>CSV</MenuItem>
                        <MenuItem value={30}>PDF</MenuItem>  
                    </Select>
                  </FormControl> */}
                       <FormControl sx={{ marginLeft: "0", width: "100%" }}>
                    <InputLabel id="demo-select-small-label">Status</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={mStatusType}
                      label="Select Transaction Status"
                      onChange={handleMStatusChange}
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="successful">Successful</MenuItem> 
                      <MenuItem value="failed">Failed</MenuItem> 
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              {/* Buttons */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto",
                  width: "40%",
                  gap: "30px",
                }}
              >
                <Button
                  sx={{
                    height: "55px",
                    backgroundColor: "#F59E0B",
                    "&:hover": {
                      backgroundColor: "#E18D00", // Change this to your desired hover color
                    },
                  }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  // onClick={handleMerchantTransactionLog}
                  onClick={handleMerchantTransactionLogDownload}
                  disabled={
                    !startDate || !endDate || !mOperator || !mStatusType
                  }
                >
                  {MerchantTransactionLogDownloadLoading ? (
                    <CircularProgress sx={{ width: "10px", color: "white" }} />
                  ) : (
                    <Typography sx={{ display: "flex" }}>Download</Typography>
                  )}
                </Button>

                {/* {mDocumentType === "PDF" ? (
                  <>
                    {MerchantTransactionLogDownloadData.length === 0 ? (
                      <Button
                        sx={{
                          height: "55px",
                          backgroundColor: "#FFF",
                          border: "2px solid #F59E0B",
                          color: "#F59E0B",
                          "&:hover": {
                            border: "2px solid #F59E0B",
                          },
                        }}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        disabled={
                          !startDate ||
                          !endDate ||
                          !mOperator ||
                          !mTransactionType ||
                          !mDocumentType
                        }
                        onClick={handleMerchantTransactionLogDownload}
                      >
                        <Typography sx={{ display: "flex" }}>
                          Download
                        </Typography>
                      </Button>
                    ) : MerchantTransactionLogDownloadLoading ? (
                      <Button
                        sx={{
                          height: "55px",
                          backgroundColor: "#FFF",
                          border: "2px solid #F59E0B",
                          color: "#F59E0B",
                          "&:hover": {
                            border: "2px solid #F59E0B",
                          },
                        }}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        disabled
                      >
                        <CircularProgress />
                      </Button>
                    ) : (
                      <Button
                        sx={{
                          height: "55px",
                          backgroundColor: "#FFF",
                          border: "2px solid #F59E0B",
                          color: "#F59E0B",
                          "&:hover": {
                            border: "2px solid #F59E0B",
                          },
                        }}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        disabled={
                          !startDate ||
                          !endDate ||
                          !mOperator ||
                          !mTransactionType ||
                          !mDocumentType
                        }
                      >
                        <Typography sx={{ display: "flex" }}>
                          <DownloadPDF
                            jsonData={MerchantTransactionLogDownloadData}
                          />
                        </Typography>
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    {MerchantTransactionLogDownloadData.length === 0 ? (
                      <Button
                        sx={{
                          height: "55px",
                          backgroundColor: "#FFF",
                          border: "2px solid #F59E0B",
                          color: "#F59E0B",
                          "&:hover": {
                            border: "2px solid #F59E0B",
                          },
                        }}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        disabled={
                          !startDate ||
                          !endDate ||
                          !mOperator ||
                          !mTransactionType ||
                          !mDocumentType
                        }
                        onClick={handleMerchantTransactionLogDownload}
                      >
                        <Typography sx={{ display: "flex" }}>
                          Download
                        </Typography>
                      </Button>
                    ) : MerchantTransactionLogDownloadLoading ? (
                      <Button
                        sx={{
                          height: "55px",
                          backgroundColor: "#FFF",
                          border: "2px solid #F59E0B",
                          color: "#F59E0B",
                          "&:hover": {
                            border: "2px solid #F59E0B",
                          },
                        }}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        disabled
                      >
                        <CircularProgress />
                      </Button>
                    ) : (
                      <Button
                        sx={{
                          height: "55px",
                          backgroundColor: "#FFF",
                          border: "2px solid #F59E0B",
                          color: "#F59E0B",
                          "&:hover": {
                            border: "2px solid #F59E0B",
                          },
                        }}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        disabled={
                          !startDate ||
                          !endDate ||
                          !mOperator ||
                          !mTransactionType ||
                          !mDocumentType
                        }
                      >
                        <Typography sx={{ display: "flex" }}>
                          <DownloadCSV
                            jsonData={MerchantTransactionLogDownloadData}
                          />
                        </Typography>
                      </Button>
                    )}
                  </>
                )} */}
                {/* {MerchantTransactionToEmailLoading ? (
                  <Button
                    sx={{
                      height: "55px",
                      backgroundColor: "#FFF",
                      border: "2px solid #F59E0B",
                      color: "#F59E0B",
                      "&:hover": {
                        border: "2px solid #F59E0B",
                      },
                    }}
                    type="submit"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    disabled
                  >
                    <CircularProgress sx={{ width: "10px", color: "gray" }} />
                  </Button>
                ) : (
                  <Button
                    sx={{
                      height: "55px",
                      backgroundColor: "#FFF",
                      border: "2px solid #F59E0B",
                      color: "#F59E0B",
                      "&:hover": {
                        border: "2px solid #F59E0B",
                      },
                    }}
                    type="submit"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    disabled={
                      !startDate || !endDate || !mOperator || !mStatusType
                    }
                    onClick={handleMerchantTransactionLogDownloadToEmail}
                  >
                    <Typography sx={{ display: "flex" }}>
                      Send To Email
                    </Typography>
                  </Button>
                )} */}
              </Box>
            </Box>
            {/* <MerchantTransactionLogTable
              data={MerchantTransactionLogData}
              title="Transaction Log"
            /> */}
          </Box>
          {/* )} */}
        </TabPanel>
        {/* Search */}
        <TabPanel value={value} index={2}>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "25px",
                justifyContent: "flex-start",
                padding: "30px",
                margin: "30px auto ",
                alignItems: "flex-start",
                boxShadow: 1,
                borderRadius: "8px",
              }}
            >
              <Typography
                sx={{ fontSize: "14px", fontWeight: 600, marginBottom: 0 }}
              >
                Search Transaction Log
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  gap: "50px",
                  // margin: "20px auto",
                }}
              >   <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                    width: "50%",
                  }}
                >
                <FormControl sx={{ marginLeft: "0", width: "100%" }}>
                    <InputLabel id="demo-select-small-label">
                      Operator
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={operator}
                      label="Operator"
                      onChange={handleOperatorChange}
                    >
                      <MenuItem value="MTN">MTN</MenuItem>
                      <MenuItem value="MOOV">MOOV</MenuItem>
                      <MenuItem value="MOOVTG">MOOVTG</MenuItem>
                      <MenuItem value="TOGOCEL">TOGOCEL</MenuItem>
                      <MenuItem value="HUBCI">HUBCI</MenuItem>
                    </Select>
                  </FormControl>
                  </Box>
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "25px",
                  }}
                >
                  <TextField
                    type="text"
                    placeholder="Enter Client Ref"
                    variant="outlined"
                    fullWidth
                    value={transactionId}
                    onChange={handleTransactionIdTextChange}
                    sx={{
                      marginTop: "5px",
                      "&:focus": {
                        border: "2px solid red",
                      },
                    }}
                  />
                </Box>
             
              </Box>
              {/* Buttons */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                    width: "50%",
                  }}
                >
                  <Button
                    sx={{
                      height: "55px",
                      backgroundColor: "#F59E0B",
                      "&:hover": {
                        backgroundColor: "#E18D00", // Change this to your desired hover color
                      },
                    }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleGetTransaction}
                    disabled={!transactionId}
                  >
                    {TransactionLoading ? (
                      <CircularProgress
                        sx={{ width: "10px", color: "white" }}
                      />
                    ) : (
                      <Typography sx={{ display: "flex" }}>Search</Typography>
                    )}
                  </Button>
                </Box>
              <TableContainer
                component={Paper}
                sx={{
                  width: "100%",
                  overflowX: "auto",
                  borderRadius: "0",
                  border: "none",
                  boxShadow: 0,
                }}
              >
                <Table
                  aria-label="collapsible table"
                  sx={{ padding: 0, margin: 0 }}
                >
                  <TableHead>
                    <TableRow
                      sx={{ backgroundColor: "#FCDFAE1A", textTransform: "" }}
                    >
                      <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>
                        Transaction ID
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>
                        Client Ref
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>
                        Amount
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: 600, fontSize: "14px" }}
                      >
                        Business Name
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: 600, fontSize: "14px" }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: 600, fontSize: "14px" }}
                      >
                        Customer Number
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: 600, fontSize: "14px" }}
                      >
                        Request Type
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: 600, fontSize: "14px" }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {TransactionData === null ? (
                      <TableRow>
                        <TableCell>Search for a transaction</TableCell>
                      </TableRow>
                    ) : TransactionData === "Transaction Not Found" ? (
                      <TableRow>
                        <TableCell>No Transaction Found</TableCell>
                      </TableRow>
                    ) : (
                      <>
                        <TableRow
                          key={TransactionData.merchantID}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {" "}
                          <TableCell component="th" scope="row">
                            {TransactionData.momTransactionId === null
                              ? "N/A"
                              : TransactionData.momTransactionId}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {TransactionData.transactionId === null
                              ? "N/A"
                              : TransactionData.transactionId}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {TransactionData.amount === null
                              ? "N/A"
                              : TransactionData.amount + " XOF"}
                          </TableCell>
                          <TableCell align="left">
                            {TransactionData.businessName === null
                              ? "N/A"
                              : TransactionData.businessName}
                          </TableCell>
                          <TableCell align="left">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "left",
                                width: "100px",
                              }}
                            >
                              {TransactionData.status !== null ? (
                                <Box
                                  sx={{
                                    fontSize: "11px",
                                    borderRadius: "10px",
                                    padding: "2px 10px",
                                    backgroundColor: "#F2F0F9",
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100px",
                                    marginBottom: "2px",
                                    color: "#6E6893",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      height: "7px",
                                      width: "7px",
                                      borderRadius: "50%",
                                      backgroundColor: "#6E6893",
                                      marginRight: "5px",
                                    }}
                                  ></Box>
                                  {TransactionData.status}
                                </Box>
                              ) : TransactionData.status === null ? (
                                <Box
                                  sx={{
                                    fontSize: "11px",
                                    borderRadius: "10px",
                                    padding: "2px 10px",
                                    backgroundColor: "lightgray",
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100px",
                                    marginBottom: "2px",
                                    color: "grey",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      height: "8px",
                                      width: "8px",
                                      borderRadius: "50%",
                                      backgroundColor: "grey",
                                      marginRight: "5px",
                                    }}
                                  ></Box>
                                  N/A
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    fontSize: "11px",
                                    borderRadius: "10px",
                                    padding: "2px 10px",
                                    backgroundColor: "#D8FFD8",
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100px",
                                    marginBottom: "2px",
                                    color: "#07751A",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      height: "8px",
                                      width: "8px",
                                      borderRadius: "50%",
                                      backgroundColor: "#07751A",
                                      marginRight: "5px",
                                    }}
                                  ></Box>
                                  {TransactionData.status}
                                </Box>
                              )}
                              <Box sx={{ fontSize: "12px" }}>
                                <b>Date:</b>{" "}
                                {formatDate(TransactionData.transactionDate)}
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            {TransactionData.sendersMsisdn === null
                              ? "N/A"
                              : "+" + TransactionData.sendersMsisdn}
                          </TableCell>
                          <TableCell align="center">
                            {TransactionData.requestType === null
                              ? "N/A"
                              : TransactionData.requestType}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => setOpenCollapse(!openCollapse)}
                            >
                              {openCollapse ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={8}
                          >
                            <Collapse
                              in={openCollapse}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box sx={{ margin: "20px auto" }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "20px",
                                    width: "200px",
                                    marginLeft: "auto",
                                  }}
                                >
                                  {UpdateTransactionStatusDataLoading ? (
                                    <Button
                                      sx={{
                                        height: "35px",
                                        backgroundColor: "#F59E0B",
                                        color: "#fff",
                                        "&:hover": {
                                          backgroundColor: "#E18D00", // Change this to your desired hover color
                                        },
                                      }}
                                      fullWidth
                                    >
                                      <CircularProgress />
                                    </Button>
                                  ) : (
                                    <Button
                                      sx={{
                                        height: "35px",
                                        backgroundColor: "#F59E0B",
                                        color: "#fff",
                                        "&:hover": {
                                          backgroundColor: "#E18D00", // Change this to your desired hover color
                                        },
                                      }}
                                      fullWidth
                                      onClick={handleUpdateTransactionStatus}
                                    >
                                      Update
                                    </Button>
                                  )}
                                </Box>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          {/* )} */}
        </TabPanel>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            border: "solid 1px #E0E0E0",
            borderRadius: "8px",
            marginTop: "1rem",
          }}
        ></Box>
      </Box>
    </div>
  );
};

export default TransactionsLog;

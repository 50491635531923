import { useState } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleIcon from "@mui/icons-material/People";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { CardMedia, CircularProgress, Typography } from "@mui/material";
import { Tooltip } from "react-tooltip";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import "../../styles/Sidebar.css";
import { SignOut } from "../../api/Data";
import { ROUTES } from "../../components/constants/RouteData";
import TwoFAModal from "../../components/modals/TwoFAModal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TwoFASwitch from "../../components/switch/TwoFASwitch";
import { useAppSelector } from "../../state/hooks";

const QLogo = `${process.env.PUBLIC_URL}/assets/images/QOSPAY.png`;
const qLogo = `${process.env.PUBLIC_URL}/assets/images/q.png`;
const drawerWidth = 220;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 10px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 10px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export function Sidebar({ children }) {
  const check2FAStatus = useAppSelector((state) => state.isUsing2FA);
  const [open, setOpen] = useState(true);
  const [selectedItem, setSelectedItem] = useState("dashboard");
  const [openDropdown, setOpenDropdown] = useState(null); // State to track dropdown visibility
  const [selectedDropdownItem, setSelectedDropdownItem] = useState("");
  const [open2FAModal, setOpen2FAModal] = useState(false);
  // const handleOpen2FAModal = () => setOpen2FAModal(true);
  const handleClose2FAModal = () => setOpen2FAModal(false);
  const [qrCodeData, setQrCodeData] = useState("");
  const [mfaCodeData, setMfaCodeData] = useState("");
  const [twoFALoading, setTwoFALoading] = useState(false);
  const TwoFAStatus = check2FAStatus.isUsing2FA;

  const handleButtonClick = (dropdownName) => {
    setSelectedItem(dropdownName);
    if (openDropdown === dropdownName) {
      setOpenDropdown(null); // Close the dropdown if it's already open
    } else {
      setOpenDropdown(dropdownName); // Open the clicked dropdown
    }
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    setOpenDropdown(null);
  };
  const handleSignOut = async () => {
    SignOut();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <ToastContainer />
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{
          backgroundColor: "white",
          boxShadow: 0,
          borderBottom: "1px solid #E0E0E0",
          paddingLeft: open ? "0px" : "80px",
        }}
      >
        <Toolbar>
          <Box sx={{ display: "flex", gap: "30px" }}>
            {open ? (
              <IconButton
                onClick={handleDrawerClose}
                edge="start  sx={{
                    marginRight: 5,
                  }}"
              >
                <KeyboardBackspaceIcon />
              </IconButton>
            ) : (
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "200px",
                alignItems: "center",
              }}
            >
              {!TwoFAStatus && (
                <Typography
                  sx={{ color: "#000", fontSize: "12px", fontWeight: "600" }}
                >
                  Enable 2FA
                </Typography>
              )}
              {twoFALoading ? (
                <CircularProgress />
              ) : (
                <Box>
                  {!TwoFAStatus && (
                    <Box>
                      <TwoFASwitch />
                    </Box>
                  )}
                </Box>
              )}
              <TwoFAModal
                open={open2FAModal}
                handleClose2FAModal={handleClose2FAModal}
                qrCodeData={qrCodeData}
                mfaCodeData={mfaCodeData}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              cursor: "pointer",
            }}
          >
            <ExitToAppIcon
              onClick={handleSignOut}
              sx={{ marginLeft: "auto", fontSize: "2rem", color: "orange" }}
              data-tooltip-id="signout-tooltip"
              data-tooltip-content="Sign Out"
            />
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Drawer
          variant="permanent"
          open={open}
          sx={{
            "& .MuiDrawer-paper": {
              scrollbarWidth: "none", // Hide the scrollbar in Firefox
              msOverflowStyle: "none", // Hide the scrollbar in IE and Edge
              "&::-webkit-scrollbar": {
                display: "none", // Hide the scrollbar in Chrome, Safari, and Opera
              },
            },
          }}
        >
          <DrawerHeader>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {open ? (
                <CardMedia
                  component="img"
                  height="30px"
                  image={QLogo}
                  alt="QOS Logo"
                  sx={{ objectFit: "contain", marginLeft: "10px" }}
                />
              ) : (
                <CardMedia
                  component="img"
                  height="30px"
                  image={qLogo}
                  alt="QOS Logo"
                  sx={{ objectFit: "contain", marginLeft: "10px" }}
                />
              )}
            </Box>
          </DrawerHeader>
          <Divider />
          <List>
            {/* Dashboard  */}
            <ListItem disablePadding sx={{ display: "block" }}>
              <Link
                to={ROUTES.DASHBOARD}
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 28,
                    width: "80%",
                    margin: "10px auto",
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    borderRadius: 2,
                    backgroundColor:
                      selectedItem === "dashboard" ? "#F59E0B" : "inherit",
                    color: selectedItem === "dashboard" ? "#FFF" : "inherit",
                    "&:hover": {
                      backgroundColor:
                        selectedItem === "dashboard" ? "#F59E0B" : "inherit",
                    },
                  }}
                  onClick={() => setSelectedItem("dashboard")}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: selectedItem === "dashboard" ? "#FFF" : "inherit",
                    }}
                  >
                    <HomeOutlinedIcon
                      data-tooltip-id="tooltip"
                      data-tooltip-content="Dashboard"
                      sx={{ fontSize: "14px" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography sx={{ fontSize: "12px" }}>
                        Dashboard
                      </Typography>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
            {/* User Management  */}
            <ListItem disablePadding sx={{ display: "block" }}>
              <Link
                to={ROUTES.COMING_SOON}
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 28,
                    width: "80%",
                    margin: "10px auto",
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    borderRadius: 2,
                    backgroundColor:
                      selectedItem === "usermanagement" ? "#F59E0B" : "inherit",
                    color:
                      selectedItem === "usermanagement" ? "#FFF" : "inherit",
                    "&:hover": {
                      backgroundColor:
                        selectedItem === "usermanagement"
                          ? "#F59E0B"
                          : "inherit",
                    },
                  }}
                  onClick={() => setSelectedItem("usermanagement")}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color:
                        selectedItem === "usermanagement" ? "#FFF" : "inherit",
                    }}
                  >
                    <ManageAccountsOutlinedIcon
                      data-tooltip-id="tooltip"
                      data-tooltip-content="User Management"
                      sx={{ fontSize: "14px" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography sx={{ fontSize: "12px" }}>
                        User Management
                      </Typography>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
            {/* Merchant  */}
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 28,
                  width: "80%",
                  margin: "10px auto",
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  borderRadius: 2,
                  backgroundColor:
                    selectedItem === "merchant" ? "#F59E0B" : "inherit",
                  color: selectedItem === "merchant" ? "#FFF" : "inherit",
                  "&:hover": {
                    backgroundColor:
                      selectedItem === "merchant" ? "#F59E0B" : "inherit",
                  },
                }}
                onClick={() => (open ? handleButtonClick("merchant") : "")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: selectedItem === "merchant" ? "#FFF" : "inherit",
                  }}
                >
                  <PersonOutlineOutlinedIcon
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Merchant"
                    sx={{ fontSize: "14px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ fontSize: "12px" }}>Merchant</Typography>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
                {openDropdown === "merchant" ? (
                  <ExpandLessIcon
                    sx={{ display: open ? "block" : "none", fontSize: "12px" }}
                  />
                ) : (
                  <ExpandMoreIcon
                    sx={{ display: open ? "block" : "none", fontSize: "12px" }}
                  />
                )}
              </ListItemButton>
              {openDropdown === "merchant" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    margin: "-30px auto auto auto",
                    paddingTop: "25px",
                    width: "80%",
                    backgroundColor: "#F9F9F9",
                    borderRadius: 2,
                    zIndex: "-2",
                  }}
                >
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <Link
                      to={ROUTES.MERCHANT_OVERVIEW}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItemButton
                        sx={{
                          height: "50px",
                          width: "100%",
                          marginLeft: open ? "auto" : "auto",
                          marginRight: open ? "13px" : "5px",
                          justifyContent: open ? "initial" : "center",

                          borderRadius: 2,
                          backgroundColor:
                            selectedDropdownItem === "merchantoverview"
                              ? "#F9F9F9"
                              : "inherit",
                          color:
                            selectedDropdownItem === "merchantoverview"
                              ? "black"
                              : "inherit",
                          "&:hover": {
                            backgroundColor:
                              selectedDropdownItem === "merchantoverview"
                                ? "#F9F9F1"
                                : "inherit",
                          },
                        }}
                        onClick={() =>
                          setSelectedDropdownItem("merchantoverview")
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color:
                              selectedItem === "merchantoverview"
                                ? "black"
                                : "inherit",
                          }}
                        ></ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: "12px" }}>
                              Merchant Overview
                            </Typography>
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>{" "}
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <Link
                      to={ROUTES.MERCHANT_LIST}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItemButton
                        sx={{
                          height: "50px",
                          width: "100%",
                          marginLeft: open ? "auto" : "auto",
                          marginRight: open ? "13px" : "5px",
                          justifyContent: open ? "initial" : "center",

                          borderRadius: 2,
                          backgroundColor:
                            selectedDropdownItem === "managemerchant"
                              ? "#F9F9F9"
                              : "inherit",
                          color:
                            selectedDropdownItem === "managemerchant"
                              ? "black"
                              : "inherit",
                          "&:hover": {
                            backgroundColor:
                              selectedDropdownItem === "managemerchant"
                                ? "#F9F9F1"
                                : "inherit",
                          },
                        }}
                        onClick={() =>
                          setSelectedDropdownItem("managemerchant")
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color:
                              selectedItem === "managemerchant"
                                ? "black"
                                : "inherit",
                          }}
                        ></ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: "12px" }}>
                              Manage Merchants
                            </Typography>
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                </Box>
              )}
            </ListItem>
            {/* Compliance  */}
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 28,
                  width: "80%",
                  margin: "10px auto",
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  borderRadius: 2,
                  backgroundColor:
                    selectedItem === "compliance" ? "#F59E0B" : "inherit",
                  color: selectedItem === "compliance" ? "#FFF" : "inherit",
                  "&:hover": {
                    backgroundColor:
                      selectedItem === "compliance" ? "#F59E0B" : "inherit",
                  },
                }}
                onClick={() => (open ? handleButtonClick("compliance") : "")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: selectedItem === "compliance" ? "#FFF" : "inherit",
                  }}
                >
                  <HowToRegOutlinedIcon
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Compliance"
                    sx={{ fontSize: "14px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ fontSize: "12px" }}>
                      Compliance
                    </Typography>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
                {openDropdown === "compliance" ? (
                  <ExpandLessIcon
                    sx={{ display: open ? "block" : "none", fontSize: "12px" }}
                  />
                ) : (
                  <ExpandMoreIcon
                    sx={{ display: open ? "block" : "none", fontSize: "12px" }}
                  />
                )}
              </ListItemButton>
              {openDropdown === "compliance" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    margin: "-30px auto auto auto",
                    paddingTop: "25px",
                    width: "80%",
                    backgroundColor: "#F9F9F9",
                    borderRadius: 2,
                    zIndex: "-2",
                  }}
                >
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <Link
                      to={ROUTES.ACTIVATION_REQUEST}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItemButton
                        sx={{
                          height: "50px",
                          width: "100%",
                          marginLeft: open ? "auto" : "auto",
                          marginRight: open ? "13px" : "5px",
                          justifyContent: open ? "initial" : "center",

                          borderRadius: 2,
                          backgroundColor:
                            selectedDropdownItem === "activationrequests"
                              ? "#F9F9F9"
                              : "inherit",
                          color:
                            selectedDropdownItem === "activationrequests"
                              ? "black"
                              : "inherit",
                          "&:hover": {
                            backgroundColor:
                              selectedDropdownItem === "activationrequests"
                                ? "#F9F9F1"
                                : "inherit",
                          },
                        }}
                        onClick={() =>
                          setSelectedDropdownItem("activationrequests")
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color:
                              selectedItem === "activationrequests"
                                ? "black"
                                : "inherit",
                          }}
                        ></ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: "12px" }}>
                              Activation Requests
                            </Typography>
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                </Box>
              )}
            </ListItem>
            {/* Transaction */}
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 28,
                  width: "80%",
                  margin: "10px auto",
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  borderRadius: 2,
                  backgroundColor:
                    selectedItem === "transaction" ? "#F59E0B" : "inherit",
                  color: selectedItem === "transaction" ? "#FFF" : "inherit",
                  "&:hover": {
                    backgroundColor:
                      selectedItem === "transaction" ? "#F59E0B" : "inherit",
                  },
                }}
                onClick={() => (open ? handleButtonClick("transaction") : "")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: selectedItem === "transaction" ? "#FFF" : "inherit",
                  }}
                >
                  <CreditCardOutlinedIcon
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Transaction"
                    sx={{ fontSize: "14px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ fontSize: "12px" }}>
                      Transaction
                    </Typography>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
                {openDropdown === "transaction" ? (
                  <ExpandLessIcon
                    sx={{ display: open ? "block" : "none", fontSize: "12px" }}
                  />
                ) : (
                  <ExpandMoreIcon
                    sx={{ display: open ? "block" : "none", fontSize: "12px" }}
                  />
                )}
              </ListItemButton>

              {openDropdown === "transaction" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    margin: "-30px auto auto auto",
                    paddingTop: "25px",
                    width: "80%",
                    backgroundColor: "#F9F9F9",
                    borderRadius: 2,
                    zIndex: "-2",
                  }}
                >
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <Link
                      to={ROUTES.TRANSACTION_LOG}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItemButton
                        sx={{
                          height: "50px",
                          width: "100%",
                          marginLeft: open ? "auto" : "auto",
                          marginRight: open ? "13px" : "5px",
                          justifyContent: open ? "initial" : "center",

                          borderRadius: 2,
                          backgroundColor:
                            selectedDropdownItem === "transactionlog"
                              ? "#F9F9F9"
                              : "inherit",
                          color:
                            selectedDropdownItem === "transactionlog"
                              ? "black"
                              : "inherit",
                          "&:hover": {
                            backgroundColor:
                              selectedDropdownItem === "transactionlog"
                                ? "#F9F9F1"
                                : "inherit",
                          },
                        }}
                        onClick={() =>
                          setSelectedDropdownItem("transactionlog")
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color:
                              selectedItem === "activationrequests"
                                ? "black"
                                : "inherit",
                          }}
                        ></ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: "12px" }}>
                              Transaction Log
                            </Typography>
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                </Box>
              )}
            </ListItem>
            {/* Payment */}
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 28,
                  width: "80%",
                  margin: "10px auto",
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  borderRadius: 2,
                  backgroundColor:
                    selectedItem === "payment" ? "#F59E0B" : "inherit",
                  color: selectedItem === "payment" ? "#FFF" : "inherit",
                  "&:hover": {
                    backgroundColor:
                      selectedItem === "payment" ? "#F59E0B" : "inherit",
                  },
                }}
                onClick={() => (open ? handleButtonClick("payment") : "")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: selectedItem === "payment" ? "#FFF" : "inherit",
                  }}
                >
                  <PeopleIcon
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Payment"
                    sx={{ fontSize: "14px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ fontSize: "12px" }}>Payment</Typography>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
                {openDropdown === "payment" ? (
                  <ExpandLessIcon
                    sx={{ display: open ? "block" : "none", fontSize: "12px" }}
                  />
                ) : (
                  <ExpandMoreIcon
                    sx={{ display: open ? "block" : "none", fontSize: "12px" }}
                  />
                )}
              </ListItemButton>

              {openDropdown === "payment" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    margin: "-30px auto auto auto",
                    paddingTop: "25px",
                    width: "80%",
                    backgroundColor: "#F9F9F9",
                    borderRadius: 2,
                    zIndex: "-2",
                  }}
                >
                  {/* Payment Overview */}
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <Link
                      to={ROUTES.FINANCE_OVERVIEW}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItemButton
                        sx={{
                          height: "50px",
                          width: "100%",
                          marginLeft: open ? "auto" : "auto",
                          marginRight: open ? "13px" : "5px",
                          justifyContent: open ? "initial" : "center",

                          borderRadius: 2,
                          backgroundColor:
                            selectedDropdownItem === "paymentoverview"
                              ? "#F9F9F9"
                              : "inherit",
                          color:
                            selectedDropdownItem === "paymentoverview"
                              ? "black"
                              : "inherit",
                          "&:hover": {
                            backgroundColor:
                              selectedDropdownItem === "paymentoverview"
                                ? "#F9F9F1"
                                : "inherit",
                          },
                        }}
                        onClick={() =>
                          setSelectedDropdownItem("paymentoverview")
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color:
                              selectedItem === "Payment Overview"
                                ? "black"
                                : "inherit",
                          }}
                        ></ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: "12px" }}>
                              Overview
                            </Typography>
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                  {/* Revenue */}
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <Link
                      to={ROUTES.REVENUE}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItemButton
                        sx={{
                          height: "50px",
                          width: "100%",
                          marginLeft: open ? "auto" : "auto",
                          marginRight: open ? "13px" : "5px",
                          justifyContent: open ? "initial" : "center",

                          borderRadius: 2,
                          backgroundColor:
                            selectedDropdownItem === "revenue"
                              ? "#F9F9F9"
                              : "inherit",
                          color:
                            selectedDropdownItem === "revenue"
                              ? "black"
                              : "inherit",
                          "&:hover": {
                            backgroundColor:
                              selectedDropdownItem === "revenue"
                                ? "#F9F9F1"
                                : "inherit",
                          },
                        }}
                        onClick={() => setSelectedDropdownItem("revenue")}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color:
                              selectedItem === "Revenue" ? "black" : "inherit",
                          }}
                        ></ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: "12px" }}>
                              Revenue
                            </Typography>
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                            {/* Merchant Payment */}
                            <ListItem disablePadding sx={{ display: "block" }}>
                    <Link
                      to={ROUTES.MERCHANT_PAYMET}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItemButton
                        sx={{
                          height: "50px",
                          width: "100%",
                          marginLeft: open ? "auto" : "auto",
                          marginRight: open ? "13px" : "5px",
                          justifyContent: open ? "initial" : "center",

                          borderRadius: 2,
                          backgroundColor:
                            selectedDropdownItem === "merchantpayment"
                              ? "#F9F9F9"
                              : "inherit",
                          color:
                            selectedDropdownItem === "merchantpayment"
                              ? "black"
                              : "inherit",
                          "&:hover": {
                            backgroundColor:
                              selectedDropdownItem === "merchantpayment"
                                ? "#F9F9F1"
                                : "inherit",
                          },
                        }}
                        onClick={() => setSelectedDropdownItem("merchantpayment")}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color:
                              selectedItem === "Merchant Payment"
                                ? "black"
                                : "inherit",
                          }}
                        ></ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: "12px" }}>
                             Merchant Payment
                            </Typography>
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                  {/* Manage Rates */}
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <Link
                      to={ROUTES.MANAGE_RATES}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItemButton
                        sx={{
                          height: "50px",
                          width: "100%",
                          marginLeft: open ? "auto" : "auto",
                          marginRight: open ? "13px" : "5px",
                          justifyContent: open ? "initial" : "center",

                          borderRadius: 2,
                          backgroundColor:
                            selectedDropdownItem === "managerate"
                              ? "#F9F9F9"
                              : "inherit",
                          color:
                            selectedDropdownItem === "managerate"
                              ? "black"
                              : "inherit",
                          "&:hover": {
                            backgroundColor:
                              selectedDropdownItem === "managerate"
                                ? "#F9F9F1"
                                : "inherit",
                          },
                        }}
                        onClick={() => setSelectedDropdownItem("managerate")}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color:
                              selectedItem === "Manage Rates"
                                ? "black"
                                : "inherit",
                          }}
                        ></ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: "12px" }}>
                              Manage Rates
                            </Typography>
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                  {/* Payment Report
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <Link
                      to={ROUTES.FINANCE_REPORT}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItemButton
                        sx={{
                          height: "50px",
                          width: "100%",
                          marginLeft: open ? "auto" : "auto",
                          marginRight: open ? "13px" : "5px",
                          justifyContent: open ? "initial" : "center",

                          borderRadius: 2,
                          backgroundColor:
                            selectedDropdownItem === "paymentreport"
                              ? "#F9F9F9"
                              : "inherit",
                          color:
                            selectedDropdownItem === "paymentreport"
                              ? "black"
                              : "inherit",
                          "&:hover": {
                            backgroundColor:
                              selectedDropdownItem === "paymentreport"
                                ? "#F9F9F1"
                                : "inherit",
                          },
                        }}
                        onClick={() => setSelectedDropdownItem("paymentreport")}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color:
                              selectedItem === "Payment Report"
                                ? "black"
                                : "inherit",
                          }}
                        ></ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: "12px" }}>
                              Payment Report
                            </Typography>
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem> */}
                  {/* Top Up */}
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <Link
                      to={ROUTES.TOPUP}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItemButton
                        sx={{
                          height: "50px",
                          width: "100%",
                          marginLeft: open ? "auto" : "auto",
                          marginRight: open ? "13px" : "5px",
                          justifyContent: open ? "initial" : "center",

                          borderRadius: 2,
                          backgroundColor:
                            selectedDropdownItem === "topup"
                              ? "#F9F9F9"
                              : "inherit",
                          color:
                            selectedDropdownItem === "topup"
                              ? "black"
                              : "inherit",
                          "&:hover": {
                            backgroundColor:
                              selectedDropdownItem === "topup"
                                ? "#F9F9F1"
                                : "inherit",
                          },
                        }}
                        onClick={() => setSelectedDropdownItem("topup")}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color:
                              selectedItem === "Topup" ? "black" : "inherit",
                          }}
                        ></ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: "12px" }}>
                             Merchant Topup
                            </Typography>
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                        {/* Pay Out */}
                       <ListItem disablePadding sx={{ display: "block" }}>
                    <Link
                      to={ROUTES.PAYOUT}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItemButton
                        sx={{
                          height: "50px",
                          width: "100%",
                          marginLeft: open ? "auto" : "auto",
                          marginRight: open ? "13px" : "5px",
                          justifyContent: open ? "initial" : "center",

                          borderRadius: 2,
                          backgroundColor:
                            selectedDropdownItem === "payout"
                              ? "#F9F9F9"
                              : "inherit",
                          color:
                            selectedDropdownItem === "payout"
                              ? "black"
                              : "inherit",
                          "&:hover": {
                            backgroundColor:
                              selectedDropdownItem === "payout"
                                ? "#F9F9F1"
                                : "inherit",
                          },
                        }}
                        onClick={() => setSelectedDropdownItem("payout")}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color:
                              selectedItem === "Payout" ? "black" : "inherit",
                          }}
                        ></ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: "12px" }}>
                              Merchant Payout
                            </Typography>
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>  
                </Box>
              )}
            </ListItem>
            {/* Sales */}
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 28,
                  width: "80%",
                  margin: "10px auto",
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  borderRadius: 2,
                  backgroundColor:
                    selectedItem === "sales" ? "#F59E0B" : "inherit",
                  color: selectedItem === "sales" ? "#FFF" : "inherit",
                  "&:hover": {
                    backgroundColor:
                      selectedItem === "sales" ? "#F59E0B" : "inherit",
                  },
                }}
                onClick={() => (open ? handleButtonClick("sales") : "")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: selectedItem === "sales" ? "#FFF" : "inherit",
                  }}
                >
                  <PeopleIcon
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Sales"
                    sx={{ fontSize: "14px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ fontSize: "12px" }}>Sales</Typography>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
                {openDropdown === "sales" ? (
                  <ExpandLessIcon
                    sx={{ display: open ? "block" : "none", fontSize: "12px" }}
                  />
                ) : (
                  <ExpandMoreIcon
                    sx={{ display: open ? "block" : "none", fontSize: "12px" }}
                  />
                )}
              </ListItemButton>
              {openDropdown === "sales" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    margin: "-30px auto auto auto",
                    paddingTop: "25px",
                    width: "80%",
                    backgroundColor: "#F9F9F9",
                    borderRadius: 2,
                    zIndex: "-2",
                  }}
                >
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <Link
                      to={ROUTES.COMING_SOON}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItemButton
                        sx={{
                          height: "50px",
                          width: "100%",
                          marginLeft: open ? "auto" : "auto",
                          marginRight: open ? "13px" : "5px",
                          justifyContent: open ? "initial" : "center",

                          borderRadius: 2,
                          backgroundColor:
                            selectedDropdownItem === "salesperformance"
                              ? "#F9F9F9"
                              : "inherit",
                          color:
                            selectedDropdownItem === "salesperformance"
                              ? "black"
                              : "inherit",
                          "&:hover": {
                            backgroundColor:
                              selectedDropdownItem === "salesperformance"
                                ? "#F9F9F1"
                                : "inherit",
                          },
                        }}
                        onClick={() =>
                          setSelectedDropdownItem("salesperformance")
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color:
                              selectedItem === "Sales Performance"
                                ? "black"
                                : "inherit",
                          }}
                        ></ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: "12px" }}>
                              Sales Performance
                            </Typography>
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <Link
                      to={ROUTES.COMING_SOON}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItemButton
                        sx={{
                          height: "50px",
                          width: "100%",
                          marginLeft: open ? "auto" : "auto",
                          marginRight: open ? "13px" : "5px",
                          justifyContent: open ? "initial" : "center",

                          borderRadius: 2,
                          backgroundColor:
                            selectedDropdownItem === "salesreport"
                              ? "#F9F9F9"
                              : "inherit",
                          color:
                            selectedDropdownItem === "salesreport"
                              ? "black"
                              : "inherit",
                          "&:hover": {
                            backgroundColor:
                              selectedDropdownItem === "salesreport"
                                ? "#F9F9F1"
                                : "inherit",
                          },
                        }}
                        onClick={() => setSelectedDropdownItem("salesreport")}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color:
                              selectedItem === "Sales Report"
                                ? "black"
                                : "inherit",
                          }}
                        ></ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: "12px" }}>
                              Sales Report
                            </Typography>
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <Link
                      to={ROUTES.COMING_SOON}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItemButton
                        sx={{
                          height: "50px",
                          width: "100%",
                          marginLeft: open ? "auto" : "auto",
                          marginRight: open ? "13px" : "5px",
                          justifyContent: open ? "initial" : "center",

                          borderRadius: 2,
                          backgroundColor:
                            selectedDropdownItem === "manageportfolio"
                              ? "#F9F9F9"
                              : "inherit",
                          color:
                            selectedDropdownItem === "manageportfolio"
                              ? "black"
                              : "inherit",
                          "&:hover": {
                            backgroundColor:
                              selectedDropdownItem === "manageportfolio"
                                ? "#F9F9F1"
                                : "inherit",
                          },
                        }}
                        onClick={() =>
                          setSelectedDropdownItem("manageportfolio")
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color:
                              selectedItem === "Manage Portfolio"
                                ? "black"
                                : "inherit",
                          }}
                        ></ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: "12px" }}>
                              Manage Portfolio
                            </Typography>
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <Link
                      to={ROUTES.COMING_SOON}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItemButton
                        sx={{
                          height: "50px",
                          width: "100%",
                          marginLeft: open ? "auto" : "auto",
                          marginRight: open ? "13px" : "5px",
                          justifyContent: open ? "initial" : "center",

                          borderRadius: 2,
                          backgroundColor:
                            selectedDropdownItem === "merchantanalytics"
                              ? "#F9F9F9"
                              : "inherit",
                          color:
                            selectedDropdownItem === "merchantanalytics"
                              ? "black"
                              : "inherit",
                          "&:hover": {
                            backgroundColor:
                              selectedDropdownItem === "merchantanalytics"
                                ? "#F9F9F1"
                                : "inherit",
                          },
                        }}
                        onClick={() =>
                          setSelectedDropdownItem("merchantanalytics")
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color:
                              selectedItem === "Manage Portfolio"
                                ? "black"
                                : "inherit",
                          }}
                        ></ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: "12px" }}>
                              Manage Portfolio
                            </Typography>
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <Link
                      to={ROUTES.COMING_SOON}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItemButton
                        sx={{
                          height: "50px",
                          width: "100%",
                          marginLeft: open ? "auto" : "auto",
                          marginRight: open ? "13px" : "5px",
                          justifyContent: open ? "initial" : "center",

                          borderRadius: 2,
                          backgroundColor:
                            selectedDropdownItem === "portfolioanalytics"
                              ? "#F9F9F9"
                              : "inherit",
                          color:
                            selectedDropdownItem === "portfolioanalytics"
                              ? "black"
                              : "inherit",
                          "&:hover": {
                            backgroundColor:
                              selectedDropdownItem === "portfolioanalytics"
                                ? "#F9F9F1"
                                : "inherit",
                          },
                        }}
                        onClick={() =>
                          setSelectedDropdownItem("portfolioanalytics")
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color:
                              selectedItem === "Portfolio Analytics"
                                ? "black"
                                : "inherit",
                          }}
                        ></ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: "12px" }}>
                              Portfolio Analytics
                            </Typography>
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                </Box>
              )}
            </ListItem>
            {/*  Audit Trail */}
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 28,
                  width: "80%",
                  margin: "10px auto",
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  borderRadius: 2,
                  backgroundColor:
                    selectedItem === "audittrail" ? "#F59E0B" : "inherit",
                  color: selectedItem === "audittrail" ? "#FFF" : "inherit",
                  "&:hover": {
                    backgroundColor:
                      selectedItem === "audittrail" ? "#F59E0B" : "inherit",
                  },
                }}
                onClick={() => (open ? handleButtonClick("audittrail") : "")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: selectedItem === "audittrail" ? "#FFF" : "inherit",
                  }}
                >
                  <ReceiptOutlinedIcon
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Audit Trail"
                    sx={{ fontSize: "14px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ fontSize: "12px" }}>
                      Audit Trail
                    </Typography>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
                {openDropdown === "audittrail" ? (
                  <ExpandLessIcon
                    sx={{ display: open ? "block" : "none", fontSize: "12px" }}
                  />
                ) : (
                  <ExpandMoreIcon
                    sx={{ display: open ? "block" : "none", fontSize: "12px" }}
                  />
                )}
              </ListItemButton>

              {openDropdown === "audittrail" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    margin: "-30px auto auto auto",
                    paddingTop: "25px",
                    width: "80%",
                    backgroundColor: "#F9F9F9",
                    borderRadius: 2,
                    zIndex: "-2",
                  }}
                >
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <Link
                      to={ROUTES.COMING_SOON}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItemButton
                        sx={{
                          height: "50px",
                          width: "100%",
                          marginLeft: open ? "auto" : "auto",
                          marginRight: open ? "13px" : "5px",
                          justifyContent: open ? "initial" : "center",

                          borderRadius: 2,
                          backgroundColor:
                            selectedDropdownItem === "searchaudittrail"
                              ? "#F9F9F9"
                              : "inherit",
                          color:
                            selectedDropdownItem === "searchaudittrail"
                              ? "black"
                              : "inherit",
                          "&:hover": {
                            backgroundColor:
                              selectedDropdownItem === "searchaudittrail"
                                ? "#F9F9F1"
                                : "inherit",
                          },
                        }}
                        onClick={() =>
                          setSelectedDropdownItem("searchaudittrail")
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color:
                              selectedItem === "Search" ? "black" : "inherit",
                          }}
                        ></ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: "12px" }}>
                              Search
                            </Typography>
                          }
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                </Box>
              )}
            </ListItem>
            {/*  Settings */}
            <ListItem disablePadding sx={{ display: "block" }}>
              <Link
                to={ROUTES.SETTINGS}
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 28,
                    width: "80%",
                    margin: "10px auto",
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    borderRadius: 2,
                    backgroundColor:
                      selectedItem === "settings" ? "#F59E0B" : "inherit",
                    color: selectedItem === "settings" ? "#FFF" : "inherit",
                    "&:hover": {
                      backgroundColor:
                        selectedItem === "settings" ? "#F59E0B" : "inherit",
                    },
                  }}
                  onClick={() => setSelectedItem("settings")}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: selectedItem === "settings" ? "#FFF" : "inherit",
                    }}
                  >
                    <SettingsOutlinedIcon
                      data-tooltip-id="tooltip"
                      data-tooltip-content="Settings"
                      sx={{ fontSize: "14px" }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography sx={{ fontSize: "12px" }}>
                        Settings
                      </Typography>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          </List>
          {/* <Divider />
          <List>
            <ListItem disablePadding sx={{ display: "block" }}>
              <Link
                to="/registered-customers"
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 28,
                    width: "80%",
                    margin: "10px auto",
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    borderRadius: 2,
                    backgroundColor:
                      selectedItem === "customer" ? "#F59E0B" : "inherit",
                    color: selectedItem === "customer" ? "#FFF" : "inherit",
                    "&:hover": {
                      backgroundColor:
                        selectedItem === "customer" ? "#F59E0B" : "inherit",
                    },
                  }}
                  onClick={() => setSelectedItem("customer")}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <PeopleIcon
                      data-tooltip-id="tooltip"
                      data-tooltip-content="Customer"
                      sx={{
                        color: selectedItem === "customer" ? "#FFF" : "inherit",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Customers"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
            <ListItem disablePadding sx={{ display: "block" }}>
              <Link
                to="/document-list"
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 28,
                    width: "80%",
                    margin: "10px auto",
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    borderRadius: 2,
                    backgroundColor:
                      selectedItem === "document" ? "#F59E0B" : "inherit",
                    color: selectedItem === "document" ? "#FFF" : "inherit",
                    "&:hover": {
                      backgroundColor:
                        selectedItem === "document" ? "#F59E0B" : "inherit",
                    },
                  }}
                  onClick={() => setSelectedItem("document")}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: selectedItem === "document" ? "#FFF" : "inherit",
                    }}
                  >
                    <DocumentScannerIcon
                      data-tooltip-id="tooltip"
                      data-tooltip-content="Documents"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Document"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          </List> */}
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3, width: "100px" }}>
          <DrawerHeader />
          {/* TOOL TIPS START*/}
          <Tooltip
            id="tooltip"
            place="right"
            style={{
              backgroundColor: "white",
              color: "black",
              marginLeft: "20px",
              zIndex: "100",
              fontWeight: 600,
            }}
          />
          <Tooltip
            id="signout-tooltip"
            place="bottom"
            style={{
              backgroundColor: "white",
              color: "black",
              zIndex: "100",
              fontWeight: 600,
            }}
          />
          {/* TOOL TIPS END*/}
          {children}
        </Box>
      </Box>
    </Box>
  );
}

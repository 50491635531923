import React, { useState, useEffect, ChangeEvent } from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Typography,
  CardMedia,
  Divider,
  Button,
  Skeleton,
  CircularProgress,
  TextField,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { GetDefaultRates } from "../../../../state/feautures/manageRates/getDefaultRatesSlice";
import { useAppDispatch, useAppSelector } from "../../../../state/hooks";
import { UpdateDefaultRate } from "../../../../state/feautures/manageRates/updateDefaultRateSlice";

const Row = ({ key, row, title }: any) => {
  const dispatch = useAppDispatch();
  const [openCollapse, setOpenCollapse] = useState(false);
  const [fee, setFee] = useState<string>("");
  const handleFeeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFee(e.target.value);
  };
  const SetRate = async () => {
    const data = {
      id: row.id,
      operationType: row.operationType,
      operator: row.operator,
      fee: fee,
    };

    dispatch(UpdateDefaultRate(data));
  };
  return (
    <React.Fragment>
      <TableRow
        key={row.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        {" "}
        <TableCell component="th" scope="row">
          {row.operator === null ? "N/A" : row.operator}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.operationType === null ? "N/A" : row.operationType}
        </TableCell>
        {/* <TableCell>{row.name}{row.email}</TableCell> */}
        <TableCell align="left">
          <Box>{row.fee === null ? "N/A" : row.fee}</Box>
        </TableCell>
        <TableCell align="center">
          {row.modifiedBy === null ? "N/A" : row.modifiedBy}
        </TableCell>
        <TableCell align="center">
          {row.modifiedAt === null ? "N/A" : row.modifiedAt}
        </TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenCollapse(!openCollapse)}
          >
            {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <Box sx={{ margin: "20px auto" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  width: "300px",
                  marginLeft: "auto",
                }}
              >
                <TextField
                  id="outlined-number"
                  label="Set fee"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: 0, // Minimum value
                    max: 4, // Maximum value
                  }}
                  value={fee}
                  onChange={handleFeeChange}
                />
                {/* <Button
                  sx={{
                    color: "#F59E0B",
                    height: "35px",
                    border: "solid 1px #F59E0B",
                    backgroundColor: "#FFF",
                    "&:hover": {
                      backgroundColor: "whitesmoke", // Change this to your desired hover color
                    },
                  }}
                  fullWidth
                >
                  Reject
                </Button> */}
                <Button
                  sx={{
                    height: "55px",
                    width: "100px",
                    backgroundColor: "#F59E0B",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#E18D00", // Change this to your desired hover color
                    },
                  }}
                  onClick={SetRate}
                  fullWidth
                >
                  Set
                </Button>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
const DefaultRatesTable = ({ title }: any) => {
  const dispatch = useAppDispatch();
  const { data, loading, error }: any = useAppSelector(
    (state) => state.defaultRate
  );
  const [rows, setRow] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
 
  useEffect(() => {
    dispatch(GetDefaultRates());
  }, [dispatch]);
  useEffect(() => {
    if (Array.isArray(data)) {
      setRow(data);
    }
  }, [data]);

  const handleChange = (e: any) => {
    const selectedPage = parseInt(e.target.value);
    setCurrentPage(selectedPage);
  };
  const getTotalPages = () => Math.ceil(rows.length / itemsPerPage);

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <Box sx={{ boxShadow: 1 }}>
      <Box
        sx={{
          padding: "15px",
          display: "flex",
          gap: "1rem",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Typography
          sx={{ textAlign: "left", fontSize: "25px", fontWeight: 500 }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: { xs: "100%", md: "400px" },
          }}
        ></Box>
      </Box>
      <Divider />
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          overflowX: "auto",
          borderRadius: "0",
          border: "none",
          boxShadow: 0,
        }}
      >
        <Table aria-label="collapsible table" sx={{ padding: 0, margin: 0 }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#FCDFAE1A", textTransform: "" }}>
              <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>
                Operator
              </TableCell>
              <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>
                Operation Type
              </TableCell>
              <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>
                Fee
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Modified By
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Last Modified
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {loading && data.lenght === 0 ? (
            <TableBody>
              <CircularProgress />
            </TableBody>
          ) : data.lenght !== 0 ? (
            <TableBody>
              {rows
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((row: any) => (
                  <Row key={row.email} row={row} title={title} />
                ))}
            </TableBody>
          ) : (
            <TableBody>
              <Typography>Error</Typography>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          gap: "20px",
          fontSize: "13px",
          backgroundColor: "#FCDFAE1A",
          color: "#6E6893",
          padding: "10px 0",
          borderRadius: " 0 0 10px 10px",
        }}
      >
        <Typography sx={{ fontWeight: 600, fontSize: "13px" }}>
          Rows per page: {itemsPerPage}
        </Typography>
        <Box>
          <select
            id="pageDropdown"
            onChange={handleChange}
            value={currentPage}
            style={{ border: "none", width: "17px" }}
          >
            {Array.from({ length: getTotalPages() }).map(
              (_: any, index): any => (
                <option key={index} value={index + 1}>
                  {index + 1}
                </option>
              )
            )}
          </select>
        </Box>
        <label htmlFor="pageDropdown">
          {currentPage} of {getTotalPages()}
        </label>
        <Button disabled={currentPage === 1} onClick={goToPreviousPage}>
          <NavigateBeforeIcon />
        </Button>
        <Button
          disabled={currentPage === getTotalPages()}
          onClick={goToNextPage}
        >
          <NavigateNextIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default DefaultRatesTable;

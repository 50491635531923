import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Skeleton,
  CardMedia,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TopMerchants from "./TopMerchants";
import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useAppDispatch, useAppSelector } from "../../../../state/hooks";
import { GetPaymentAnalystics } from "../../../../state/feautures/finance/getPaymentAnalysticsSlice";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { GetTopTenTransaction } from "../../../../state/feautures/finance/getTopTenTransactionSlice";
import { GetAccountBalance } from "../../../../state/feautures/finance/getAccountBalanceSlice";
import { GetTransactionCount } from "../../../../state/feautures/finance/getTransactionCountSlice";
import { GetTransactionValue } from "../../../../state/feautures/finance/getTransactionValueSlice";
import {
  AccountBalanceItem,
  TransactionCountItem,
  TransactionValueItem,
} from "../../../../types/FinanceData";
import { InactiveIcon } from "../../../../common/assets/icons";

interface TransactionData {
  typeTransaction: string;
  nbre?: number;
  amount?: number;
}
interface Credentials {
  startDate: string;
  endDate: string;
  operator: string;
}

function mergeData(
  data1: TransactionData[],
  data2: TransactionData[]
): TransactionData[] {
  const mergedMap = new Map<string, TransactionData>();

  // Merge data from first array
  data1.forEach((item) => {
    const { typeTransaction, nbre } = item;
    let existingItem = mergedMap.get(typeTransaction);
    if (!existingItem) {
      mergedMap.set(typeTransaction, {
        typeTransaction,
        amount: 0,
        nbre: nbre || 0,
      });
    } else {
      existingItem.nbre = (existingItem.nbre || 0) + (nbre || 0);
    }
  });

  // Merge data from second array
  data2.forEach((item) => {
    const { typeTransaction, amount } = item;
    let existingItem = mergedMap.get(typeTransaction);
    if (!existingItem) {
      mergedMap.set(typeTransaction, {
        typeTransaction,
        nbre: 0,
        amount: amount || 0,
      });
    } else {
      existingItem.amount = (existingItem.amount || 0) + (amount || 0);
    }
  });

  // Convert map values to array of objects
  const mergedData = Array.from(mergedMap.values());

  return mergedData;
}

interface AnalyticsData {
  count: number;
  value: number;
  percentage: number;
}

interface AnalyticsProps {
  analyticsData: {
    failed: Record<string, AnalyticsData>;
    successful: Record<string, AnalyticsData>;
  };
}
const AnalyticsDisplay: React.FC<AnalyticsProps> = ({ analyticsData }) => {
  const renderData = (
    data: Record<string, AnalyticsData>,
    status: "successful" | "failed"
  ) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Typography
        sx={{
          color: status === "successful" ? "#797D8C" : "#FF5403",
          border: `1px solid ${
            status === "successful" ? "#1FAF38" : "#FF5403"
          }`,
          background: `rgba(${
            status === "successful" ? "31, 175, 56" : "255, 84, 3"
          }, 0.1)`,
          height: "20px",
          borderRadius: "8px",
          fontSize: "14px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          justifyContent: "space-between",
        }}
      >
        {Object.keys(data).map((key) => (
          <Box
            key={key}
            sx={{
              display: "flex",
              // flexDirection: "column",
              gap: "15px",
            }}
          >
            <Typography sx={{ fontSize: "14px", color: "#797D8C" }}>
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </Typography>
            <Typography sx={{ fontSize: "14px", color: "#797D8C" }}>
              {data[key].count}
            </Typography>
            <Typography sx={{ fontSize: "14px", color: "#797D8C" }}>
              {data[key].value.toLocaleString()} XOF
            </Typography>
            <Typography sx={{ fontSize: "14px", color: "#797D8C" }}>
              {data[key].percentage}%
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      {renderData(analyticsData.successful, "successful")}
      {renderData(analyticsData.failed, "failed")}
    </Box>
  );
};
function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
} 

const formatNumber = (number: any) => {
  return number ? number.toLocaleString('en-US') : '0';
};
const FinanceOverview = () => { 
  const dispatch = useAppDispatch();
  const PaymentAnalystics = useAppSelector((state) => state.paymentanalystics);

  const PaymentAnalysticsLoading = PaymentAnalystics.loading;
  const PaymentAnalysticsTopMerchants =
    PaymentAnalystics?.data?.datas?.topMerchants;
    const analyticsData = PaymentAnalystics?.data?.datas?.analyticsData;

    // Ensure analyticsData and total are defined before destructuring
    const total = analyticsData?.total || {};
    const successful = analyticsData?.successful || {};
    const failed = analyticsData?.failed || {};
    
    // Check if total is defined before calling Object.keys
    const titles = total ? Object.keys(total) : [];
  const [value, setValue] = useState(0); 
  const [operator, setOperator] = React.useState("MTN");
  const [customDate, setCustomDate] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const formatDate = (date: Date | null) => {
    return date ? dayjs(date).format("YYYY-MM-DD") : "";
  };
  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  }; 
  useEffect(() => {
    const credential = {
      operator: operator,
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(GetPaymentAnalystics(credential));
  }, [dispatch]);
  useEffect(() => {
    const getDateRangeForValue = (
      value: number
    ): { start: string; end: string } => {
      const today = new Date();
      const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      // Initialize startDate and endDate
      let startDate: Date;
      let endDate: Date;

      switch (value) {
        case 0:
          startDate = today;
          endDate = today;
          break;
        case 1:
          const dayOfWeek = today.getDay();
          const startOfWeek = 0; // Sunday
          const endOfWeek = 6; // Saturday

          startDate = new Date(today);
          startDate.setDate(today.getDate() - dayOfWeek + startOfWeek);

          endDate = new Date(today);
          endDate.setDate(today.getDate() + (endOfWeek - dayOfWeek));
          break;
        case 2:
          startDate = new Date(today.getFullYear(), today.getMonth(), 1);
          endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
          break;
        case 3:
          const quarter = Math.floor(today.getMonth() / 3);
          switch (quarter) {
            case 0:
              startDate = new Date(today.getFullYear(), 0, 1); // Q1 start
              endDate = new Date(today.getFullYear(), 2, 31); // Q1 end
              break;
            case 1:
              startDate = new Date(today.getFullYear(), 3, 1); // Q2 start
              endDate = new Date(today.getFullYear(), 5, 30); // Q2 end
              break;
            case 2:
              startDate = new Date(today.getFullYear(), 6, 1); // Q3 start
              endDate = new Date(today.getFullYear(), 8, 30); // Q3 end
              break;
            case 3:
              startDate = new Date(today.getFullYear(), 9, 1); // Q4 start
              endDate = new Date(today.getFullYear(), 11, 31); // Q4 end
              break;
            default:
              throw new Error("Invalid Quartar");
          }
          break;
        case 4:
          startDate = new Date("2010-01-01");
          endDate = today;
          break;
        default:
          startDate = new Date("");
          endDate = new Date("");
      }
      return {
        start: formatDate(startDate),
        end: formatDate(endDate),
      };
    };
    const dateRange = getDateRangeForValue(value);
    const requestCredentials: Credentials = {
      startDate: dateRange.start,
      endDate: dateRange.end,
      operator: operator,
    };
    if (value !== 5) {
      dispatch(GetPaymentAnalystics(requestCredentials));
    }
  }, [dispatch, value, operator]);

  const handleCustomDate = () => {
    setCustomDate(!customDate);
    const requestCredentials: Credentials = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      operator: operator,
    };
    dispatch(GetPaymentAnalystics(requestCredentials));
  };

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const handleOperatorChange = (event: SelectChangeEvent) => {
    setOperator(event.target.value);
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      {/* Heading */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            marginBottom: "20px",
          }}
        >
          <Typography
            sx={{ fontSize: "24px", color: "#45464E", marginBottom: "-5px" }}
          >
            Payment Overview
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              color: "#B2B2B2",
              fontWeight: 500,
              marginTop: "0",
            }}
          >
            Manage your Payment
          </Typography>
        </Box>
        <FormControl sx={{ marginLeft: "0", width: "200px" }}>
          <InputLabel id="demo-select-small-label">Operator</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={operator}
            label="Operator"
            onChange={handleOperatorChange}
          >
            <MenuItem value="MTN">MTN</MenuItem>
            <MenuItem value="MOOV">MOOV</MenuItem>
            <MenuItem value="MOOVTG">MOOVTG</MenuItem>
            <MenuItem value="TOGOCEL">TOGOCEL</MenuItem>
            <MenuItem value="HUBCI">HUBCI</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Typography
        sx={{
          fontSize: "16px",
          color: "#45464E",
          fontWeight: 700,
          marginTop: "0",
          textAlign: "left",
        }}
      >
        {value === 0
          ? "Today"
          : value === 1
          ? "This Week"
          : value === 2
          ? "This Month"
          : value === 3
          ? "This Quarter"
          : value === 4
          ? "All Time"
          : "Custom"}
      </Typography>
      {/* Tabs Head*/}
      <Box sx={{ borderBottom: 0, marginBottom: "15px", marginTop: "15px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            borderBottom: "none", // Remove the default bottom border
          }}
        >
          <Tab
            label="Today"
            className={value === 0 ? "activeTab" : ""}
            sx={{
              border: value === 0 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 0 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(0)}
          />
          <Tab
            label="This Week"
            className={value === 1 ? "activeTab" : ""}
            sx={{
              border: value === 1 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 1 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(1)}
          />
          <Tab
            label="This Month"
            className={value === 2 ? "activeTab" : ""}
            sx={{
              border: value === 2 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 2 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(2)}
          />
          <Tab
            label="This Quarter"
            className={value === 3 ? "activeTab" : ""}
            sx={{
              border: value === 3 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 3 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(3)}
          />
          <Tab
            label="All Time"
            className={value === 4 ? "activeTab" : ""}
            sx={{
              border: value === 4 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 4 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(4)}
          />
          <Tab
            label="Custom Date"
            className={value === 5 ? "activeTab" : ""}
            sx={{
              border: value === 5 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 5 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(5)}
          />
        </Tabs>
      </Box>

      {/* Tabs Body */}
      <Box> 
        <Box sx={{ marginBottom: "30px" }}>
          {value !== 5 && (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr",
                gap: "30px",
              }}
            >
              <Box
                sx={{
                  background: "#FFFFFF",
                  padding: "30px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                }}
              >
                <Typography
                  sx={{ fontSize: "14px", color: "#797D8C", textAlign: "left" }}
                >
                  Payments by transaction type:
                </Typography>

                {PaymentAnalysticsLoading ? (
                  <Box>
                    {" "}
                    <CircularProgress />{" "}
                  </Box>
                ) : analyticsData === undefined ? (
                  <Typography>No Data</Typography>
                ) : ( 
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                      textAlign: "left",
                    }}
                  >
                    <thead>
                      <tr   style={{
                            display: "grid",
                            gridTemplateColumns: "0.5fr 1fr 1fr",
                            gap: "30px",
                          }}>
                        <th style={{}}>
                          <Typography
                            sx={{
                              // color: "#797D8C",
                              // border: "1px solid #1FAF38",
                              // background: "rgba(31, 175, 56, 0.1)",
                              height: "20px",
                              borderRadius: "8px",
                              fontSize: "14px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          ></Typography>
                          <tr
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1.5fr 0.5fr",
                              gap: "10px",
                              marginTop: "10px",
                            }}
                          >
                            <th style={{ fontSize: "14px", color: "#797D8C" }}></th>
                            <th style={{ fontSize: "14px", color: "#797D8C" }}></th>
                            <th style={{ fontSize: "14px", color: "#797D8C" }}></th>
                          </tr>
                        </th>
                        <th style={{}}>
                          <Typography
                            sx={{
                              color: "#797D8C",
                              border: "1px solid #1FAF38",
                              background: "rgba(31, 175, 56, 0.1)",
                              height: "20px",
                              borderRadius: "8px",
                              fontSize: "14px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Succesful
                          </Typography>
                          <tr
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1.5fr 0.5fr",
                              gap: "10px",
                              marginTop: "10px",
                              marginBottom: '10px'
                            }}
                          >
                            <th style={{ fontSize: "14px", color: "#797D8C" }}>
                              Count
                            </th>
                            <th style={{ fontSize: "14px", color: "#797D8C" }}>
                              Value
                            </th>
                            <th style={{ fontSize: "14px", color: "#797D8C" }}>%</th>
                          </tr>
                        </th>
                        <th style={{}}>
                          <Typography
                            sx={{
                              color: "#797D8C",
                              border: "1px solid #FF5403",
                              background: "rgba(255, 84, 3, 0.1)",
                              height: "20px",
                              borderRadius: "8px",
                              fontSize: "14px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Failed
                          </Typography>
                          <tr
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1.5fr 0.5fr",
                              gap: "10px",
                              marginTop: "10px",
                              marginBottom: '10px'
                            }}
                          >
                            <th style={{ fontSize: "14px", color: "#797D8C" }}>
                              Count
                            </th>
                            <th style={{ fontSize: "14px", color: "#797D8C" }}>
                              Value
                            </th>
                            <th style={{ fontSize: "14px", color: "#797D8C" }}>%</th>
                          </tr>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {titles.map((title) => (
                        <tr
                          style={{
                            display: "grid",
                            gridTemplateColumns: "0.5fr 1fr 1fr",
                            gap: "30px",
                          }}
                          key={title}
                        >
                          <td style={{ fontSize: "14px", color: "#797D8C" }}>
                            {title}
                          </td>
                          <td style={{}}>
                            <tr
                              style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1.5fr 0.5fr",
                                gap: "10px", 
                              }}
                            >
                              <td style={{ fontSize: "14px", color: "#797D8C", marginBottom: '10px' }}>
                                {successful[title] ? formatNumber(successful[title].count) : 0}
                              </td>
                              <td style={{ fontSize: "14px", color: "#797D8C", marginBottom: '10px' }}>
                                {successful[title] ? `${formatNumber(successful[title].value)}.00` : 0}
                              </td>
                              <td style={{ fontSize: "14px", color: "#797D8C", marginBottom: '10px' }}>
                                {successful[title] ? formatNumber(successful[title].percentage) : 0}%
                              </td>
                            </tr>
                          </td>
                          <td style={{}}>
                            <tr
                              style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1.5fr 0.5fr",
                                gap: "10px",
                              }}
                            >
                              <td style={{ fontSize: "14px", color: "#797D8C", marginBottom: '10px' }}>
                                {failed[title] ? formatNumber(failed[title].count) : 0}
                              </td>
                              <td style={{ fontSize: "14px", color: "#797D8C", marginBottom: '10px' }}>
                                {failed[title] ? `${formatNumber(failed[title].value)}.00` : 0}
                              </td>
                              <td style={{ fontSize: "14px", color: "#797D8C", marginBottom: '10px' }}>
                                {failed[title] ? formatNumber(failed[title].percentage) : 0}%
                              </td>
                            </tr>
                          </td>
                         
                        </tr>
                        
                      ))}
                    </tbody>
                  </table> 
                )}
              </Box>
              {/* CHARTS */}
              {/* <Box
                sx={{
                  background: "#FFFFFF",
                  padding: "14px 25px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                }}
              >
                <Typography
                  sx={{ fontSize: "14px", color: "#797D8C", textAlign: "left" }}
                >
                  Statistic:
                </Typography>
                <Tabs
                  // value={value}
                  // onChange={handleChange}
                  aria-label="basic tabs example"
                  sx={{
                    borderBottom: "none", // Remove the default bottom border
                  }}
                >
                  <Tab
                    label="T"
                    className={value === 0 ? "activeTab" : ""}
                    sx={{
                      border:
                        value === 0
                          ? "#F59E0B 1px solid"
                          : "#EFF1F6  1px solid",
                      borderRadius: "8px",
                      fontSize: "14px",
                      lineHeight: "16px",
                      textTransform: "capitalize",
                      marginRight: "12px",
                      backgroundColor: value === 0 ? "#FCDFAE" : "inherit",
                    }}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label="Y"
                    className={value === 1 ? "activeTab" : ""}
                    sx={{
                      border:
                        value === 1
                          ? "#F59E0B 1px solid"
                          : "#EFF1F6  1px solid",
                      borderRadius: "8px",
                      fontSize: "14px",
                      lineHeight: "16px",
                      textTransform: "capitalize",
                      marginRight: "12px",
                      backgroundColor: value === 1 ? "#FCDFAE" : "inherit",
                    }}
                    {...a11yProps(1)}
                  />
                  <Tab
                    label="TW"
                    className={value === 2 ? "activeTab" : ""}
                    sx={{
                      border:
                        value === 2
                          ? "#F59E0B 1px solid"
                          : "#EFF1F6  1px solid",
                      borderRadius: "8px",
                      fontSize: "14px",
                      lineHeight: "16px",
                      textTransform: "capitalize",
                      marginRight: "12px",
                      backgroundColor: value === 2 ? "#FCDFAE" : "inherit",
                    }}
                    {...a11yProps(2)}
                  />
                  <Tab
                    label="TM"
                    className={value === 2 ? "activeTab" : ""}
                    sx={{
                      border:
                        value === 2
                          ? "#F59E0B 1px solid"
                          : "#EFF1F6  1px solid",
                      borderRadius: "8px",
                      fontSize: "14px",
                      lineHeight: "16px",
                      textTransform: "capitalize",
                      marginRight: "12px",
                      backgroundColor: value === 2 ? "#FCDFAE" : "inherit",
                    }}
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box> */}
              <Box></Box>
            </Box>
          )}
          <TabPanel value={value} index={5}>
            {/* CUSTOM DATE */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gridGap: "1rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  backgroundColor: "#FFFFFF",
                  border: "solid 1px #E0E0E0",
                  borderRadius: "8px",
                  padding: "14px 25px",
                  marginBottom: "20px",
                }}
              >
                <Typography
                  sx={{
                    color: "#797D8C",
                    fontSize: "12px",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  Choose Custom Date:
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    // justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          width: "100%",
                        }}
                      >
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={handleStartDateChange}
                            sx={{ width: "100%" }}
                          />
                        </DemoContainer>
                        <Box sx={{ width: "15px", border: "#000 solid 1px" }} />
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={handleEndDateChange}
                            sx={{ width: "100%" }}
                          />
                        </DemoContainer>
                      </Box>
                    </Box>
                  </LocalizationProvider>
                  <Button
                    sx={{
                      height: "55px",
                      backgroundColor: "#F59E0B",
                      "&:hover": {
                        backgroundColor: "#E18D00", // Change this to your desired hover color
                      },
                    }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleCustomDate}
                  >
                    Search
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr",
                gap: "30px",
              }}
            >
              <Box
                sx={{
                  background: "#FFFFFF",
                  padding: "30px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                }}
              >
                <Typography
                  sx={{ fontSize: "14px", color: "#797D8C", textAlign: "left" }}
                >
                  Payments by transaction type:
                </Typography>

                {PaymentAnalysticsLoading ? (
                  <Box>
                    {" "}
                    <CircularProgress />{" "}
                  </Box>
                ) : analyticsData === undefined ? (
                  <Typography>No Data</Typography>
                ) : (
                  <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    textAlign: "left",
                  }}
                >
                  <thead>
                    <tr   style={{
                          display: "grid",
                          gridTemplateColumns: "0.5fr 1fr 1fr",
                          gap: "30px",
                        }}>
                      <th style={{}}>
                        <Typography
                          sx={{
                            // color: "#797D8C",
                            // border: "1px solid #1FAF38",
                            // background: "rgba(31, 175, 56, 0.1)",
                            height: "20px",
                            borderRadius: "8px",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        ></Typography>
                        <tr
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1.5fr 0.5fr",
                            gap: "10px",
                            marginTop: "10px",
                          }}
                        >
                          <th style={{ fontSize: "14px", color: "#797D8C" }}></th>
                          <th style={{ fontSize: "14px", color: "#797D8C" }}></th>
                          <th style={{ fontSize: "14px", color: "#797D8C" }}></th>
                        </tr>
                      </th>
                      <th style={{}}>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            border: "1px solid #1FAF38",
                            background: "rgba(31, 175, 56, 0.1)",
                            height: "20px",
                            borderRadius: "8px",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Succesful
                        </Typography>
                        <tr
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1.5fr 0.5fr",
                            gap: "10px",
                            marginTop: "10px",
                            marginBottom: '10px'
                          }}
                        >
                          <th style={{ fontSize: "14px", color: "#797D8C" }}>
                            Count
                          </th>
                          <th style={{ fontSize: "14px", color: "#797D8C" }}>
                            Value
                          </th>
                          <th style={{ fontSize: "14px", color: "#797D8C" }}>%</th>
                        </tr>
                      </th>
                      <th style={{}}>
                        <Typography
                          sx={{
                            color: "#797D8C",
                            border: "1px solid #FF5403",
                            background: "rgba(255, 84, 3, 0.1)",
                            height: "20px",
                            borderRadius: "8px",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Failed
                        </Typography>
                        <tr
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1.5fr 0.5fr",
                            gap: "10px",
                            marginTop: "10px",
                            marginBottom: '10px'
                          }}
                        >
                          <th style={{ fontSize: "14px", color: "#797D8C" }}>
                            Count
                          </th>
                          <th style={{ fontSize: "14px", color: "#797D8C" }}>
                            Value
                          </th>
                          <th style={{ fontSize: "14px", color: "#797D8C" }}>%</th>
                        </tr>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {titles.map((title) => (
                      <tr
                        style={{
                          display: "grid",
                          gridTemplateColumns: "0.5fr 1fr 1fr",
                          gap: "30px",
                        }}
                        key={title}
                      >
                        <td style={{ fontSize: "14px", color: "#797D8C" }}>
                          {title}
                        </td>
                        <td style={{}}>
                          <tr
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1.5fr 0.5fr",
                              gap: "10px", 
                            }}
                          >
                            <td style={{ fontSize: "14px", color: "#797D8C", marginBottom: '10px' }}>
                              {successful[title] ? formatNumber(successful[title].count) : 0}
                            </td>
                            <td style={{ fontSize: "14px", color: "#797D8C", marginBottom: '10px' }}>
                              {successful[title] ? `${formatNumber(successful[title].value)}.00` : 0}
                            </td>
                            <td style={{ fontSize: "14px", color: "#797D8C", marginBottom: '10px' }}>
                              {successful[title] ? formatNumber(successful[title].percentage) : 0}%
                            </td>
                          </tr>
                        </td>
                        <td style={{}}>
                          <tr
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1.5fr 0.5fr",
                              gap: "10px",
                            }}
                          >
                            <td style={{ fontSize: "14px", color: "#797D8C", marginBottom: '10px' }}>
                              {failed[title] ? formatNumber(failed[title].count) : 0}
                            </td>
                            <td style={{ fontSize: "14px", color: "#797D8C", marginBottom: '10px' }}>
                              {failed[title] ? `${formatNumber(failed[title].value)}.00` : 0}
                            </td>
                            <td style={{ fontSize: "14px", color: "#797D8C", marginBottom: '10px' }}>
                              {failed[title] ? formatNumber(failed[title].percentage) : 0}%
                            </td>
                          </tr>
                        </td>
                       
                      </tr>
                      
                    ))}
                  </tbody>
                </table> 
                )}
              </Box>
              <Box></Box>
            </Box>
          </TabPanel>
        </Box>
      </Box>
      {/* Top 10 Merchants Table */}
      <Box>
        <TopMerchants
          data={PaymentAnalysticsTopMerchants}
          tableTitle={`${operator} Top Merchants`}
        />
      </Box>
    </Box>
  );
};

export default FinanceOverview;

import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Security from './Security';

const style = {
    settingsContainer:{
        display: 'grid',
        gridTemplateColumns:'20% 80%',
        gap: '40px',
        width: '100%',
    }
}



interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  
  
const Index = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };
    return (
        <Box sx={{ width: "100%" }}>
        {/* Heading */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            marginBottom: "20px",
          }}
        >
          <Typography
            sx={{ fontSize: "24px", color: "#45464E", marginBottom: "-5px" }}
          >
            Settings
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              color: "#B2B2B2",
              fontWeight: 500,
              marginTop: "0",
            }}
          >
            Manage your settings here
          </Typography>
        </Box>
        <Box
          sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100%' }}
        ><Box sx={style.settingsContainer}>
            <Box sx={{width: '100%'}}>
          <Tabs
            orientation="vertical"
            // variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab label="Profile" {...a11yProps(0)} />
            <Tab label="Security" {...a11yProps(1)} />
          </Tabs></Box><Box>
          <TabPanel value={value} index={0}>
            Coming Soon
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Security />
          </TabPanel></Box></Box>
        </Box></Box>
      );
    }
    

export default Index


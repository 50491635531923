import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Endpoints } from "../../../api/EndPoints";
import axios from "axios";
import { toast } from "react-toastify";

const initialState: any = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null,
  data: [],
  cacheStatus: 'idle',
};

export const GetDashboardOverview = createAsyncThunk(
  "dashboardoverview",
  async (thunkAPI) => {
    try {
      const response = await axios.get(
        `${Endpoints.Overview.dashboardOverview}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success(response.data.responseMessage);
      return response.data.datas;
    } catch (error: any) {
        toast.error(error.response.data.responseMessage);
        if (error.response && error.response.data.responseMessage) {
          return (error.response.data.responseMessage);
        } else {
          return (error.responseMessage);
        }
    }
  }
);

export const dashboardOverviewSlice = createSlice({
  name: "dashboardoverview",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetDashboardOverview.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        GetDashboardOverview.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = null;
          state.data = action.payload;
          state.cacheStatus = 'succeeded';
        }
      )
      .addCase(
        GetDashboardOverview.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
          state.data = [];
          state.cacheStatus = 'failed';
        }
      );
  },
});

export default dashboardOverviewSlice.reducer;

import React from "react";
import { Switch, Route } from "react-router-dom";
import SignIn from "../pages/publicPages/SignIn.tsx";
import Verify2Fa from "../pages/publicPages/Verify2Fa.tsx";
import { PrivateRoute } from "./private.routes";
import Dashboard from "../pages/private/Dashboard.tsx";
import RegisteredCustomers from "../pages/private/RegisteredCustomers.tsx";
import DocumentList from "../pages/private/DocumentList.tsx";
import Merchant from "../pages/private/Merchant.tsx";
import MainLayout from "../layout/MainLayout";
import ComingSoon from "../components/constants/ComingSoon.tsx";
import Compliance from "../pages/private/Compliance.tsx";
import Summary from "../components/pages/compliance/summary/Summary.tsx";
import MerchantTableSummary from "../components/pages/compliance/summary/MerchantTableSummary.tsx";
// import FinanceOverview from "../pages/private/finance/FinanceOverview.tsx";
import Topup from "../pages/private/finance/Topup";
import Payout from "../pages/private/finance/Payout";
import MerchantPayment from "../pages/private/finance/MerchantPayment";
import Revenue from "../components/pages/finance/revenue/Revenue";
import FinanceOverview from "../components/pages/finance/overview/FinanceOverview";
import ManageRate from "../pages/private/finance/ManageRate";
import FinanceReports from "../pages/private/finance/FinanceReports";
import Settings from "../pages/private/settings/Settings";
import TransactionLog from "../pages/private/transaction/TransactionLog"; 
import { ROUTES } from "../components/constants/RouteData.ts";
import MerchantOverview from "../pages/private/merchant/MerchantOverview";

const IndexRoutes = () => {
  return (
    <>
      <Switch>
        <Route exact={true} path="/" render={() => <SignIn />} />
        <Route
          exact={true}
          path={ROUTES.VERIFY2FA}
          render={() => <Verify2Fa />}
        />
        <PrivateRoute exact={true} path={ROUTES.DASHBOARD}>
          <MainLayout>
            <Dashboard />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/registered-customers">
          <MainLayout>
            <RegisteredCustomers />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/document-list">
          <MainLayout>
            <DocumentList />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact={true} path={ROUTES.MERCHANT_OVERVIEW}>
          <MainLayout>
            <MerchantOverview />
          </MainLayout>
        </PrivateRoute>
       <PrivateRoute exact={true} path={ROUTES.MERCHANT_LIST}>
          <MainLayout>
            <Merchant />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact={true} path={ROUTES.ACTIVATION_REQUEST}>
          <MainLayout>
            <Compliance />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact={true} path={ROUTES.COMING_SOON}>
          <MainLayout>
            <ComingSoon />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact={true} path={`${ROUTES.SUMMARY}/:id`}>
          <MainLayout>
            <Summary />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact={true} path={`${ROUTES.MERCHANT_DETAILS}/:id`}>
          <MainLayout>
            <MerchantTableSummary />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact={true} path={ROUTES.FINANCE_OVERVIEW}>
          <MainLayout>
            <FinanceOverview />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact={true} path={ROUTES.TOPUP}>
          <MainLayout>
            <Topup />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact={true} path={ROUTES.PAYOUT}>
          <MainLayout>
            <Payout />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact={true} path={ROUTES.MERCHANT_PAYMET}>
          <MainLayout>
            <MerchantPayment />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact={true} path={ROUTES.REVENUE}>
          <MainLayout>
            <Revenue />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact={true} path={ROUTES.MANAGE_RATES}>
          <MainLayout>
            <ManageRate />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact={true} path={ROUTES.FINANCE_REPORT}>
          <MainLayout>
            <FinanceReports />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact={true} path={ROUTES.SETTINGS}>
          <MainLayout>
            <Settings />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute exact={true} path={ROUTES.TRANSACTION_LOG}>
          <MainLayout>
            <TransactionLog />
          </MainLayout>
        </PrivateRoute>
        {/* <Route exact={true} path="/dashboard" render={() => <Dashboard />} /> */}
      </Switch>
    </>
  );
};

export default IndexRoutes;

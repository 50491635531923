import { useState, useEffect } from "react";
import { Box, Typography, Button, Skeleton } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { CardMedia } from "@mui/material";
import "../../../styles/Dashboard.css";
import Badge from "@mui/material/Badge";
import {
  MerchantIcon,
  ActiveIcon,
  InactiveIcon,
  SuspendedIcon,
} from "../../../common/assets/icons";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import { GetCustomerList } from "../../../state/feautures/customer/getCustomerListSlice";
import { GetDashboardOverview } from "../../../state/feautures/dashboard/getDashboardOverviewSlice";


function createNotificationData(notification: string, view: string) {
  return { notification, view };
}

const notificationRows = [createNotificationData("No Notification", "View")];

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Index = () => {
  const dispatch = useAppDispatch(); 
  const DashboardOverview = useAppSelector((state: any) => state.dashboardOverview);
  const DashboardOverviewData = DashboardOverview.data
  const DashboardOverviewLoading= DashboardOverview.loading 
  const DashboardOverviewCacheStatus= DashboardOverview.cacheStatus 
  const [value, setValue] = React.useState(0);  

  useEffect(() => {  
    if (DashboardOverviewCacheStatus === "idle"){
   dispatch(GetDashboardOverview())}
  }, [dispatch, DashboardOverviewCacheStatus])
  
 
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  if (DashboardOverviewLoading) {
    return (
      <Box>
        <Box>
          <Skeleton
            variant="rectangular"
            sx={{
              height: "40px",
              width: "170px",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
              marginBottom: "0.5rem",
            }}
            animation="wave"
          />
          <Skeleton
            variant="rectangular"
            sx={{
              height: "20px",
              width: "250px",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
              marginBottom: "1rem",
            }}
            animation="wave"
          />
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(4,1fr)",
            gridGap: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Skeleton
            variant="rectangular"
            sx={{
              height: "90px",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
            }}
            animation="wave"
          />
          <Skeleton
            variant="rectangular"
            sx={{
              height: "90px",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
            }}
            animation="wave"
          />
          <Skeleton
            variant="rectangular"
            sx={{
              height: "90px",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
            }}
            animation="wave"
          />
          <Skeleton
            variant="rectangular"
            sx={{
              height: "90px",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
            }}
            animation="wave"
          />
        </Box>
        <Skeleton
          variant="rectangular"
          sx={{
            height: "440px",
            backgroundColor: "#FFFFFF",
            border: "solid 1px #E0E0E0",
            borderRadius: "8px",
          }}
          animation="wave"
        />
      </Box>
    );
  } 
  return (
    <>
      <Box sx={{ width: { sm: "100%" } }}>
        {/* Heading  */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            marginBottom: "20px",
          }}
        >
          <Typography
            sx={{ fontSize: "20px", color: "#45464E", marginBottom: "-5px" }}
          >
            Dashboard Overview
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#B2B2B2",
              fontWeight: 500,
              marginTop: "0",
            }}
          >
            Checkout your dashboard summary
          </Typography>
        </Box>
        {/* Tab Heading */}
        <Box sx={{ borderBottom: 0, marginBottom: "10px" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              borderBottom: "none", // Remove the default bottom border
            }}
          >
            {/* <Tab
              label="1 Day"
              className={value === 0 ? "activeTab" : ""}
              sx={{
                border:
                  value === 0 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
                borderRadius: "8px",
                fontSize: "14px",
                lineHeight: "16px",
                textTransform: "capitalize",
                marginRight: "12px",
                backgroundColor: value === 0 ? "#FCDFAE" : "inherit",
              }}
              {...a11yProps(0)}
            />
            <Tab
              label="3 Days"
              className={value === 1 ? "activeTab" : ""}
              sx={{
                border:
                  value === 1 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
                borderRadius: "8px",
                fontSize: "14px",
                lineHeight: "16px",
                textTransform: "capitalize",
                marginRight: "12px",
                backgroundColor: value === 1 ? "#FCDFAE" : "inherit",
              }}
              {...a11yProps(1)}
            />
            <Tab
              label="7 Days"
              className={value === 2 ? "activeTab" : ""}
              sx={{
                border:
                  value === 2 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
                borderRadius: "8px",
                fontSize: "14px",
                lineHeight: "16px",
                textTransform: "capitalize",
                marginRight: "12px",
                backgroundColor: value === 2 ? "#FCDFAE" : "inherit",
              }}
              {...a11yProps(2)}
            />
            <Tab
              label="30 Days"
              className={value === 3 ? "activeTab" : ""}
              sx={{
                border:
                  value === 3 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
                borderRadius: "8px",
                fontSize: "14px",
                lineHeight: "16px",
                textTransform: "capitalize",
                marginRight: "12px",
                backgroundColor: value === 3 ? "#FCDFAE" : "inherit",
              }}
              {...a11yProps(3)}
            /> */}
            <Tab
              label="All Time"
              className={value === 4 ? "activeTab" : ""}
              sx={{
                border:
                  value === 4 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
                borderRadius: "8px",
                fontSize: "14px",
                lineHeight: "16px",
                textTransform: "capitalize",
                marginRight: "12px",
                backgroundColor: value === 4 ? "#FCDFAE" : "inherit",
              }}
              {...a11yProps(4)}
            />
          </Tabs>
        </Box>
        <Box>
          <Box
            sx={{
              display: { sm: "flex", md: "grid" },
              flexDirection: { sm: "column" },
              gridTemplateColumns: { sm: "1fr", md: "3fr 1fr" },
              gridGap: "1rem",
            }}
          >
            <Box sx={{}}>
              {/* <TabPanel value={value} index={0}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: { sm: "1fr", md: "repeat(3, 1fr)" },
                    gridGap: "1rem",
                    margin: "0",
                  }}
                >
                  <Box
                    sx={{
                      height: "90px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Merchants
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={MerchantIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
                      N/A
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "90px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Registered Business
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={MerchantIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
                      N/A
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "90px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Individual Business
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={MerchantIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
                      N/A
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "90px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Active Accounts
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={ActiveIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
                      N/A
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "90px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Inactive Accounts
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={InactiveIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
                      N/A
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "90px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Suspended Accounts
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={SuspendedIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
                      N/A
                    </Typography>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: { sm: "1fr", md: "repeat(3, 1fr)" },
                    gridGap: "1rem",
                    margin: "0",
                  }}
                >
                  <Box
                    sx={{
                      height: "90px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Merchants
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={MerchantIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
                      N/A
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "90px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Registered Business
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={MerchantIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
                      N/A
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "90px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Individual Business
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={MerchantIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
                      N/A
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "90px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Active Accounts
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={ActiveIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
                      N/A
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "90px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Inactive Accounts
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={InactiveIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
                      N/A
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "90px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Suspended Accounts
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={SuspendedIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
                      N/A
                    </Typography>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: { sm: "1fr", md: "repeat(3, 1fr)" },
                    gridGap: "1rem",
                    margin: "0px",
                  }}
                >
                  <Box
                    sx={{
                      height: "90px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Merchants
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={MerchantIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
                      N/A
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "90px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Registered Business
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={MerchantIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
                      N/A
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "90px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Individual Business
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={MerchantIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
                      N/A
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "90px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Active Accounts
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={ActiveIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
                      N/A
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "90px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Inactive Accounts
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={InactiveIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
                      N/A
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "90px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Suspended Accounts
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={SuspendedIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
                      N/A
                    </Typography>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: { sm: "1fr", md: "repeat(3, 1fr)" },
                    gridGap: "1rem",
                    margin: "0px",
                  }}
                >
                  <Box
                    sx={{
                      height: "90px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Merchants
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={MerchantIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
                      N/A
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "90px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Registered Business
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={MerchantIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
                      N/A
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "90px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Individual Business
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={MerchantIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
                      N/A
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "90px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Active Accounts
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={ActiveIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
                      N/A
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "90px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Inactive Accounts
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={InactiveIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
                      N/A
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      height: "90px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Suspended Accounts
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={SuspendedIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
                      N/A
                    </Typography>
                  </Box>
                </Box>
              </TabPanel> */}
              <TabPanel value={value} index={0}>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: { xs: "1fr",sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)" },
                    gridGap: "1rem",
                    margin: "0px",
                  }}
                >
                  <Box
                    sx={{
                      height: "140px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: '10px'
                    }}
                  ><Box sx={{     display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: '100%'}}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        All Accounts
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={MerchantIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                      {DashboardOverviewData.totalUsers}
                    </Typography></Box>
                    <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        (V2)
                      </Typography> 
                    </Box>
                    <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                      {DashboardOverviewData.totalAccountsV2}
                    </Typography></Box>
                  </Box>
                  <Box
                    sx={{
                      height: "140px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: '10px'
                    }}
                  ><Box sx={{     display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: '100%'}}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Registered Business
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={MerchantIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                    {DashboardOverviewData.totalRegisteredBusinesses}
                    </Typography></Box>
                    <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        {/* (V2) */}
                      </Typography> 
                    </Box>
                    <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                    {DashboardOverviewData?.totalRegisteredBusinessesV2}
                    </Typography></Box>
                  </Box> 
                  <Box
                    sx={{
                      height: "140px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: '10px'
                    }}
                  ><Box sx={{     display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: '100%'}}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Individual Business
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={MerchantIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                    {DashboardOverviewData.totalIndividualBusinesses}
                    </Typography></Box>
                    <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        {/* (V2) */}
                      </Typography> 
                    </Box>
                    <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                    {DashboardOverviewData?.totalIndividualBusinessesV2}
                    </Typography></Box>
                  </Box>  
                  <Box
                    sx={{
                      height: "140px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: '10px'
                    }}
                  ><Box sx={{     display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: '100%'}}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Active Accounts
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={ActiveIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                    {DashboardOverviewData.totalActiveUsers} 
                    </Typography></Box>
                    <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        (V2)
                      </Typography> 
                    </Box>
                    <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                    {DashboardOverviewData.totalActiveAccountsV2} 
                    </Typography></Box>
                  </Box> 
                  <Box
                    sx={{
                      height: "140px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: '10px'
                    }}
                  ><Box sx={{     display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: '100%'}}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Inactive Accounts
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={InactiveIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                    {DashboardOverviewData.totalInactiveUsers} 
                    </Typography></Box>
                    <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        (V2)
                      </Typography> 
                    </Box>
                    <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                    {DashboardOverviewData.totalInactiveAccountsV2}
                    </Typography></Box>
                  </Box>   
                  <Box
                    sx={{
                      height: "140px",
                      backgroundColor: "#FFFFFF",
                      border: "solid 1px #E0E0E0",
                      borderRadius: "8px",
                      padding: "14px 25px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: '10px'
                    }}
                  ><Box sx={{     display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: '100%'}}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                         Suspended Accounts
                      </Typography>
                      <CardMedia
                        component="img"
                        height="20px"
                        image={SuspendedIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                    {DashboardOverviewData.totalSuspendedUsers} 
                    </Typography></Box>
                    <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#797D8C",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        (V2)
                      </Typography> 
                    </Box>
                    <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                    {DashboardOverviewData.totalSuspendedAccountsV2} 
                    </Typography></Box>
                  </Box>  
                </Box> 
                </Box>
              </TabPanel>
              <Box
                sx={{
                  backgroundColor: "#FFFFFF",
                  border: "solid 1px #E0E0E0",
                  borderRadius: "8px",
                  marginTop: "1rem",
                }}
              >
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Badge badgeContent={0} color="error">
                            Notification
                          </Badge>
                        </TableCell>
                        <TableCell align="right">View More</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {notificationRows.map((row) => (
                        <TableRow
                          key={row.notification}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.notification}
                          </TableCell>
                          <TableCell align="right">
                            <Button
                              variant="contained"
                              sx={{
                                color: "white",
                                backgroundColor: "#F59E0B",
                                height: "30px",
                              }}
                            >
                              {row.view}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                border: "solid 1px #E0E0E0",
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gridGap: "1rem",
                  justifyContent: "start",
                  alignItems: "start",
                  textAlign: "left",
                  width: "100%",
                  color: "#58697D",
                }}
              >
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: "16px",
                    fontWeight: 100,
                    borderBottom: "1px solid #CCCCD8",
                    padding: "15px",
                  }}
                >
                  Service Health
                </Typography>
                <Box
                  sx={{
                    padding: "10px",
                    paddingBottom: "20px",
                    display: "grid",
                    gridTemplateColumns: "60% 40%",
                    borderBottom: "1px solid #CCCCD8",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 100,
                    }}
                  >
                    Subscriber App
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "green",
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: "9px",
                      }}
                    >
                      Healthy
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    padding: "10px",
                    paddingBottom: "20px",
                    display: "grid",
                    gridTemplateColumns: "60% 40%",
                    borderBottom: "1px solid #CCCCD8",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 100,
                    }}
                  >
                    USSD Gateway
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#C57B2A",
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: "9px",
                      }}
                    >
                      Some Issues
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    padding: "10px",
                    paddingBottom: "20px",
                    display: "grid",
                    gridTemplateColumns: "60% 40%",
                    borderBottom: "1px solid #CCCCD8",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 100,
                    }}
                  >
                    SMS Gateway
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#F23030",
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: "9px",
                      }}
                    >
                      Not Reachable
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Index;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Endpoints } from "../../../api/EndPoints";
import axios from "axios";
import {  toast } from "react-toastify";
import { TopTenMerchantsInitialState } from "../../../types/storeData";

const initialState: TopTenMerchantsInitialState = {
  loading: false,
  error: null,
  data: [],
};

export const GetTopTenTransaction = createAsyncThunk(
  "toptentransaction",
  async () => {
    try {
      const response = await axios.get(
        Endpoints.Finance.TopTenTransaction,
        {
          headers: {
            Accept: "application/json",
          "Content-Type": "application/json",
           Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );     
       toast.success(response.data.responseMessage);
    return response.data.datas;
    } catch (error: any) {       
       toast.error(error.response.data.responseMessage);
      if (error.response && error.response.data.responseMessage) {
        return (error.response.data.responseMessage);
      } else {
        return (error.responseMessage);
      }
    }
  }
);

export const topTenTransactionSlice = createSlice({
  name: "toptentransaction",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetTopTenTransaction.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetTopTenTransaction.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(GetTopTenTransaction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
        state.data = [];
      })
  },
});

export default topTenTransactionSlice.reducer;

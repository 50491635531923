import React, { useState, useEffect } from "react";
import { Box, Typography, CardMedia, Divider, Button } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ClearIcon from "@mui/icons-material/Clear";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { FilterIcon } from "../../../common/assets/icons";
import { ROUTES } from "../../constants/RouteData";

const ComplianceTable = ({ data }: any) => {
  const [rows, setRow] = useState([]);
  const [count, setCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState<any>("");
  const [clearField, setClearField] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Set the number of items per page according to your requirement
  useEffect(() => {
    const row = data;
    setRow(row);
  }, []);

  // PAGINATION
  const handleChange = (e: any) => {
    const selectedPage = parseInt(e.target.value);
    setCurrentPage(selectedPage);
  };
  const getTotalPages = () => Math.ceil(rows.length / itemsPerPage);

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // REGISTERED BUSINESS SEARCH BAR
  // useEffect(() => {
  //   setRow(data);
  // }, [count]);

  const filterObjects = () => {
    const filtered: any = data.filter((object: any) => {
      return object.firstName.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setRow(filtered);
  };

  useEffect(() => {
    filterObjects();
  }, [count]);

  const handleSearch = () => {
    filterObjects();
    setClearField(true);
  };
  const clearSearchField = () => {
    setSearchQuery("");
    setCount((prevCount) => prevCount + 1);
    setClearField(false);
  };
  // Handling input change in the search bar
  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default form submission behavior
      handleSearch(); // Call the search function when Enter key is pressed
    }
  };

  return (
    <Box sx={{ boxShadow: 1 }}>
      <Box
        sx={{
          padding: "15px",
          display: "flex",
          gap: "1rem",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Typography
          sx={{
            textAlign: "left",
            fontSize: "25px",
            fontWeight: 500,
          }}
        >
          Activation Request List
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: { xs: "100%", md: "400px" },
          }}
        >
          {/* Search Bar */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
              marginLeft: "auto",
              width: "300px",
              // padding: "3px 0px 3px 2px",
              position: "relative",
            }}
          >
            {" "}
            <FormControl fullWidth>
              <OutlinedInput
                id="outlined-adornment-amount"
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyDown={handleKeyPress} // Add this line to handle key press
                startAdornment={
                  <InputAdornment position="start">
                    <SearchRoundedIcon
                      onClick={handleSearch}
                      sx={{
                        cursor: "pointer",
                      }}
                    />
                  </InputAdornment>
                }
                sx={{ height: "40px" }}
              />
            </FormControl>
            {clearField ? (
              <ClearIcon
                onClick={clearSearchField}
                sx={{
                  cursor: "pointer",
                  position: "absolute",
                  left: "248px",
                }}
              />
            ) : (
              ""
            )}
          </Box>
          {/* Search Bar */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "110px",
              height: "40px",
              border: "1px solid black",
              borderRadius: "6px",
              padding: "1px 4px",
              cursor: "pointer",
            }}
          >
            {" "}
            <CardMedia
              component="img"
              height="20px"
              image={FilterIcon}
              alt="Icon"
              sx={{
                objectFit: "contain",
                width: "20px",
              }}
            />
            <Typography>Filter</Typography>
          </Box>
        </Box>
      </Box>
      <TableContainer
        sx={{ borderRadius: "0", border: "none", boxShadow: 0 }}
        component={Paper}
      >
        <Divider />
        <Table sx={{ padding: 0, margin: 0 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#FCDFAE1A", textTransform: "" }}>
              <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>
                Merchant ID
              </TableCell>
              <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>
                Account Information
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Type
              </TableCell>{" "}
              <TableCell
                align="center"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Activation Status
              </TableCell>
              {/* <TableCell align="center" sx={{ fontWeight: 600, fontSize: "14px" }}>
                Timeline
              </TableCell> */}
              <TableCell
                align="center"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Country
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                User Profile
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Phone
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Action
              </TableCell>
            </TableRow>{" "}
          </TableHead>
          <TableBody>
            {rows
              .slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
              )
              .map((data: any) => (
                <TableRow
                  key={data.merchandId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {data.merchandId === null ? "N/A" : data.merchandId}
                  </TableCell>
                  {/* <TableCell>{data.name}{data.email}</TableCell> */}
                  <TableCell
                    align="left"
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Box>
                      {data.businessName === null ? "N/A" : data.businessName}
                    </Box>
                    <Box>{data.email === null ? "N/A" : data.email}</Box>
                    {/* <Box>{data.accountInformation}</Box> */}
                  </TableCell>
                  <TableCell align="center">
                    {data.typeBusiness === "INDIVIDUAL BUSINESS" ? (
                      <Box
                        sx={{
                          backgroundColor: "#F59E0B",
                          padding: "3px 10px",
                          border: "1px solid #F59E0B",
                          borderRadius: "10px",
                          color: "#FFEEE5",
                          textAlign: "center",
                        }}
                      >
                        IND
                      </Box>
                    ) : data.typeBusiness === null ? (
                      <Box
                        sx={{
                          backgroundColor: "lighgrey",
                          padding: "3px 10px",
                          border: "1px solid grey",
                          borderRadius: "10px",
                          color: "grey",
                          textAlign: "center",
                        }}
                      >
                        N/A
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          backgroundColor: "#FFEEE5",
                          padding: "3px 10px",
                          border: "1px solid #F59E0B",
                          borderRadius: "10px",
                          color: "#F59E0B",
                          textAlign: "center",
                        }}
                      >
                        REG
                      </Box>
                    )}
                  </TableCell>{" "}
                  <TableCell align="left">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "left",
                        width: "100px",
                      }}
                    >
                      {data.activationStatus === "INACTIVE" ? (
                        <Box
                          sx={{
                            fontSize: "11px",
                            borderRadius: "10px",
                            padding: "2px 10px",
                            backgroundColor: "#F2F0F9",
                            display: "flex",
                            alignItems: "center",
                            // justifyContent: "space-between",
                            width: "100px",
                            marginBottom: "2px",
                            color: "#6E6893",
                          }}
                        >
                          <Box
                            sx={{
                              height: "7px",
                              width: "7px",
                              borderRadius: "50%",
                              backgroundColor: "#6E6893",
                              marginRight: "5px",
                            }}
                          ></Box>
                          {data.activationStatus}
                        </Box>
                      ) : data.activationStatus === null ? (
                        <Box
                          sx={{
                            fontSize: "11px",
                            borderRadius: "10px",
                            padding: "2px 10px",
                            backgroundColor: "lightgray",
                            display: "flex",
                            alignItems: "center",
                            // justifyContent: "center",
                            width: "100px",
                            marginBottom: "2px",
                            color: "grey",
                          }}
                        >
                          <Box
                            sx={{
                              height: "8px",
                              width: "8px",
                              borderRadius: "50%",
                              backgroundColor: "grey",
                              marginRight: "5px",
                            }}
                          ></Box>
                          N/A
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            fontSize: "11px",
                            borderRadius: "10px",
                            padding: "2px 10px",
                            backgroundColor: "#D8FFD8",
                            display: "flex",
                            alignItems: "center",
                            // justifyContent: "center",
                            width: "100px",
                            marginBottom: "2px",
                            color: "#07751A",
                          }}
                        >
                          <Box
                            sx={{
                              height: "8px",
                              width: "8px",
                              borderRadius: "50%",
                              backgroundColor: "#07751A",
                              marginRight: "5px",
                            }}
                          ></Box>
                          {data.activationStatus}
                        </Box>
                      )}
                      <Box sx={{ fontSize: "12px" }}>
                        <b>Date:</b>{" "}
                        {data.accountDate === null
                          ? "N/A"
                          : data.accountDate.split("T")[0]}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {data.country === null ? "N/A" : data.country}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Box>
                      {data.userProfile === null ? (
                        "N/A"
                      ) : (
                        <b>
                          {data.firstName} {data.lastName}
                        </b>
                      )}
                    </Box>
                    <Box>{data.userProfile === null ? "N/A" : data.email}</Box>
                    {/* <Box>{data.userProfile}</Box> */}
                  </TableCell>
                  <TableCell align="center">
                    {data.phoneNo === null ? "N/A" : `+${data.phoneNo}`}
                  </TableCell>
                  <TableCell align="center">
                    <Link to={`${ROUTES.SUMMARY}/${data.merchandId}`}>
                      <MoreVertOutlinedIcon
                        //   onClick={handleOpen}
                        sx={{ cursor: "pointer" }}
                      />
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          gap: "20px",
          fontSize: "13px",
          backgroundColor: "#FCDFAE1A",
          color: "#6E6893",
          padding: "10px 0",
          borderRadius: " 0 0 10px 10px",
        }}
      >
        <Typography sx={{ fontWeight: 600, fontSize: "13px" }}>
          Rows per page: {itemsPerPage}
        </Typography>
        <Box>
          <select
            id="pageDropdown"
            onChange={handleChange}
            value={currentPage}
            style={{ border: "none", width: "17px" }}
          >
            {Array.from({ length: getTotalPages() }).map(
              (_: any, index): any => (
                <option key={index} value={index + 1}>
                  {index + 1}
                </option>
              )
            )}
          </select>
        </Box>
        <label htmlFor="pageDropdown">
          {currentPage} of {getTotalPages()}
        </label>
        <Button disabled={currentPage === 1} onClick={goToPreviousPage}>
          <NavigateBeforeIcon />
        </Button>
        <Button
          disabled={currentPage === getTotalPages()}
          onClick={goToNextPage}
        >
          <NavigateNextIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default ComplianceTable;

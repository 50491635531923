import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Modal,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import axios from "axios";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// STYLE MODAL
const styleModal = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
  borderRadius: 2,
};
// STYLED TABLE
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const BusinessCustomers: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [registeredBusinessData, setRegisteredBusinessData] = useState<any>([]);
  const [noData, setNoData] = useState<any>(false);
  const [count, setCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [clearField, setClearField] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalID, setModalID] = useState("");
  const [modalIndex, setModalIndex] = useState("");
  const [modalCompanyName, setModalCompanyName] = useState("");
  const [modalActivityDescription, setModalActivityDescription] = useState("");
  const [modalBankAccount, setModalBankAccount] = useState("");
  const [modalBankName, setModalBankName] = useState("");
  const [modalBusinessDescription, setModalBusinessDescription] = useState("");
  const [modalBusRegDocUrl, setModalBusRegDocUrl] = useState("");
  const [modalBusinessEmail, setModalBusinessEmail] = useState("");
  const [modalDOB, setModalDOB] = useState("");
  const [modalCountry, setModalCountry] = useState("");
  const [modalDocIdUrl, setDocIdUrl] = useState("");
  const [modalDocUrl, setModalDocUrl] = useState("");
  const [modalUserEmail, setModalUserEmail] = useState("");
  const [modalFirstName, setModalFirstName] = useState("");
  const [modalIdGovUrl, setModalIdGovUrl] = useState("");
  const [modalIndividualId, setModalIndividualId] = useState("");
  const [modalIndustry, setModalIndustry] = useState("");
  const [modalLastName, setModalLastName] = useState("");
  const [modalLegalBusinessName, setModalLegalBusinessName] = useState("");
  const [modalLegalName, setModalLegalName] = useState("");
  const [modalNumberMoov, setModalNumberMoov] = useState("");
  const [modalNumberMtn, setModalNumberMtn] = useState("");
  const [modalOfficeAddress, setModalOfficeAddress] = useState("");
  const [modalPayAccountList, setModalPayAccountList] = useState("");
  const [modalPhoneNumber, setModalPhoneNumber] = useState("");
  const [modalProofAddUrl, setModalProofAddUrl] = useState("");
  const [modalRegNumber, setModalRegNumber] = useState("");
  const [modalRole, setModalRole] = useState("");
  const [modalSmaFacebook, setModalSmaFacebook] = useState("");
  const [modalSmaInstagram, setModalSmaInstagram] = useState("");
  const [modalSmaTwitter, setModalSmaTwitter] = useState("");
  const [modalStaffSize, setModalStaffSize] = useState("");
  const [modalTaxNumber, setModalTaxNumber] = useState("");
  const [modalTaxRegDocUrl, setModalTaxRegDocUrl] = useState("");
  const [modalTradingName, setModalTradingName] = useState("");
  const [modalWebsite, setModalWebsite] = useState("");
  const [modalCategory, setModalCategory] = useState("");
  const [modalNationality, setModalNationality] = useState("");
  const [modalState, setModalState] = useState("");
  const [activeState, setActiveState] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [activationMessage, setActivationMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Set the number of items per page according to your requirement

  const userToken = localStorage.getItem("token");
  const token = userToken;
  // REGISTERED BUSINESS
  const handleOpen = (
    userId: any,
    companyName: any,
    tradingName: any,
    staffSize: any,
    industry: any,
    category: any,
    businessDescription: any,
    regNumber: any,
    taxNumber: any,
    busRegDocUrl: any,
    taxRegDocUrl: any,
    businessEmail: any,
    phoneNumber: any,
    officeAddress: any,
    proofAddUrl: any,
    website: any,
    smaFacebook: any,
    smaTwitter: any,
    smaInstagram: any,
    legalName: any,
    firstName: any,
    lastName: any,
    DOB: any,
    nationality: any,
    role: any,
    idGovUrl: any,
    DocIdUrl: any,
    bankName: any,
    bankAccount: any,
    numberMtn: any,
    numberMoov: any,
    state: any,
    activityDescription: any,
    country: any,
    DocUrl: any,
    userEmail: any,
    individualId: any,
    legalBusinessName: any,
    payAccountList: any,
    index: any
  ) => {
    setOpen(true);
    setModalID(userId);
    setModalCompanyName(companyName);
    setModalTradingName(tradingName);
    setModalStaffSize(staffSize);
    setModalIndustry(industry);
    setModalCategory(category);
    setModalBusinessDescription(businessDescription);
    setModalRegNumber(regNumber);
    setModalTaxNumber(taxNumber);
    setModalBusRegDocUrl(busRegDocUrl);
    setModalTaxRegDocUrl(taxRegDocUrl);
    setModalBusinessEmail(businessEmail);
    setModalPhoneNumber(phoneNumber);
    setModalOfficeAddress(officeAddress);
    setModalProofAddUrl(proofAddUrl);
    setModalWebsite(website);
    setModalSmaFacebook(smaFacebook);
    setModalSmaInstagram(smaInstagram);
    setModalSmaTwitter(smaTwitter);
    setModalLegalName(legalName);
    setModalFirstName(firstName);
    setModalLastName(lastName);
    setModalDOB(DOB);
    setModalNationality(nationality);
    setModalRole(role);
    setModalIdGovUrl(idGovUrl);
    setModalDocUrl(DocIdUrl);
    setModalBankName(bankName);
    setModalBankAccount(bankAccount);
    setModalNumberMtn(numberMtn);
    setModalNumberMoov(numberMoov);
    setModalState(state);
    // setModalIndex(index);
  };
  const handleClose = () => setOpen(false);

  const handleUnhide = () => setActiveState(true);
  const handleHide = () => setActiveState(false);
  // REGISTERED BUSINESS API CALL
  const fetchRegisteredBusinessData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "http://74.208.84.251:9016/api/v1/customer/registredCustomers",
      );
      setRegisteredBusinessData(response.data.datas);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setNoData(true);
      setLoading(false);
    }
    setLoading(false);
  };
  // REGISTERED BUSINESS SEARCH BAR
  useEffect(() => {
    fetchRegisteredBusinessData();
  }, [count]);
  const filterObjects = () => {
    const filtered: any = registeredBusinessData.filter((object: any) => {
      return object.email.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setRegisteredBusinessData(filtered);
  };

  useEffect(() => {
    filterObjects();
  }, [count]);

  const handleSearch = () => {
    filterObjects();
    setClearField(true);
  };
  const clearSearchField = () => {
    setSearchQuery("");
    setCount((prevCount) => prevCount + 1);
    setClearField(false);
  };
  // Handling input change in the search bar
  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const handleActivation = async (userId: any) => {
    setLoadingButton(true);
    const data = {
      userId: userId,
      status: "approved",
      commentaire: activationMessage,
    };

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      // Make the POST request
      const response = await axios.post(
        "http://74.208.84.251:9016/api/v1/customer/activeCustomers",
        data,
        {
          headers,
        }
      );
      toast.success("Account Activation Successful");
      setLoadingButton(false);
      window.location.reload();
    } catch (error) {
      // Handle the error
      setLoadingButton(false);
      toast.error("Something Went Wrong");
      console.error("Error:", error);
    }
  };
  const handleDeactivation = async (userId: any) => {
    setLoadingButton(true);
    const data = {
      userId: userId,
      status: "rejected",
      commentaire: activationMessage,
    };
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      // Make the POST request
      const response = await axios.post(
        "http://74.208.84.251:9016/api/v1/customer/activeCustomers",
        data,
        {
          headers,
        }
      );

      // Handle the response
      toast.success("Account Deactivation Successful");
      setLoadingButton(false);
      window.location.reload();
    } catch (error) {
      // Handle the error
      setLoadingButton(false);
      toast.error("Something Went Wrong");
      console.error("Error:", error);
    }
  };
  const handleActivationChange = (event: any) => {
    setActivationMessage(event.target.value);
  };
  const getTotalPages = () => Math.ceil(registeredBusinessData.length / itemsPerPage);

  const goToPage = (page: number) => {
    setCurrentPage(page);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <Box>
      <ToastContainer />
      <Box
        sx={{
          width: "100%",
          height: "3.5rem",
          boxShadow: 1,
          backgroundColor: "white",
          borderLeft: "orange 12px solid",
          borderRadius: 2,
          display: "flex",
          marginBottom: "1rem",
        }}
      >
        <Typography variant="h6" sx={{ margin: "auto 30px", fontWeight: 500 }}>
          REGISTERED BUSINESS LIST
        </Typography>
      </Box>
      {/* Search Bar */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "10px",
          marginLeft: "auto",
          backgroundColor: "#f6f6f6",
          width: "300px",
          padding: "3px 0px 3px 2px",
          position: "relative",
        }}
      >
        <Box>
          <TextField
            id="input-with-icon-textfield"
            label="Search"
            value={searchQuery}
            onChange={handleSearchChange}
            variant="outlined"
          />
        </Box>
        <Button
          variant="contained"
          sx={{ cursor: "pointer", marginLeft: "5px" }}
          onClick={handleSearch}
        >
          <SearchIcon sx={{ cursor: "pointer", fontSize: "40px" }} />
        </Button>{" "}
        {clearField ? (
          <ClearIcon
            onClick={clearSearchField}
            sx={{
              cursor: "pointer",
              position: "absolute",
              left: "195px",
            }}
          />
        ) : (
          ""
        )}
      </Box>
      {/* Search Bar */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Company Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Phone Number</StyledTableCell>
              <StyledTableCell>Country</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>View</StyledTableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <StyledTableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell>
                  <CircularProgress />
                </StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </StyledTableRow>
            </TableBody>
          ) : noData ? (
            <TableBody
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ margin: "20px auto" }}>NO DATA</Typography>
            </TableBody>
          ) : (
            <TableBody>
              {registeredBusinessData
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((item: any, index: any): any => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row" key={index}>
                      {item.companyname}
                    </StyledTableCell>
                    <StyledTableCell key={index}>{item.email}</StyledTableCell>
                    <StyledTableCell key={index}>
                      {item.mtnphonenumber}
                    </StyledTableCell>
                    <StyledTableCell key={index}>
                      {item.country}
                    </StyledTableCell>
                    <StyledTableCell key={index}>{item.state}</StyledTableCell>
                    <StyledTableCell key={index}>
                      <Button
                        onClick={() =>
                          handleOpen(
                            String(item.userId),
                            String(item.companyname),
                            String(item.tradingName),
                            String(item.staffSize),
                            String(item.industry),
                            String(item.category),
                            String(item.businessDescription),
                            String(item.registrationNumber),
                            String(item.taxNumber),
                            String(item.businessRegisDocUrl),
                            String(item.taxRegisDocUrl),
                            String(item.businessemail),
                            String(item.phoneNumber),
                            String(item.officeAddress),
                            String(item.proofAddressUrl),
                            String(item.webSite),
                            String(item.smaFacebook),
                            String(item.smaInstagram),
                            String(item.smaTwitter),
                            String(item.legalName),
                            String(item.firstname),
                            String(item.lastname),
                            String(item.dateOfBirth),
                            String(item.nationality),
                            String(item.role),
                            String(item.idGovUrl),
                            String(item.documentIdentificationUrl),
                            String(item.bankName),
                            String(item.bankAccount),
                            String(item.mtnphonenumber),
                            String(item.merchantnumbermoov),
                            String(item.state),
                            String(item.payAccountList),
                            String(item.country),
                            String(item.activityDescription),
                            String(item.documentUrl),
                            String(item.email),
                            String(item.individualId),
                            String(item.legalBusinessName),

                            index
                          )
                        }
                      >
                        Open
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          )}
        </Table>
        <Divider />
        <Box sx={{ padding: "0.8rem" }}>
          <Button disabled={currentPage === 1} onClick={goToPreviousPage}>
            Previous Page
          </Button>
          {Array.from({ length: getTotalPages() }).map((_, index) => (
            <Button
              key={index}
              onClick={() => goToPage(index + 1)}
              disabled={currentPage === index + 1}
            >
              {index + 1}
            </Button>
          ))}
          <Button
            disabled={currentPage === getTotalPages()}
            onClick={goToNextPage}
          >
            Next Page
          </Button>
        </Box>
      </TableContainer>
      <Modal
        key={modalIndex}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Box sx={{ marginBottom: "2rem" }}>
            {activeState ? (
              <Typography sx={{ fontWeight: 600, marginBottom: "0.7rem" }}>
                {modalState === "ACTIVE"
                  ? "Deactivate Account"
                  : "Activate Account"}
              </Typography>
            ) : (
              <Typography sx={{ fontWeight: 600, marginBottom: "0.7rem" }}>
                Business Profile
              </Typography>
            )}
            {activeState ? (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    sm: "repeat(1, 1fr)",
                  },
                  gridGap: "1rem",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Message"
                  variant="outlined"
                  value={activationMessage}
                  onChange={handleActivationChange}
                />{" "}
                <Typography
                  sx={{
                    color: "grey",
                    margin: "-5px 0px 0px 5px",
                    fontSize: "12px",
                  }}
                >
                  {modalState === "ACTIVE"
                    ? "Enter your reason for Deactivating Account"
                    : "Enter your reason for Activating Account"}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "1rem",
                  }}
                >
                  {modalState === "ACTIVE" ? (
                    // <Button
                    //   onClick={() => handleDeactivation(modalDocumentId)}
                    //   variant="contained"
                    //   color="error"
                    // >
                    //   Deactivate
                    // </Button>
                    <Box>
                      {loadingButton ? (
                        <Button
                          variant="contained"
                          color="error"
                          sx={{
                            marginRight: "5px",
                            width: "95px",
                            height: "37px",
                          }}
                        >
                          <CircularProgress size={20} color="inherit" />
                        </Button>
                      ) : (
                        <Button
                          onClick={() => handleDeactivation(modalID)}
                          variant="contained"
                          color="error"
                          sx={{ marginRight: "5px" }}
                        >
                          Deactivate
                        </Button>
                      )}
                      <Button
                        onClick={handleHide}
                        variant="contained"
                        color="primary"
                      >
                        Cancel
                      </Button>
                    </Box>
                  ) : (
                    <Box>
                      {loadingButton ? (
                        <Button
                          variant="contained"
                          color="success"
                          sx={{
                            marginRight: "5px",
                            width: "95px",
                            height: "37px",
                          }}
                        >
                          <CircularProgress size={20} color="inherit" />
                        </Button>
                      ) : (
                        <Button
                          onClick={() => handleActivation(modalID)}
                          variant="contained"
                          color="success"
                          sx={{ marginRight: "5px" }}
                        >
                          Activate
                        </Button>
                      )}
                      <Button
                        onClick={handleHide}
                        variant="contained"
                        color="primary"
                      >
                        Cancel
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            ) : (
              <Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      sm: "repeat(1, 1fr)",
                      md: "repeat(2, 1fr)",
                    },
                    gridGap: "1rem",
                  }}
                >
                  {modalID === "null" ? (
                    <TextField
                      id="input-with-icon-textfield"
                      label="User ID:"
                      value="N/A"
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  ) : (
                    <TextField
                      id="input-with-icon-textfield"
                      label="User ID:"
                      value={modalID}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  )}
                  {modalCompanyName === "null" ? (
                    <TextField
                      id="input-with-icon-textfield"
                      label="Company Name:"
                      value="N/A"
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  ) : (
                    <TextField
                      id="input-with-icon-textfield"
                      label="Company Name:"
                      value={modalCompanyName}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  )}
                  {modalTradingName === "null" ? (
                    <TextField
                      id="input-with-icon-textfield"
                      label="Trading Name:"
                      value="N/A"
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  ) : (
                    <TextField
                      id="input-with-icon-textfield"
                      label="Trading Name:"
                      value={modalTradingName}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  )}
                  {modalStaffSize === "null" ? (
                    <TextField
                      id="input-with-icon-textfield"
                      label="Staff Size:"
                      value="N/A"
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  ) : (
                    <TextField
                      id="input-with-icon-textfield"
                      label="Staff Size:"
                      value={modalStaffSize}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  )}
                  {modalIndustry === "null" ? (
                    <TextField
                      id="input-with-icon-textfield"
                      label="Industry:"
                      value="N/A"
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  ) : (
                    <TextField
                      id="input-with-icon-textfield"
                      label="Industry:"
                      value={modalIndustry}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  )}
                  {modalCategory === "null" ? (
                    <TextField
                      id="input-with-icon-textfield"
                      label="Category:"
                      value="N/A"
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  ) : (
                    <TextField
                      id="input-with-icon-textfield"
                      label="Category:"
                      value={modalCategory}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  )}
                  {modalBusinessDescription === "null" ? (
                    <TextField
                      id="input-with-icon-textfield"
                      label="Business Description:"
                      value="N/A"
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  ) : (
                    <TextField
                      id="input-with-icon-textfield"
                      label="Business Description:"
                      value={modalBusinessDescription}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />
                  )}
                </Box>

                <Box sx={{ marginBottom: "2rem" }}>
                  <Typography sx={{ fontWeight: 600, marginBottom: "0.7rem" }}>
                    Business Registration Information
                  </Typography>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        sm: "repeat(1, 1fr)",
                        md: "repeat(2, 1fr)",
                      },
                      gridGap: "1rem",
                    }}
                  >
                    {modalRegNumber === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Registration Number:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Registration Number:"
                        value={modalRegNumber}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalTaxNumber === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Tax Number:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Tax Number:"
                        value={modalTaxNumber}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalBusRegDocUrl === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Business Registration Document Url:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Business Registration Document Url:"
                        value={modalBusRegDocUrl}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalTaxRegDocUrl === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Tax Registration Document Url:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Staff Size:"
                        value={modalTaxRegDocUrl}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                  </Box>
                </Box>
                <Box sx={{ marginBottom: "2rem" }}>
                  <Typography sx={{ fontWeight: 600, marginBottom: "0.7rem" }}>
                    Contact Information
                  </Typography>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        sm: "repeat(1, 1fr)",
                        md: "repeat(2, 1fr)",
                      },
                      gridGap: "1rem",
                    }}
                  >
                    {modalBusinessEmail === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Business Email:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Business Email:"
                        value={modalBusinessEmail}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalPhoneNumber === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Phone Number:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Phone Number:"
                        value={modalPhoneNumber}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalOfficeAddress === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Office Address:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Office Address:"
                        value={modalOfficeAddress}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalProofAddUrl === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Proof Of Address Url:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Proof Of Address Url:"
                        value={modalProofAddUrl}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalWebsite === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Website"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Website:"
                        value={modalWebsite}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalSmaFacebook === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Facebook Url:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Facebook Url:"
                        value={modalSmaFacebook}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalSmaInstagram === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Instagram Url:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Instagram Url:"
                        value={modalSmaInstagram}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalSmaTwitter === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Twitter Url:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Twitter Url:"
                        value={modalSmaTwitter}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                  </Box>
                </Box>
                <Box sx={{ marginBottom: "2rem" }}>
                  <Typography sx={{ fontWeight: 600, marginBottom: "0.7rem" }}>
                    Business Representative
                  </Typography>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        sm: "repeat(1, 1fr)",
                        md: "repeat(2, 1fr)",
                      },
                      gridGap: "1rem",
                    }}
                  >
                    {modalLegalName === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Legal Name:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Legal Name:"
                        value={modalLegalName}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalFirstName === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="First Name:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="First Name:"
                        value={modalFirstName}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalLastName === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Last Name:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Last Name:"
                        value={modalLastName}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalDOB === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Date Of Birth:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Date Of Birth:"
                        value={modalDOB}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalNationality === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Nationality:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Nationality:"
                        value={modalNationality}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalRole === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Role:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Role:"
                        value={modalRole}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalIdGovUrl === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Government Issued ID Url:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Government Issued ID Url:"
                        value={modalIdGovUrl}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalDocUrl === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Document Government Url:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Document Government Url:"
                        value={modalDocUrl}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                  </Box>
                </Box>
                <Box sx={{ marginBottom: "2rem" }}>
                  <Typography sx={{ fontWeight: 600, marginBottom: "0.7rem" }}>
                    Payout Information
                  </Typography>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        sm: "repeat(1, 1fr)",
                        md: "repeat(2, 1fr)",
                      },
                      gridGap: "1rem",
                    }}
                  >
                    {modalBankName === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Bank Name:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Bank Name:"
                        value={modalBankName}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalBankAccount === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Bank Account:"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Bank Account:"
                        value={modalBankAccount}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalNumberMtn === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Number (MTN):"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Number (MTN):"
                        value={modalNumberMtn}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                    {modalNumberMoov === "null" ? (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Number (MOOV):"
                        value="N/A"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    ) : (
                      <TextField
                        id="input-with-icon-textfield"
                        label="Number (MOOV):"
                        value={modalNumberMoov}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "1rem",
                  }}
                >
                  {modalState === "ACTIVE" ? (
                    <Button
                      // onClick={() => handleDeactivation(modalDocumentId)}
                      onClick={handleUnhide}
                      variant="contained"
                      color="error"
                    >
                      Deactivate
                    </Button>
                  ) : (
                    <Button
                      // onClick={() => handleActivation(modalDocumentId)}
                      onClick={handleUnhide}
                      variant="contained"
                      color="success"
                    >
                      Activate
                    </Button>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default BusinessCustomers;

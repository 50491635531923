import React, { useState, useEffect } from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Typography,
  CardMedia,
  Divider,
  Button,
  Skeleton,
  CircularProgress,
  TextField,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Link } from "react-router-dom";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ClearIcon from "@mui/icons-material/Clear";
import { ROUTES } from "../../../constants/RouteData";
import { FilterIcon } from "../../../../common/assets/icons";
import IosShareIcon from "@mui/icons-material/IosShare";

const FinanceReportTableHead = [
  "Reference",
  "Date",
  "Operator",
  "Request Payment (Total Amount)",
  "Request Payment (Count)",
  "Make Deposit(Total Amount)",
  "Make Deposit (Count)",
  "Payout ( Total Amount)",
  "Payout (Count)",
  "Topup (Total Amount)",
  "Topup (Count)",
  "Refund (Total Amount)",
  "Refund (Count)",
  "Chargebacks(Total Amount)",
  "Chargebacks (Count)",
  "Available Balance",
  "QOS Fee",
  "Operator Fee",
  "Other Fee (s)",
];

const Row = ({ row, title }: any) => {
  return (
    <React.Fragment>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell component="th" scope="row">
          {row.reference === null ? "N/A" : row.reference}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.date === null ? "N/A" : row.date}
        </TableCell>
        <TableCell align="left">
          <Box>{row.operator === null ? "N/A" : row.operator}</Box>
        </TableCell>
        <TableCell align="center">
          {row.requestPaymentTotalAmount === null
            ? "N/A"
            : row.requestPaymentTotalAmount}
        </TableCell>
        <TableCell align="center">
          {row.requestPaymentCount === null ? "N/A" : row.requestPaymentCount}
        </TableCell>
        <TableCell align="center">
          {row.makeDepositTotalAmount === null
            ? "N/A"
            : row.makeDepositTotalAmount}
        </TableCell>
        <TableCell align="center">
          {row.makeDepositCount === null ? "N/A" : row.makeDepositCount}
        </TableCell>
        <TableCell align="center">
          {row.payoutTotalAmount === null ? "N/A" : row.payoutTotalAmount}
        </TableCell>
        <TableCell align="center">
          {row.payoutCount === null ? "N/A" : row.payoutCount}
        </TableCell>
        <TableCell align="center">
          {row.topUpTotalAmount === null ? "N/A" : row.topUpTotalAmount}
        </TableCell>
        <TableCell align="center">
          {row.topUpCount === null ? "N/A" : row.topUpCount}
        </TableCell>
        <TableCell align="center">
          {row.refundTotalAmount === null ? "N/A" : row.refundTotalAmount}
        </TableCell>
        <TableCell align="center">
          {row.refundCount === null ? "N/A" : row.refundCount}
        </TableCell>
        <TableCell align="center">
          {row.chargeBackTotalAmount === null
            ? "N/A"
            : row.chargeBackTotalAmount}
        </TableCell>
        <TableCell align="center">
          {row.chargeBackCount === null ? "N/A" : row.chargeBackCount}
        </TableCell>
        <TableCell align="center">
          {row.availableBalance === null ? "N/A" : row.availableBalance}
        </TableCell>
        <TableCell align="center">
          {row.qosFee === null ? "N/A" : row.qosFee}
        </TableCell>
        <TableCell align="center">
          {row.operatorFee === null ? "N/A" : row.operatorFee}
        </TableCell>
        <TableCell align="center">
          {row.otherFee === null ? "N/A" : row.otherFee}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
const FinancialReportTable = ({ data, title }: any) => {
  const [rows, setRow] = useState<any>(null);
  const itemsPerPage = 1;

  useEffect(() => {
    const row = data;
    setRow(row);
  }, [data]);

  return (
    <Box sx={{ boxShadow: 1 }}>
      <Box
        sx={{
          padding: "15px",
          display: "flex",
          gap: "1rem",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Typography
          sx={{ textAlign: "left", fontSize: "25px", fontWeight: 500 }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: { xs: "100%", md: "400px" },
          }}
        >
        </Box>
      </Box>
      <Divider />
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          overflowX: "auto",
          borderRadius: "0",
          border: "none",
          boxShadow: 0,
        }}
      >
        <Table aria-label="collapsible table" sx={{ padding: 0, margin: 0 }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#FCDFAE1A", textTransform: "" }}>
              {FinanceReportTableHead.map((item: any, index: number) => (
                <TableCell
                  align="center"
                  sx={{ fontWeight: 600, fontSize: "14px" }}
                  key={index}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {rows === null ? (
            <TableBody>
              <TableRow>
                <TableCell>Search for a report</TableCell>
              </TableRow>
            </TableBody>
          ) : rows === undefined ? (
            <TableBody>
              <TableRow>
                <TableCell>Search for a report</TableCell>
              </TableRow>
            </TableBody>
          ) :(
            <TableBody>
              <Row row={rows} title={title} />
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          gap: "20px",
          fontSize: "13px",
          backgroundColor: "#FCDFAE1A",
          color: "#6E6893",
          padding: "10px 0",
          borderRadius: " 0 0 10px 10px",
        }}
      >
        <Typography sx={{ fontWeight: 600, fontSize: "13px" }}>
          Rows per page: {itemsPerPage}
        </Typography>
        <Button disabled>
          <NavigateBeforeIcon />
        </Button>
        <Button disabled>
          <NavigateNextIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default FinancialReportTable;

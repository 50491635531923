import React, { useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Box,
  CardMedia,
} from "@mui/material"; 
import { useState } from "react";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ROUTES } from "../../components/constants/RouteData";
import { SignIn as Login } from "../../state/feautures/auth/signInSlice";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { change2FAStatus } from "../../state/feautures/global/isUsing2FA";
const QLogo = `${process.env.PUBLIC_URL}/assets/images/QOSPAY.png`;
const LoginArrow = `${process.env.PUBLIC_URL}/assets/images/loginarrow.svg`;

const SignIn: React.FC = () => {
  const dispatch = useAppDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);

  const signInData = useAppSelector((state: any) => state.signIn);
  const isLoading = signInData.loading;
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  useEffect(() => {
    const data = signInData.data;
    if (data.datas?.user?.isUsing2Fa === true) {
      dispatch(change2FAStatus(true));
    }
    if (data.responseMessage === "Successful") {
      if (
        localStorage.getItem("token") &&
        data.datas?.user?.isUsing2Fa === false
      ) {
        // history.push(ROUTES.DASHBOARD);
        toast.error("You do not have 2FA activated. Please login to your merchant account and activate 2FA");
        localStorage.setItem("isUsing2FA", false.toString());
      }
      if (
        localStorage.getItem("token") &&
        data.datas.user.isUsing2Fa === true
      ) {
        localStorage.setItem("isUsing2FA", true.toString());
        history.push(ROUTES.VERIFY2FA);
      }  if (
        localStorage.getItem("token") &&
        data.datas?.user?.isUsing2Fa === null
      ) {
        // history.push(ROUTES.DASHBOARD);
        toast.error("You do not have 2FA activated. Please login to your merchant account and activate 2FA");
        localStorage.setItem("isUsing2FA", false.toString());

      }
    } else if (data.responseMessage === "Bad credentials") {
      toast.error("Wrong Email or Password");
    } else if (data.responseMessage === "YOU ARE NOT ADMIN") {
      toast.error("You Do Not Have Admin Access");
    } else if (data.responseMessage === "No value present") {
      toast.error("Fill The Form");
    }
  }, [dispatch,signInData, history]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const credential = {
      password: password,
      rememberMe: true,
      username: username,
    };
    dispatch(Login(credential));
  };

  return (
    <Container sx={{ height: "100vh", paddingTop: "60px" }} maxWidth="sm">
      <ToastContainer />
      <Box
        sx={{
          height: "max-content",
          boxShadow: 4,
          padding: "60px 40px",
          margin: "auto",
          backgroundColor: "white",
        }}
      >
        <Box sx={{ marginBottom: "25px" }}>
          <CardMedia
            component="img"
            height="40px"
            image={QLogo}
            alt="QOS Logo"
            sx={{ objectFit: "contain" }}
          />
          <Typography sx={{ marginTop: "0", color: "#B2B2B2" }}>
            Admin Dashboard
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography>Email</Typography>
            <TextField
              type="text"
              placeholder="Email"
              variant="outlined"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              sx={{
                marginTop: "5px",
                "&:focus": {
                  border: "2px solid red",
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              margin: "20px auto",
            }}
          >
            <Typography>Password</Typography>
            <TextField
              type={showPassword ? "text" : "password"}
              // label="Password"
              placeholder="Password"
              variant="outlined"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{
                marginTop: "5px",
                "&:focus": {
                  border: "2px solid red",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Button
            sx={{
              height: "50px",
              marginTop: "20px",
              backgroundColor: "#F59E0B",
              "&:hover": {
                backgroundColor: "#E18D00", // Change this to your desired hover color
              },
            }}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
          >
            {isLoading ? (
              <CircularProgress sx={{ width: "10px", color: "white" }} />
            ) : (
              <Typography sx={{ display: "flex" }}>
                Login{" "}
                <CardMedia
                  component="img"
                  width="40px"
                  image={LoginArrow}
                  alt="QOS Logo"
                  sx={{
                    objectFit: "contain",
                    marginLeft: "8px",
                    "&:hover": {
                      marginLeft: "8.5px",
                    },
                  }}
                />
              </Typography>
            )}
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default SignIn;

import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import QRCode from "qrcode.react";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { LoginArrow, QLogo } from "../../common/assets/icons";
import { Container, CardMedia, CircularProgress } from "@mui/material";
import { Verify2FA } from "../../api/Data";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Validate2FA } from "../../state/feautures/auth/validate2FASlice";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { useHistory } from "react-router-dom";
const ModalBody = {
  title: {
    fontSize: "20px",
    fontWeight: 600,
  },
  contentBox: {
    display: "grid",
    gridTemplateColumns: { sm: "repeat(1, 1fr)", lg: "repeat(3, 1fr)" },
    p: 3,
    gap: "30px",
  },
  contentCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
  },
  text: {
    color: "gray",
  },
  textField: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    border: "solid 1px #000",
    p: 1,
    width: "100%",
    borderRadius: "5px",
  },
  icon: {
    cursor: "pointer",
  },
  storeBoxRow: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
  },
  storeBox: {
    backgroundColor: "#000",
    display: "flex",
    flexDirection: "row",
    color: "#fff",
    borderRadius: "5px",
    p: 1,
    justifyContent: "center",
    cursor: "pointer",
    alignItems: "center",
    gap: 1,
  },
  storeBoxSmText: {
    fontWeight: 400,
    fontSize: "10px",
  },
  storeBoxLgText: {
    fontWeight: 500,
    fontSize: "18px",
  },
};

export default function TwoFAModal({
  open,
  handleClose2FAModal,
  qrCodeData,
  mfaCodeData,
}: any) {
  const history = useHistory();

  const dispatch = useAppDispatch();
  const { data, loading, error } = useAppSelector((state) => state.validateFA);
  const [authenticationCode, setAuthenticationCode] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const handleAuthenticationTextChange = (event: any) => {
    setAuthenticationCode(event.target.value);
  };
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };


  const handleCopyClick = async () => {
    try {
      // Copy text to clipboard
      await navigator.clipboard.writeText(mfaCodeData);
      toast.success("Text copied to clipboard!");
    } catch (err) {
      toast.error("Failed to copy text");
    }
  };
  const username = localStorage.getItem("userEmail");
  const handle2FAActivation = async (e: any) => {
    e.preventDefault();
    const data = {
      code: authenticationCode,
      username: username,
    };
    dispatch(Validate2FA(data)).then((resultAction) => {
      if (Validate2FA.fulfilled.match(resultAction)) {
        // Dispatch an action to update Redux state indicating successful authentication
        // Example: dispatch(authenticateSuccess());
        
        // Redirect the user to the home page
        history.push("/");
        
        // Remove token and isUsing2FA from local storage
        localStorage.removeItem("token");
        localStorage.removeItem("isUsing2FA");
      }
    });
  };

  return (
    <div>
      <ToastContainer />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose2FAModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              transform: "translate(0)",
              marginTop: "20px",
              margin: "auto",
              borderRadius: "8px",
              boxShadow: 24,
              p: 4,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "50px",
            }}
          >
            <Container
              sx={{ height: "100vh", paddingTop: "0px" }}
              maxWidth="sm"
            >
              <Box
                sx={{
                  height: "max-content",
                  boxShadow: 4,
                  padding: "90px 40px",
                  margin: "auto",
                  backgroundColor: "white",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      marginBottom: "25px",
                      marginLeft: "0px",
                      width: "100%",
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="30px"
                      image={QLogo}
                      alt="QOS Logo"
                      sx={{ objectFit: "contain" }}
                    />
                  </Box>
                  {activeStep === 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Typography sx={{ textAlign: "center" }}>
                        Protect your account with just two easy steps
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                        }}
                      >
                        <Typography sx={{ color: "#B2B2B2" }}>
                          <b style={{ color: "#000" }}>
                            1. Link an authentication app to your account
                          </b>
                          <br /> Use a compatible authentication app like Google
                          Authenticator, Authy, Duo Mobile, 1Password etc. We
                          will generate a QR code for you to scan
                        </Typography>
                        <Typography sx={{ color: "#B2B2B2" }}>
                          <b style={{ color: "#000" }}>
                            2. Enter confirmation code
                          </b>
                          <br />
                          Two factor authentication will be turned on for
                          authentication app, which you can turn off any time.
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Button
                          sx={{
                            height: "50px",
                            marginTop: "20px",
                            backgroundColor: "#F59E0B",
                            "&:hover": {
                              backgroundColor: "#E18D00", // Change this to your desired hover color
                            },
                          }}
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          fullWidth
                        >
                          <Typography sx={{ display: "flex" }}>
                            Get Started
                          </Typography>
                        </Button>
                        <Button
                          sx={{ color: "#000", textDecoration: "underline" }}
                          onClick={handleClose2FAModal}
                        >
                          Close
                        </Button>
                      </Box>
                    </Box>
                  ) : activeStep === 1 ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Typography sx={{ textAlign: "center" }}>
                        Protect your account with just two easy steps
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ color: "#B2B2B2" }}>
                          Use your authentication app to scan this QR code. If
                          you do not have an authentication app on your device.
                          You will need to install one now.
                        </Typography>
                        <img
                          style={{ width: "150px" }}
                          src={qrCodeData}
                          alt="QR Code"
                        />
                        <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                          OR
                        </Typography>
                        <Typography sx={{ color: "#B2B2B2" }}>
                          Copy and paste the code below in your authenticator
                          app
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            border: "solid 1px #000",
                            p: 1,
                            width: "100%",
                            borderRadius: "5px",
                          }}
                        >
                          <Typography>
                            {mfaCodeData === ""
                              ? "QR Text is Loading..."
                              : mfaCodeData}
                          </Typography>
                          <FileCopyIcon
                            onClick={handleCopyClick}
                            sx={ModalBody.icon}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Button
                          sx={{
                            height: "50px",
                            marginTop: "20px",
                            backgroundColor: "#F59E0B",
                            "&:hover": {
                              backgroundColor: "#E18D00", // Change this to your desired hover color
                            },
                          }}
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          fullWidth
                        >
                          <Typography sx={{ display: "flex" }}>Next</Typography>
                        </Button>
                        <Button
                          sx={{ color: "#000", textDecoration: "underline" }}
                          onClick={handleClose2FAModal}
                        >
                          Close
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Typography sx={{ textAlign: "center" }}>
                        Enter the confirmation code
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ color: "#B2B2B2" }}>
                          Follow the instruction on the authentication app to
                          link your Qospay account. Once the authentication app
                          generates confirmation code, enter it here
                          <Typography
                            sx={{ color: "#B2B2B2", marginTop: "15px" }}
                          >
                            If the authentication process fails, restart the
                            process
                          </Typography>
                        </Typography>
                      </Box>
                      <form onSubmit={handle2FAActivation}>
                        <TextField
                          type="text"
                          placeholder="Enter confirmation code"
                          variant="outlined"
                          fullWidth
                          value={authenticationCode}
                          onChange={handleAuthenticationTextChange}
                          sx={{
                            marginTop: "5px",
                            "&:focus": {
                              border: "2px solid red",
                            },
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          {loading ? (
                            <Button
                              sx={{
                                height: "50px",
                                marginTop: "20px",
                                backgroundColor: "#F59E0B",
                                "&:hover": {
                                  backgroundColor: "#E18D00", // Change this to your desired hover color
                                },
                              }}
                              variant="contained"
                              color="primary"
                              disabled
                              fullWidth
                            >
                              <CircularProgress />
                            </Button>
                          ) : (
                            <Button
                              sx={{
                                height: "50px",
                                marginTop: "20px",
                                backgroundColor: "#F59E0B",
                                "&:hover": {
                                  backgroundColor: "#E18D00", // Change this to your desired hover color
                                },
                              }}
                              variant="contained"
                              color="primary"
                              type="submit"
                              fullWidth
                            >
                              <Typography sx={{ display: "flex" }}>
                                Confirm
                              </Typography>
                            </Button>
                          )}
                          <Button
                            sx={{ color: "#000", textDecoration: "underline" }}
                            onClick={handleClose2FAModal}
                          >
                            Close
                          </Button>
                        </Box>
                      </form>
                    </Box>
                  )}
                </Box>
              </Box>
            </Container>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

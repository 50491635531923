import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Endpoints } from "../../../api/EndPoints";
import axios from "axios";
import { toast } from "react-toastify";

const initialState: any = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null,
  data: [],
};

export const UpdateTransactionStatus = createAsyncThunk(
  "updatetransactionstatus",
  async (credential: any) => {
    try {
      const response = await axios.put(
        Endpoints.Transaction.UpdateTransactionStatus,
        {
            clientId: credential.clientId,
            transactionId: credential.transactionId,
            operator: credential.operator,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Transaction Status Updated");
      return response.data.datas;
    } catch (error: any) {
      toast.error("Transaction Status Not Updated");
      if (error.response && error.response.data.message) {
        return error.response.data.message;
      } else {
        return error.message;
      }
    }
  }
);

export const updateTransactionStatusSlice = createSlice({
  name: "updatetransactionstatus",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(UpdateTransactionStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        UpdateTransactionStatus.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = null;
          state.data = action.payload;
        }
      )
      .addCase(
        UpdateTransactionStatus.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
          state.data = [];
        }
      );
  },
});

export default updateTransactionStatusSlice.reducer;

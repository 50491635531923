import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Skeleton,
  Button,
  Paper,
  InputBase,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import DateRangePicker from "../../../constants/DateRangePicker";
import FinancialReportTable from "./FinancialReportTable";
import { useAppDispatch, useAppSelector } from "../../../../state/hooks";
import { GetGeneralFinanceReport } from "../../../../state/feautures/financeReport/getGeneralFinanceReportSlice";
import { GetMerchantFinanceReport } from "../../../../state/feautures/financeReport/getMerchantFinanceReportSlice";
import DownloadCSV from "../../../../services/helper/DownloadCSV";
import DownloadPDF from "../../../../services/helper/DownloadPDF";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { GetCustomerInformation } from "../../../../state/feautures/customer/getCustomerInformationSlice";

const formatDate = (date: Date | null) => {
  return date ? dayjs(date).format("YYYY-MM-DD") : "";
};
// Tab Functions
function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const FinanceReport = () => {
  const dispatch = useAppDispatch();
  const GeneralFinanceReport = useAppSelector(
    (state) => state.generalFinanceReport
  );

  const GeneralFinanceReportData = GeneralFinanceReport.data?.datas;
  console.log("GeneralFinanceReportData", GeneralFinanceReportData);
  const GeneralFinanceReportLoading = GeneralFinanceReport.loading;

  const MerchantFinanceReport = useAppSelector(
    (state) => state.merchantFinanceReport
  );

  const MerchantFinanceReportData = MerchantFinanceReport.data?.datas;
  console.log("GeneralMerchantReportData", MerchantFinanceReportData);
  const MerchantFinanceReportLoading = MerchantFinanceReport.loading;
  const CustomerInformationData: any = useAppSelector(
    (state) => state.customerInformation
  );

  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [documentType, setDocumentType] = useState("");
  const [operator, setOperator] = useState<any>("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [operatorValue, setOperatorValue] = useState<any>([]);
  const [mOperator, setMOperator] = useState<any>("");
  const [mDocumentType, setMDocumentType] = useState("");

  useEffect(() => {
    if (CustomerInformationData.data !== null) {
      setBusinessName(CustomerInformationData.data.datas.businessName);
      setOperatorValue(CustomerInformationData.data.datas.clientId);
    }
  }, [CustomerInformationData]);

  const handleDocumentTypeChange = (event: SelectChangeEvent) => {
    setDocumentType(event.target.value);
  };

  const handleTabChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const handleGeneralFinanceReport = () => {
    const credential = {
      operator: operator,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    };
    dispatch(GetGeneralFinanceReport(credential));
  };
  const handleMerchantFinanceReport = () => {
    const credential = {
      merchantId: mOperator.name,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    };
    dispatch(GetMerchantFinanceReport(credential));
  };
  const handleOperatorChange = (event: SelectChangeEvent) => {
    setOperator(event.target.value);
  };

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };

  const handleSearchQuery = (e: any) => {
    setSearchQuery(e.target.value);
  };

  const handleGetCustomerInformation = () => {
    const credential = {
      email: searchQuery,
    };
    dispatch(GetCustomerInformation(credential));
  };
  const handleMOperatorChange = (event: SelectChangeEvent) => {
    setMOperator(event.target.value);
  };

  const handleMDocumentTypeChange = (event: SelectChangeEvent) => {
    setMDocumentType(event.target.value);
  };
  return (
    <div>
      {/* Heading */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          marginBottom: "20px",
        }}
      >
        <Typography
          sx={{ fontSize: "24px", color: "#45464E", marginBottom: "-5px" }}
        >
          Finance Report
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            color: "#B2B2B2",
            fontWeight: 500,
            marginTop: "0",
          }}
        >
          Manage your finance report activities here
        </Typography>
      </Box>
      {/* Tabs Head*/}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: 0,
          marginBottom: "10px",
          marginTop: "30px",
        }}
      >
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          sx={{
            borderBottom: "none", // Remove the default bottom border
          }}
        >
          <Tab
            label="General Report"
            className={value === 0 ? "activeTab" : ""}
            sx={{
              border: value === 0 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 0 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(0)}
          />
          <Tab
            label="Generate Merchant Report"
            className={value === 1 ? "activeTab" : ""}
            sx={{
              border: value === 1 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 1 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(1)}
          />
          <Tab
            label="Generated Report"
            className={value === 2 ? "activeTab" : ""}
            sx={{
              border: value === 2 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 2 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <Box sx={{ width: "100%" }} aria-label="scrollable auto tabs example">
        {/* General Report */}
        <TabPanel value={value} index={0}>
          {loading ? (
            <Box sx={{}}>
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "140px",
                  backgroundColor: "#FFFFFF",
                  border: "solid 1px #E0E0E0",
                  borderRadius: "8px",
                }}
                animation="wave"
              />
            </Box>
          ) : (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "25px",
                  justifyContent: "flex-start",
                  padding: "30px",
                  margin: "30px auto ",
                  alignItems: "flex-start",
                  boxShadow: 1,
                  borderRadius: "8px",
                }}
              >
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 600, marginBottom: 0 }}
                >
                  Generate General Report
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    gap: "50px",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexDirection: "column",
                      marginTop: "10px",
                      gap: "25px",
                    }}
                  >
                    <FormControl sx={{ marginLeft: "0", width: "100%" }}>
                      <InputLabel id="demo-select-small-label">
                        Operator
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={operator}
                        label="Operator"
                        onChange={handleOperatorChange}
                      >
                        <MenuItem value="MTN">MTN</MenuItem>
                        <MenuItem value="MOOV">MOOV</MenuItem>
                        <MenuItem value="MOOVTG">MOOVTG</MenuItem>
                        <MenuItem value="TOGOCEL">TOGOCEL</MenuItem>
                        <MenuItem value="HUBCI">HUBCI</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl sx={{ marginLeft: "0", width: "100%" }}>
                      <InputLabel id="demo-select-small-label">
                        Document type
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={documentType}
                        label="Document type"
                        onChange={handleDocumentTypeChange}
                      >
                        <MenuItem value="CSV">CSV</MenuItem>
                        <MenuItem value="PDF">PDF</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "25px",
                      marginBottom: "auto",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={handleStartDateChange}
                          />
                        </DemoContainer>
                        <Box sx={{ width: "15px", border: "#000 solid 1px" }} />
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={handleEndDateChange}
                          />
                        </DemoContainer>
                      </Box>
                    </LocalizationProvider>
                  </Box>
                </Box>
                {/* Buttons */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                    width: "80%",
                    gap: "30px",
                  }}
                >
                  {GeneralFinanceReportLoading ? (
                    <Button
                      sx={{
                        height: "55px",
                        backgroundColor: "#F59E0B",
                        "&:hover": {
                          backgroundColor: "#E18D00", // Change this to your desired hover color
                        },
                      }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      <CircularProgress
                        sx={{ width: "10px", color: "white" }}
                      />
                    </Button>
                  ) : (
                    <Button
                      sx={{
                        height: "55px",
                        backgroundColor: "#F59E0B",
                        "&:hover": {
                          backgroundColor: "#E18D00", // Change this to your desired hover color
                        },
                      }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={handleGeneralFinanceReport}
                      disabled={!operator || !startDate || !endDate}
                    >
                      <Typography sx={{ display: "flex" }}>Search</Typography>
                    </Button>
                  )}
                  {documentType === "PDF" ? (
                    <Button
                      sx={{
                        height: "55px",
                        backgroundColor: "#FFF",
                        border: "2px solid #F59E0B",
                        color: "#F59E0B",
                        "&:hover": {
                          border: "2px solid #F59E0B",
                        },
                      }}
                      type="submit"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      disabled={!documentType}
                    >
                      <Typography sx={{ display: "flex" }}>
                        <DownloadPDF jsonData={GeneralFinanceReportData} />
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      sx={{
                        height: "55px",
                        backgroundColor: "#FFF",
                        border: "2px solid #F59E0B",
                        color: "#F59E0B",
                        "&:hover": {
                          border: "2px solid #F59E0B",
                        },
                      }}
                      type="submit"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      disabled={!documentType}
                    >
                      <Typography sx={{ display: "flex" }}>
                        <DownloadCSV jsonData={GeneralFinanceReportData} />
                      </Typography>
                    </Button>
                  )}
                </Box>
              </Box>
              <FinancialReportTable
                data={GeneralFinanceReportData}
                title="Recent General Report"
              />
            </Box>
          )}
        </TabPanel>

        {/* Merchant Rates */}
        <TabPanel value={value} index={1}>
          {loading ? (
            <Box sx={{}}>
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "140px",
                  backgroundColor: "#FFFFFF",
                  border: "solid 1px #E0E0E0",
                  borderRadius: "8px",
                }}
                animation="wave"
              />
            </Box>
          ) : (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "25px",
                  justifyContent: "flex-start",
                  padding: "30px",
                  margin: "30px auto ",
                  alignItems: "flex-start",
                  boxShadow: 1,
                  borderRadius: "8px",
                }}
              >
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 600, marginBottom: 0 }}
                >
                  Generate Merchant Report
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "50px",
                    width: "100%",
                  }}
                >
                  <Paper
                    component="form"
                    sx={{
                      p: "0px 4px",
                      display: "flex",
                      alignItems: "center",
                      width: 400,
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search by Email"
                      onChange={handleSearchQuery}
                      value={searchQuery}
                      inputProps={{ "aria-label": "search for merchant" }}
                    />
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="search"
                      onClick={handleGetCustomerInformation}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                  <Paper
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      justifySelf: "flex-start",
                      width: 400,
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Business Name"
                      value={
                        businessName === ""
                          ? ""
                          : businessName === null
                          ? "N/A"
                          : businessName
                      }
                      inputProps={{ "aria-label": "search google maps" }}
                    />
                    <IconButton
                      type="button"
                      sx={{ p: "10px", color: "red" }}
                      aria-label="search"
                      onClick={() => setBusinessName("")}
                    >
                      <CancelOutlinedIcon />
                    </IconButton>
                  </Paper>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    gap: "50px",
                    // margin: "20px auto",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexDirection: "column",
                      marginTop: "10px",
                      gap: "25px",
                    }}
                  >
                    <FormControl sx={{ marginLeft: "0", width: "100%" }}>
                      <InputLabel id="demo-select-small-label">
                        Operator
                      </InputLabel>
                      {operatorValue !== null && (
                        <Select
                          value={mOperator}
                          onChange={handleMOperatorChange}
                          displayEmpty
                          fullWidth
                        >
                          <MenuItem value="" disabled>
                            Select an option
                          </MenuItem>
                          {operatorValue.map((item: any, index: any) => (
                            <MenuItem key={index} value={item}>
                              {item.operator}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </FormControl>
                    <FormControl sx={{ marginLeft: "0", width: "100%" }}>
                      <InputLabel id="demo-select-small-label">
                        Document type
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={mDocumentType}
                        label="Document type"
                        onChange={handleMDocumentTypeChange}
                      >
                        <MenuItem value={20}>CSV</MenuItem>
                        <MenuItem value={30}>PDF</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: "25px",
                      marginBottom: "auto",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={handleStartDateChange}
                          />
                        </DemoContainer>
                        <Box sx={{ width: "15px", border: "#000 solid 1px" }} />
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={handleEndDateChange}
                          />
                        </DemoContainer>
                      </Box>
                    </LocalizationProvider>
                  </Box>
                </Box>
                {/* Buttons */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                    width: "80%",
                    gap: "30px",
                  }}
                >
                  <Button
                    sx={{
                      height: "55px",
                      backgroundColor: "#F59E0B",
                      "&:hover": {
                        backgroundColor: "#E18D00", // Change this to your desired hover color
                      },
                    }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleMerchantFinanceReport}
                    disabled={!mOperator || !startDate || !endDate}
                  >
                    {" "}
                    {MerchantFinanceReportLoading ? (
                      <CircularProgress
                        sx={{ width: "10px", color: "white" }}
                      />
                    ) : (
                      <Typography
                        sx={{ display: "flex" }}
                        // onClick={handleUnhide}
                      >
                        Search
                      </Typography>
                    )}
                  </Button>

                  {mDocumentType === "PDF" ? (
                    <Button
                      sx={{
                        height: "55px",
                        backgroundColor: "#FFF",
                        border: "2px solid #F59E0B",
                        color: "#F59E0B",
                        "&:hover": {
                          border: "2px solid #F59E0B",
                        },
                      }}
                      type="submit"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      disabled={!mDocumentType}
                    >
                      <Typography sx={{ display: "flex" }}>
                        <DownloadPDF jsonData={MerchantFinanceReportData} />
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      sx={{
                        height: "55px",
                        backgroundColor: "#FFF",
                        border: "2px solid #F59E0B",
                        color: "#F59E0B",
                        "&:hover": {
                          border: "2px solid #F59E0B",
                        },
                      }}
                      type="submit"
                      variant="outlined"
                      color="primary"
                      fullWidth
                      disabled={!mDocumentType}
                    >
                      <Typography sx={{ display: "flex" }}>
                        <DownloadCSV jsonData={MerchantFinanceReportData} />
                      </Typography>
                    </Button>
                  )}
                </Box>
              </Box>
              <FinancialReportTable
                data={MerchantFinanceReportData}
                title="Recent Merchant Report"
              />
            </Box>
          )}
        </TabPanel>
        {/* Generated Report */}
        <TabPanel value={value} index={2}>
          {loading ? (
            <Box sx={{}}>
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "140px",
                  backgroundColor: "#FFFFFF",
                  border: "solid 1px #E0E0E0",
                  borderRadius: "8px",
                }}
                animation="wave"
              />
            </Box>
          ) : (
            <Box>
              <FinancialReportTable
                data={handleMerchantFinanceReport}
                title="Generated Report"
              />
            </Box>
          )}
        </TabPanel>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            border: "solid 1px #E0E0E0",
            borderRadius: "8px",
            marginTop: "1rem",
          }}
        ></Box>
      </Box>
    </div>
  );
};

export default FinanceReport;

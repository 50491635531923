import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Endpoints } from "../../../api/EndPoints";
import axios from "axios";
import { toast } from "react-toastify";

const initialState: any = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null,
  data: [],
};

export const AddMerchantRate = createAsyncThunk(
  "addmerchantrate",
  async (credential: any, thunkAPI) => {
    // const data = {
    //   merchandId: credential.merchandId,
    //   businessName: credential.merchandId,
    //   contactInformation: credential.merchandId,
    //   type: credential.merchandId,
    //   country: credential.merchandId,
    //   requestPayment: credential.requestPayment,
    //   makeDeposit: credential.makeDeposit,
    //   cardPyament: credential.cardPayment,
    //   chargeBack: credential.chargeBack,
    //   topUp: credential.topup,
    //   transfert: credential.transfer,
    //   refund: credential.refund,
    //   payout: credential.payout,
    // };
    try {
      const response = await axios.post(
        Endpoints.ManageRates.AddMerchantRate,
        {
          merchandId: credential.merchandId,
          clientId: credential.clientId,
          businessName: credential.businessName,
          contactInformation: credential.contactInformation,
          type: credential.type,
          country: credential.country,
          requestPayment: credential.requestPayment,
          makeDeposit: credential.makeDeposit,
          cardPyament: credential.cardPayment,
          chargeBack: credential.chargeBack,
          topUp: credential.topup,
          transfert: credential.transfer,
          refund: credential.refund,
          payout: credential.payout,
          modifiedAt: credential.modifiedAt,
          modifiedBy: credential.modifiedBy,
          operator: credential.operator,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.data.responseMessage === "Successfull") {
        toast.success("Merchant Rate Added");
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      } else if (response.data.responseCode === "48") {
        toast.success("Customer already exist..Please do update");
      }

      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const addMerchantRateSclice = createSlice({
  name: "addmerchantrate",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(AddMerchantRate.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        AddMerchantRate.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = null;
          state.data = action.payload;
        }
      )
      .addCase(
        AddMerchantRate.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
          state.data = [];
        }
      );
  },
});

export default addMerchantRateSclice.reducer;

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CardMedia,
  Skeleton,
  CircularProgress,
  Button,
  Paper,
  InputBase,
  Divider,
  TextField,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  TogoIcon,
  BeninIcon,
  CotedivoireIcon,
  InactiveIcon,
  SuspendedIcon,
} from "../../../../common/assets/icons";
import DefaultRatesTable from "./DefaultRatesTable";
import MerchantRates from "./MerchantRates";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { LoginArrow } from "../../../../common/assets/icons";
import { useAppDispatch, useAppSelector } from "../../../../state/hooks";
import { AddMerchantRate } from "../../../../state/feautures/manageRates/addMerchantRateSlice";
import { GetMerchantRates } from "../../../../state/feautures/manageRates/getMerchantRatesSlice";
import RightDrawer from "../../../../common/drawer/RightDrawer";
// import { GetMerchantInformation } from "../../../../state/feautures/manageRates/getMerchantInformationSlice";
import { GetCustomerInformation } from "../../../../state/feautures/customer/getCustomerInformationSlice";

// Tab Functions
function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageRates = () => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(1);
  const [errorMessage, setErrorMessage] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [addCustomRate, setAddCustomRate] = useState(false);
  const [fees, setFees] = useState({
    requestPayment: "",
    topup: "",
    makeDeposit: "",
    transfer: "",
    cardPayment: "",
    refund: "",
    chargeBack: "",
    payout: "",
  });
  const [selectedOption, setSelectedOption] = useState<any>({});
  const [searchByMID, setSearchByMID] = useState("");
  const [searchByBN, setSearchByBN] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [customerInformation, setCustomerInformation] = useState<any>(null);
  const [operatorValue, setOperatorValue] = useState<any>([]);
  const [businessName, setBusinessName] = useState("");
  const [country, setCountry] = useState("");
  const [contactInformation, setContactInformation] = useState("");
  const [businessType, setBusinessType] = useState("");

  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
  };
  const { data, loading, error }: any = useAppSelector(
    (state) => state.merchantRate
  );
  const CustomerInformationData: any = useAppSelector(
    (state) => state.customerInformation
  );

  const handleSearchQuery = (e: any) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    dispatch(GetMerchantRates());
  }, [dispatch]);

  useEffect(() => {
    if (CustomerInformationData.data !== null) {
      setCustomerInformation(CustomerInformationData.data);
      setBusinessName(CustomerInformationData.data.datas.businessName);
      setOperatorValue(CustomerInformationData.data.datas.clientId);
      setCountry(CustomerInformationData.data.datas.country);
      setContactInformation(
        CustomerInformationData.data.datas.contactInformation
      );
      setBusinessType(CustomerInformationData.data.datas.type);
    }
  }, [CustomerInformationData]);

  const handleGetCustomerInformation = () => {
    const credential = {
      email: searchQuery,
    };
    dispatch(GetCustomerInformation(credential));
  };
  // Handler for all input fields
  const handleFeeChange =
    (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setFees((prevFees) => ({
        ...prevFees,
        [field]: e.target.value,
      }));
    };

  const handleSetFee = async () => {
    const data = {
      merchandId: searchQuery,
      clientId: selectedOption?.name,
      businessName: businessName,
      contactInformation: contactInformation,
      type: businessType,
      country: country,
      requestPayment: fees.requestPayment,
      makeDeposit: fees.makeDeposit,
      cardPyament: fees.cardPayment,
      chargeBack: fees.chargeBack,
      topUp: fees.topup,
      transfert: fees.transfer,
      refund: fees.refund,
      payout: fees.payout,
      modifiedAt: "",
      modifiedBy: "",
      operator: selectedOption?.operator,
    };
    dispatch(AddMerchantRate(data));
  };

  const handleTabChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  return (
    <div>
      {/* Heading */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          marginBottom: "20px",
        }}
      >
        <Typography
          sx={{ fontSize: "24px", color: "#45464E", marginBottom: "-5px" }}
        >
          Manage Rates
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            color: "#B2B2B2",
            fontWeight: 500,
            marginTop: "0",
          }}
        >
          Manage your rates activities here
        </Typography>
      </Box>
      {/* Tabs Head*/}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: 0,
          marginBottom: "10px",
          marginTop: "30px",
        }}
      >
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          sx={{
            borderBottom: "none", // Remove the default bottom border
          }}
        >
          {" "}
          <Tab
            label="Default Rates"
            className={value === 0 ? "activeTab" : ""}
            sx={{
              border: value === 0 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 0 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(0)}
          />
          <Tab
            label="Merchant Rates"
            className={value === 1 ? "activeTab" : ""}
            sx={{
              border: value === 1 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 1 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(1)}
          />
        </Tabs>
        {value === 1 && (
          <Button
            sx={{
              color: "#FFF",
              border: "solid 1px #F59E0B",
              backgroundColor: "#F59E0B",
              "&:hover": {
                backgroundColor: "#F59E0B", // Change this to your desired hover color
              },
            }}
            variant="contained"
            // onClick={() => setAddCustomRate(!addCustomRate)}
            onClick={toggleDrawer}
          >
            Add Merchant Rate
          </Button>
        )}
      </Box>
      <Box sx={{ width: "100%" }} aria-label="scrollable auto tabs example">
        <RightDrawer open={isDrawerOpen} onClose={toggleDrawer}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "28px",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 600,
                marginBottom: 0,
                margin: "auto",
              }}
            >
              Merchant Rate
            </Typography>
            <Paper
              component="form"
              sx={{
                p: "0px 4px",
                display: "flex",
                alignItems: "start",
                width: "100%",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search br Email"
                onChange={handleSearchQuery}
                value={searchQuery}
                inputProps={{ "aria-label": "search for merchant" }}
              />
              <IconButton
                onClick={handleGetCustomerInformation}
                type="button"
                sx={{ p: "5px" }}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Paper>
            <Paper
              component="form"
              sx={{
                p: "0px 4px",
                display: "flex",
                alignItems: "start",
                width: "100%",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Business Name"
                value={businessName === "" ? "" : businessName}
                inputProps={{ "aria-label": "search google maps" }}
              />
              <IconButton
                type="button"
                sx={{ p: "5px", color: "red" }}
                aria-label="search"
                onClick={() => setBusinessName("")}
              >
                <CancelOutlinedIcon />
              </IconButton>
            </Paper>
            <div>
              <InputLabel shrink>Select an Operator</InputLabel>
              {operatorValue !== null && (
                <Select
                  value={selectedOption}
                  onChange={handleChange}
                  displayEmpty
                  fullWidth
                  style={{ height: "40px" }}
                >
                  <MenuItem value="" disabled>
                    Select an option
                  </MenuItem>
                  {operatorValue.map((item: any, index: any) => (
                    <MenuItem key={index} value={item}>
                      {item.operator}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </div>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
                width: "100%",
                gap: "15px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { sm: "column", md: "column" },
                  gap: { sm: "20px", md: "13px" },
                  alignItems: { sm: "start", md: "start" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                  }}
                >
                  Request Payment
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    width: { sm: "100%", md: "100%" },
                    marginLeft: { sm: "0", md: "0" },
                    alignItems: "start",
                  }}
                >
                  <TextField
                    id="outlined-number"
                    label="Set fee"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: 0, // Minimum value
                      max: 4, // Maximum value
                    }}
                    size="small"
                    value={fees.requestPayment}
                    onChange={handleFeeChange("requestPayment")}
                  />
                  {/* <Button
                            sx={{
                              width: "100px",
                              backgroundColor: "#F59E0B",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#E18D00", // Change this to your desired hover color
                              },
                            }}
                            fullWidth
                          >
                            Set
                          </Button> */}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { sm: "column", md: "column" },
                  gap: { sm: "20px", md: "13px" },
                  alignItems: { sm: "start", md: "start" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                  }}
                >
                  Topup
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    width: { sm: "100%", md: "100%" },
                    marginLeft: { sm: "0", md: "0" },
                    alignItems: "start",
                  }}
                >
                  <TextField
                    id="outlined-number"
                    label="Set fee"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: 0, // Minimum value
                      max: 4, // Maximum value
                    }}
                    size="small"
                    value={fees.topup}
                    onChange={handleFeeChange("topup")}
                  />
                  {/* <Button
                            sx={{
                              width: "100px",
                              backgroundColor: "#F59E0B",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#E18D00", // Change this to your desired hover color
                              },
                            }}
                            fullWidth
                          >
                            Set
                          </Button> */}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { sm: "column", md: "column" },
                  gap: { sm: "20px", md: "13px" },
                  alignItems: { sm: "start", md: "start" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                  }}
                >
                  Make Deposit
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    width: { sm: "100%", md: "100%" },
                    marginLeft: { sm: "0", md: "0" },
                    alignItems: "start",
                  }}
                >
                  <TextField
                    id="outlined-number"
                    label="Set fee"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: 0, // Minimum value
                      max: 4, // Maximum value
                    }}
                    size="small"
                    value={fees.makeDeposit}
                    onChange={handleFeeChange("makeDeposit")}
                  />
                  {/* <Button
                            sx={{
                              width: "100px",
                              backgroundColor: "#F59E0B",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#E18D00", // Change this to your desired hover color
                              },
                            }}
                            fullWidth
                          >
                            Set
                          </Button> */}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { sm: "column", md: "column" },
                  gap: { sm: "20px", md: "13px" },
                  alignItems: { sm: "start", md: "start" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                  }}
                >
                  Transfer
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    width: { sm: "100%", md: "100%" },
                    marginLeft: { sm: "0", md: "0" },
                    alignItems: "start",
                  }}
                >
                  <TextField
                    id="outlined-number"
                    label="Set fee"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: 0, // Minimum value
                      max: 4, // Maximum value
                    }}
                    size="small"
                    value={fees.transfer}
                    onChange={handleFeeChange("transfer")}
                  />
                  {/* <Button
                            sx={{
                              width: "100px",
                              backgroundColor: "#F59E0B",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#E18D00", // Change this to your desired hover color
                              },
                            }}
                            fullWidth
                          >
                            Set
                          </Button> */}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { sm: "column", md: "column" },
                  gap: { sm: "20px", md: "13px" },
                  alignItems: { sm: "start", md: "start" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                  }}
                >
                  Card Payment
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    width: { sm: "100%", md: "100%" },
                    marginLeft: { sm: "0", md: "0" },
                    alignItems: "start",
                  }}
                >
                  <TextField
                    id="outlined-number"
                    label="Set fee"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: 0, // Minimum value
                      max: 4, // Maximum value
                    }}
                    size="small"
                    value={fees.cardPayment}
                    onChange={handleFeeChange("cardPayment")}
                  />
                  {/* <Button
                            sx={{
                              width: "100px",
                              backgroundColor: "#F59E0B",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#E18D00", // Change this to your desired hover color
                              },
                            }}
                            fullWidth
                          >
                            Set
                          </Button> */}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { sm: "column", md: "column" },
                  gap: { sm: "20px", md: "13px" },
                  alignItems: { sm: "start", md: "start" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                  }}
                >
                  Refund
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    width: { sm: "100%", md: "100%" },
                    marginLeft: { sm: "0", md: "0" },
                    alignItems: "start",
                  }}
                >
                  <TextField
                    id="outlined-number"
                    label="Set fee"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: 0, // Minimum value
                      max: 4, // Maximum value
                    }}
                    size="small"
                    value={fees.refund}
                    onChange={handleFeeChange("refund")}
                  />
                  {/* <Button
                            sx={{
                              width: "100px",
                              backgroundColor: "#F59E0B",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#E18D00", // Change this to your desired hover color
                              },
                            }}
                            fullWidth
                          >
                            Set
                          </Button> */}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { sm: "column", md: "column" },
                  gap: { sm: "20px", md: "13px" },
                  alignItems: { sm: "start", md: "start" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                  }}
                >
                  Charge Back
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    width: { sm: "100%", md: "100%" },
                    marginLeft: { sm: "0", md: "0" },
                    alignItems: "start",
                  }}
                >
                  <TextField
                    id="outlined-number"
                    label="Set fee"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: 0, // Minimum value
                      max: 4, // Maximum value
                    }}
                    size="small"
                    value={fees.chargeBack}
                    onChange={handleFeeChange("chargeBack")}
                  />
                  {/* <Button
                            sx={{
                              width: "100px",
                              backgroundColor: "#F59E0B",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#E18D00", // Change this to your desired hover color
                              },
                            }}
                            fullWidth
                          >
                            Set
                          </Button> */}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { sm: "column", md: "column" },
                  gap: { sm: "20px", md: "13px" },
                  alignItems: { sm: "start", md: "start" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                  }}
                >
                  Payout
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    width: { sm: "100%", md: "100%" },
                    marginLeft: { sm: "0", md: "0" },
                    alignItems: "start",
                  }}
                >
                  <TextField
                    id="outlined-number"
                    label="Set fee"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: 0, // Minimum value
                      max: 4, // Maximum value
                    }}
                    size="small"
                    value={fees.payout}
                    onChange={handleFeeChange("payout")}
                  />
                  {/* <Button
                            sx={{
                              width: "100px",
                              backgroundColor: "#F59E0B",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#E18D00", // Change this to your desired hover color
                              },
                            }}
                            fullWidth
                          >
                            Set
                          </Button> */}
                </Box>
              </Box>
            </Box>
            {/* Buttons */}
            <Box
              sx={{
                display: "flex",
                flexDirection: { sm: "column-reverse", md: "row" },
                justifyContent: "center",
                gap: "30px",
                margin: "auto",
                marginBottom: 0,
                width: { sm: "100%", md: "100%" },
              }}
            >
              <Button
                sx={{
                  color: "#F59E0B",
                  border: "solid 1px #F59E0B",
                  backgroundColor: "#FFF",
                  "&:hover": {
                    backgroundColor: "whitesmoke", // Change this to your desired hover color
                  },
                }}
                type="submit"
                variant="contained"
                fullWidth
                onClick={toggleDrawer}
              >
                Cancel
              </Button>
              {/* </Link> */}
              <Button
                sx={{
                  backgroundColor: "#F59E0B",
                  "&:hover": {
                    backgroundColor: "#E18D00", // Change this to your desired hover color
                  },
                }}
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                {loading ? (
                  <CircularProgress sx={{ width: "10px", color: "white" }} />
                ) : (
                  <Typography sx={{ display: "flex" }} onClick={handleSetFee}>
                    Set
                    <CardMedia
                      component="img"
                      width="40px"
                      image={LoginArrow}
                      alt="arrow"
                      sx={{
                        objectFit: "contain",
                        marginLeft: "8px",
                        "&:hover": {
                          marginLeft: "8.5px",
                        },
                      }}
                    />
                  </Typography>
                )}
              </Button>
            </Box>
          </Box>
        </RightDrawer>
        {/* Default Rates */}
        <TabPanel value={value} index={0}>
          {loading ? (
            <Box sx={{}}>
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "140px",
                  backgroundColor: "#FFFFFF",
                  border: "solid 1px #E0E0E0",
                  borderRadius: "8px",
                }}
                animation="wave"
              />
            </Box>
          ) : (
            <DefaultRatesTable title="Default Rates" />
          )}
        </TabPanel>
        {/* Merchant Rates */}
        <TabPanel value={value} index={1}>
          {loading ? (
            <Box sx={{}}>
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "140px",
                  backgroundColor: "#FFFFFF",
                  border: "solid 1px #E0E0E0",
                  borderRadius: "8px",
                }}
                animation="wave"
              />
            </Box>
          ) : (
            <Box>
              <MerchantRates data={data} title="Merchant Rates" />
            </Box>
          )}
        </TabPanel>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            border: "solid 1px #E0E0E0",
            borderRadius: "8px",
            marginTop: "1rem",
          }}
        ></Box>
      </Box>
    </div>
  );
};

export default ManageRates;

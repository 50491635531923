import React from 'react';
import { jsPDF } from 'jspdf';

interface Props {
  jsonData: Record<string, any>; // Adjust the type according to your JSON structure
}

const DownloadPDF: React.FC<Props> = ({ jsonData }) => {
  const downloadPdf = () => {
    const PAGE_WIDTH = 210;
    const PAGE_HEIGHT = 297;

    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: [PAGE_WIDTH, PAGE_HEIGHT]
    });

    const splitDataIntoPages = (data: string[]) => {
      let startY = 10;
      let remainingData = [...data];

      while (remainingData.length > 0) {
        const currentPageData = remainingData.splice(0, 50); // Adjust the number of lines per page as needed
        pdf.text(currentPageData.join('\n'), 10, startY);
        if (remainingData.length > 0) {
          pdf.addPage([PAGE_WIDTH, PAGE_HEIGHT], 'portrait');
        }
        startY = 10;
      }
    };

    // Convert JSON to string and split into pages
    const jsonString = JSON.stringify(jsonData, null, 4);
    const splitData = jsonString.split('\n');
    splitDataIntoPages(splitData);

    pdf.save('data.pdf');
  };

  return (
    <span onClick={downloadPdf}>
      Download
   </span>
  );
}

export default DownloadPDF;

import { Box, CircularProgress, Switch } from "@mui/material";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Verify2FA, Enable2FA } from "../../api/Data";
import "react-toastify/dist/ReactToastify.css";
import TwoFAModal from "../modals/TwoFAModal";

const TwoFASwitch = () => {
  const [check2FA, setChecked2FA] = useState(false);
  const [open2FAModal, setOpen2FAModal] = useState(false);
  // const handleOpen2FAModal = () => setOpen2FAModal(true);
  const handleClose2FAModal = () => setOpen2FAModal(false);
  const [qrCodeData, setQrCodeData] = useState("");
  const [mfaCodeData, setMfaCodeData] = useState("");
  const [twoFALoading, setTwoFALoading] = useState(false);
  const credentials = {
    username: localStorage.getItem("userEmail"),
    // username: "josen31097@tospage.com"
  };

  const handle2FAChange = async (event: any) => {
    setTwoFALoading(true);
    setChecked2FA(event.target.checked);
    if (check2FA === true) {
      console.log("Disable 2FA");
    } else if (check2FA === false) {
      console.log("Enable 2FA");
      try {
        const response = await Enable2FA(credentials);
        setQrCodeData(response.qrCode);
        setMfaCodeData(response.mfaCode);
        setTwoFALoading(false);
        setOpen2FAModal(true);
        console.log(response)
        console.log(qrCodeData, mfaCodeData)
        if (response.status === 200) {
          setOpen2FAModal(true);
        }
      } catch (error) {
        //   setOpen2FAModal(true);
          // Handle error, if any
        toast.error("Something went wrong");
        console.error("Error sending toggle state:", error);
        setTwoFALoading(false);
        setChecked2FA(false);
      }
    }
  };
  return (
    <Box>
      <ToastContainer />
      {twoFALoading ? (
        <CircularProgress />
      ) : (
        <Box>
          {!check2FA && (
            <Switch
              checked={check2FA}
              onChange={handle2FAChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          )}
        </Box>
      )}
      <TwoFAModal
        open={open2FAModal}
        handleClose2FAModal={handleClose2FAModal}
        qrCodeData={qrCodeData}
        mfaCodeData={mfaCodeData}
      />
    </Box>
  );
};

export default TwoFASwitch;

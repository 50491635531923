import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  CardMedia,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import * as echarts from "echarts";
import { FilterIcon } from "../../../../common/assets/icons";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// TODO make reuseable
function formatDate(inputDate: any) {
  const formattedDate = inputDate.toISOString().split("T")[0];
  return formattedDate;
}
const today = new Date();

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// Example usage
const data1: any = [
  {
    typeTransaction: "API",
    nbre: 84914,
    amount: 6.99258598123e11,
  },
  {
    typeTransaction: "Checkout",
    nbre: 11266,
    amount: 1.8982341e8,
  },
  {
    typeTransaction: "Payment Link",
    nbre: 29,
    amount: 3014057.0,
  },
  {
    typeTransaction: "Store",
    nbre: 29,
    amount: 3014057.0,
  },
  {
    typeTransaction: "USSD",
    nbre: 189,
    amount: 1.45453438e10,
  },
  {
    typeTransaction: "Transfer",
    nbre: 138,
    amount: 138.0,
  },
  {
    typeTransaction: "Wordpress",
    nbre: 2213,
    amount: 5.1584038e7,
  },
];

const Revenue = () => {
  const [timelineDropdown, setTimelineDropdown] = React.useState("10");
  const [value, setValue] = useState(0);
  const [operator, setOperator] = React.useState("MTN");
  const [dateDeb, setDateDeb] = useState(today);
  const [dateFin, setDateFin] = useState(today);

  const chartRef = useRef(null);
  const handleChartChange = (event: SelectChangeEvent) => {
    setTimelineDropdown(event.target.value as string);
  };
  // CHART
  useEffect(() => {
    const chart = echarts.init(chartRef.current);

    // Define the chart options
    const options = {
      // title: {
      //   text: 'Revenue'
      // },
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: ["Current Week", "Previous Week"],
      },
      grid: {
        left: "30px", // Adjust the left margin
        right: "30px", // Adjust the right margin // Adjust the top margin
        containLabel: true, // Set to true if you want to include axis labels within the chart area
      },
      // FEATURE FOR SAVING GRAPH
      // toolbox: {
      //   feature: {
      //     saveAsImage: {},
      //   },
      // },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Current Day",
          type: "line",
          stack: "Total",
          data: [120, 132, 101, 134, 90, 230, 210],
        },
        {
          name: "Previous Day",
          type: "line",
          stack: "Total",
          data: [20, 182, 191, 234, 290, 330, 310],
        },
        {
          name: "Current Day2",
          type: "line",
          stack: "Total",
          data: [420, 132, 101, 134, 90, 230, 210],
        },
        {
          name: "Previous Day2",
          type: "line",
          stack: "Total",
          data: [120, 182, 191, 234, 290, 330, 310],
        },
      ],
    };

    chart.setOption(options);
  }, []);

  const handleOperatorChange = (event: SelectChangeEvent) => {
    setOperator(event.target.value);
  };
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  const handleSetTodayDate = () => {
    const formattedToday = formatDate(today);
    setDateDeb(formattedToday);
    setDateFin(formattedToday);
    console.log(formattedToday);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      {/* Heading */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
        }}
      >
        <Typography
          sx={{ fontSize: "24px", color: "#45464E", marginBottom: "-5px" }}
        >
          Revenue
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            color: "#B2B2B2",
            fontWeight: 500,
            marginTop: "0",
          }}
        >
          Manage your revenue activities here
        </Typography>
      </Box>
      {/* Tabs Head */}
      <Box
        sx={{
          borderBottom: 0,
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            borderBottom: "none", // Remove the default bottom border
          }}
        >
          <Tab
            label="D"
            className={value === 0 ? "activeTab" : ""}
            sx={{
              border: value === 0 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 0 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(0)}
            onClick={handleSetTodayDate}
          />
          <Tab
            label="W"
            className={value === 1 ? "activeTab" : ""}
            sx={{
              border: value === 1 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 1 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(1)}
            // onClick={handleSetTodayDate}
          />
          <Tab
            label="M"
            className={value === 2 ? "activeTab" : ""}
            sx={{
              border: value === 2 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 2 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(2)}
            // onClick={handleSetTodayDate}
          />
          <Tab
            label="Q"
            className={value === 3 ? "activeTab" : ""}
            sx={{
              border: value === 3 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 3 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(3)}
            // onClick={handleSetTodayDate}
          />
          <Tab
            label="C"
            className={value === 4 ? "activeTab" : ""}
            sx={{
              border: value === 4 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 4 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(4)}
            // onClick={handleSetTodayDate}
          />
        </Tabs>
        <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
          <InputLabel id="demo-select-small-label">Select Operator</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={operator}
            label="Operator"
            onChange={handleOperatorChange}
          >
            <MenuItem value="">
              <em>ALL</em>
            </MenuItem>
            <MenuItem value="MTN">MTN</MenuItem>
            <MenuItem value="MOOV">MOOV</MenuItem>
            <MenuItem value="MOOVTG">MOOVTG</MenuItem>
            <MenuItem value="TOGOCEL">TOGOCEL</MenuItem>
            <MenuItem value="HUBCI">HUBCI</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ marginBottom: "30px" }}>
        <TabPanel value={value} index={0}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
            {/*Charts */}
            <Box
              sx={{
                border: "solid 1px #E0E0E0",
                borderRadius: "8px",
                padding: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: "20px 20px 0 30px ",
                  marginBottom: "-20px",
                }}
              >
                <Typography
                  sx={{ fontSize: "24px", fontWeight: 600, color: "#797D8C" }}
                >
                  {operator} (XOF)
                </Typography>
                <Box
                  sx={{
                    width: "64%",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Typography sx={{ color: "#797D8C", fontSize: "12px" }}>
                      Amount:
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "6px",
                          height: "6px",
                          borderRadius: "50%",
                          backgroundColor: "orange",
                        }}
                      ></Box>
                      <Typography sx={{ fontSize: "9px" }}>Current</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "6px",
                          height: "6px",
                          borderRadius: "50%",
                          backgroundColor: "red",
                        }}
                      ></Box>
                      <Typography sx={{ fontSize: "9px" }}>Previous</Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "19px",
                    }}
                  >
                    <Typography sx={{ color: "#797D8C", fontSize: "12px" }}>
                      Count:
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "6px",
                          height: "6px",
                          borderRadius: "50%",
                          backgroundColor: "blue",
                        }}
                      ></Box>
                      <Typography sx={{ fontSize: "9px" }}>Current</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "6px",
                          height: "6px",
                          borderRadius: "50%",
                          backgroundColor: "green",
                        }}
                      ></Box>
                      <Typography sx={{ fontSize: "9px" }}>Previous</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  padding: "10px",
                  display: "grid",
                  gridTemplateColumns: "4fr 1fr",
                }}
              >
                <Box>
                  <div
                    ref={chartRef}
                    style={{
                      width: "100%",
                      height: "400px",
                      marginBottom: "-50px",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "20px",
                    marginTop: "40px",
                    marginRight: "30px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      paddingTop: "10px",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        width: "110px",
                        height: "40px",
                        border: "1px solid black",
                        borderRadius: "6px",
                        padding: "1px 4px",
                        cursor: "pointer",
                        marginLeft: "auto",
                        position: "absolute",
                        top: "-100px",
                        right: "0",
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="20px"
                        image={FilterIcon}
                        alt="Icon"
                        sx={{
                          objectFit: "contain",
                          width: "20px",
                        }}
                      />
                      <Typography>Filter</Typography>{" "}
                    </Box>
                    <Box
                      sx={{
                        borderRadius: "8px",
                        border: "solid 1px #E0E0E0",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "20px",
                      }}
                    >
                      <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
                        1.7B
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "green",
                          fontWeight: 600,
                        }}
                      >
                        15%
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        borderRadius: "8px",
                        border: "solid 1px #E0E0E0",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "20px",
                      }}
                    >
                      <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
                        100M
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "green",
                          fontWeight: 600,
                        }}
                      >
                        35%
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      marginLeft: "auto",
                      textDecoration: "underline",
                      color: "#5041BC",
                      cursor: "pointer",
                      fontSize: "12px",
                    }}
                  >
                    View Report
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "10px",
              }}
            >
              <Typography
                sx={{ color: "#797D8C", fontWeight: 700, fontSize: "20px" }}
              >
                Revenue by source
              </Typography>
              {data1.length === 0 ? (
                <Typography>No Data</Typography>
              ) : (
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  {data1.map((item: any, index: number) => (
                    <Box
                      sx={{
                        backgroundColor: "#FFFFFF",
                        border: "solid 1px #E0E0E0",
                        borderRadius: "8px",
                        padding: "15px 25px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                      }}
                      key={index}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography sx={{ fontSize: "13px", color: "#797D8C" }}>
                          {item.typeTransaction}
                        </Typography>
                        <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
                          {item.nbre}
                        </Typography>
                      </Box>
                      <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
                        {item.amount}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {" "}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { sm: "1fr", md: "repeat(3, 1fr)" },
              gridGap: "1rem",
              margin: "0",
            }}
          >
            <Typography>Coming Soon</Typography>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { sm: "1fr", md: "repeat(3, 1fr)" },
              gridGap: "1rem",
              margin: "0",
            }}
          >
            <Typography>Coming Soon</Typography>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { sm: "1fr", md: "repeat(3, 1fr)" },
              gridGap: "1rem",
              margin: "0",
            }}
          >
            <Typography>Coming Soon</Typography>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { sm: "1fr", md: "repeat(3, 1fr)" },
              gridGap: "1rem",
              margin: "0",
            }}
          >
            <Typography>Coming Soon</Typography>
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Revenue;

import * as React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Box } from "@mui/material";

export default function RightDrawer({ children, open, onClose }: any) {
  return (
    <div>
      {(["right"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={() => onClose()}>Close</Button> */}
          <SwipeableDrawer
            anchor={anchor}
            open={open} // Use open prop
            onClose={onClose} // Use onClose prop
            onOpen={() => {}} // Handle onOpen if needed
            sx={{}}
          >
            <Box sx={{ marginTop: "50px", padding: "50px", width: {sm:'70vw', md:"50vw"} }}>{children}</Box>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}

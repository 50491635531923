import { useState } from "react";
import {
  Box,
  Typography,
  Tab,
  Tabs,
} from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import IndividualCustomers from "../../components/pages/customers/IndividualCustomers";
import BusinessCustomers from "../../components/pages/customers/BusinessCustomers";


// TABS SETUP
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RegisteredCustomers: React.FC = () => {
 
  // TAB SETUP
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };


  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "3.5rem",
          boxShadow: 1,
          backgroundColor: "white",
          borderLeft: "orange 12px solid",
          borderRadius: 2,
          display: "flex",
          marginBottom: "1rem",
        }}
      >
        <Typography variant="h6" sx={{ margin: "auto 30px", fontWeight: 500 }}>
          CUSTOMER OVERVIEW
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          boxShadow: 1,
          backgroundColor: "white",
          borderRadius: 2,
          display: "grid",
          gridTemplateColumns: "repeat(4,1fr)",
          gridGap: 10,
          marginBottom: 7,
          padding: 1,
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            height: "150px",
            boxShadow: 1,
            borderRadius: 1,
            textAlign: "left",
            display: "flex",
          }}
        >
          <Box sx={{ margin: "auto auto 28px 30px" }}>
            <Typography
              variant="h4"
              sx={{
                position: "relative",
                "::after": {
                  content: "''",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "5px",
                  background: "orange",
                },
              }}
            >
              4400000
            </Typography>
            <Typography sx={{ fontSize: "14.5px", marginTop: "8px" }}>
              Total Customer
            </Typography>
          </Box>
        </Box>{" "}
        <Box
          sx={{
            backgroundColor: "white",
            height: "150px",
            boxShadow: 1,
            borderRadius: 1,
            textAlign: "left",
            display: "flex",
          }}
        >
          <Box sx={{ margin: "auto auto 28px 30px" }}>
            <Typography
              variant="h4"
              sx={{
                position: "relative",
                "::after": {
                  content: "''",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "5px",
                  background: "green",
                },
              }}
            >
              440
            </Typography>
            <Typography sx={{ fontSize: "14.5px", marginTop: "8px" }}>
              Active Customer
            </Typography>
          </Box>
        </Box>{" "}
        <Box
          sx={{
            backgroundColor: "white",
            height: "150px",
            boxShadow: 1,
            borderRadius: 1,
            textAlign: "left",
            display: "flex",
          }}
        >
          <Box sx={{ margin: "auto auto 28px 30px" }}>
            <Typography
              variant="h4"
              sx={{
                position: "relative",
                "::after": {
                  content: "''",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "5px",
                  background: "gray",
                },
              }}
            >
              440
            </Typography>
            <Typography sx={{ fontSize: "14.5px", marginTop: "8px" }}>
              Inactive Customer
            </Typography>
          </Box>
        </Box>{" "}
        <Box
          sx={{
            backgroundColor: "white",
            height: "150px",
            boxShadow: 1,
            borderRadius: 1,
            textAlign: "left",
            display: "flex",
          }}
        >
          <Box sx={{ margin: "auto auto 28px 30px" }}>
            <Typography
              variant="h4"
              sx={{
                position: "relative",
                "::after": {
                  content: "''",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "5px",
                  background: "red",
                },
              }}
            >
              440
            </Typography>
            <Typography sx={{ fontSize: "14.5px", marginTop: "8px" }}>
              Blocked Customer
            </Typography>
          </Box>
        </Box>{" "}
      </Box>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            margin: "auto",
            borderBottom: 1,
            borderColor: "divider",
            width: "max-content",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="icon label tabs example"
          >
            <Tab
              icon={<BusinessIcon />}
              label="REGISTERED BUSINESS"
              {...a11yProps(0)}
            />
            <Tab
              icon={<PersonIcon />}
              label="INDIVIDUAL CUSTOMER"
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {/* TABLE REGISTERED BUSINESS */}
       <BusinessCustomers />
        </TabPanel>
        <TabPanel value={value} index={1}>
          {/* TABLE Individual Customers */}
         <IndividualCustomers />
        </TabPanel>
      </Box>
    </>
  );
};
export default RegisteredCustomers;

import { Sidebar } from "../common/sideBar/SideBar.Component";
import { Box } from "@mui/material";

const MainLayout = ({ children }) => {
  return (
    <main style={{background: '#FAFAFA'}}>
      <Sidebar children={children} />
    </main>
  );
};

export default MainLayout;

import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Box,
  CardMedia,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ROUTES } from "../../components/constants/RouteData";
import { Verify2FA } from "../../state/feautures/auth/verify2FASlice";
import { useAppDispatch, useAppSelector } from "../../state/hooks";

const QLogo = `${process.env.PUBLIC_URL}/assets/images/QOSPAY.png`;
const LoginArrow = `${process.env.PUBLIC_URL}/assets/images/loginarrow.svg`;

const Verify2Fa: React.FC = () => {
  const dispatch = useAppDispatch();
  const [vCode, setVCode] = useState("");
  const history = useHistory();

  const verify2FAData = useAppSelector((state) => state.verify2FA);
  const isLoading = verify2FAData.loading;
  useEffect(() => {
    const data = verify2FAData.data;
    if (data.responseMessage === "Successful") {
      history.push(ROUTES.DASHBOARD);
    }
  }, [verify2FAData, history]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const credential = {
      code: vCode,
      username: localStorage.getItem("userEmail"),
    };
    dispatch(Verify2FA(credential));
  };

  return (
    <Container sx={{ height: "100vh", paddingTop: "60px" }} maxWidth="sm">
      <ToastContainer />
      <Box
        sx={{
          height: "max-content",
          boxShadow: 4,
          padding: "60px 40px",
          margin: "auto",
          backgroundColor: "white",
        }}
      >
        <Box sx={{ marginBottom: "25px" }}>
          <CardMedia
            component="img"
            height="40px"
            image={QLogo}
            alt="QOS Logo"
            sx={{ objectFit: "contain" }}
          />
          <Typography sx={{ marginTop: "0", color: "#B2B2B2" }}>
            Verify Two Factor Authenticator
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography>Verification Code</Typography>
            <TextField
              type="text"
              placeholder="Verification Code"
              variant="outlined"
              fullWidth
              value={vCode}
              onChange={(e) => setVCode(e.target.value)}
              sx={{
                marginTop: "5px",
                "&:focus": {
                  border: "2px solid red",
                },
              }}
            />
          </Box>
          <Button
            sx={{
              height: "50px",
              marginTop: "20px",
              backgroundColor: "#F59E0B",
              "&:hover": {
                backgroundColor: "#E18D00", // Change this to your desired hover color
              },
            }}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
          >
            {isLoading ? (
              <CircularProgress sx={{ width: "10px", color: "white" }} />
            ) : (
              <Typography sx={{ display: "flex" }}>
                Verify
                <CardMedia
                  component="img"
                  width="40px"
                  image={LoginArrow}
                  alt="QOS Logo"
                  sx={{
                    objectFit: "contain",
                    marginLeft: "8px",
                    "&:hover": {
                      marginLeft: "8.5px",
                    },
                  }}
                />
              </Typography>
            )}
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default Verify2Fa;

import { Box, Typography, Divider, TextField } from "@mui/material";
import TwoFASwitch from "../../switch/TwoFASwitch";
import { Disable2FA } from "../../../api/Data";
import { useState } from "react";

const Security = () => {
  const [value, setValue] = useState("");

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    console.log("Submitted value:", value);
    const credentials = {
      username: localStorage.getItem("userEmail"),
      vcode: value,
    };
    try {
      const response = Disable2FA(credentials);
      console.log(response);
    } catch (error) {}
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "flex-start",
        flexDirection: "column",
        border: "1px solid #F1F3F9",
        borderRadius: "8px",
      }}
    >
      <Typography sx={{ p: 2 }} variant="h6">
        Security
      </Typography>
      <Divider sx={{ border: "1px solid #F1F3F9", width: "100%" }} />
      <Box sx={{ p: 2, width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "flex-start",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography sx={{ fontWeight: 600 }}>
            Two Factor Authentication
          </Typography>
          <Box
            sx={{
              p: 2,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ color: "#B2B2B2", fontSize: "14px" }}>
              Two factor authentication is currently <span>DISABLED</span> on
              your account
            </Typography>
            <Typography sx={{ color: "#B2B2B2", fontSize: "14px" }}>
              Would you like to <span>enable</span> it?
            </Typography>
            <TwoFASwitch />
          </Box>
          {/* <Typography>DISABLE Two Factor Authentication</Typography>
          <form onSubmit={handleSubmit}>
            <label>
              Enter Code
              <input type="tel" value={value} onChange={handleChange} />
            </label>
            <br />
            <br />

            <button type="submit">Submit</button>
          </form> */}
        </Box>
      </Box>
    </Box>
  );
};

export default Security;

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  TextField,
} from "@mui/material";
import ValidatePayoutModal from "../../../modals/ValidataePayoutModal";

const PayoutOverview = () => {
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientId, setClientId] = useState("");
  const [amount, setAmount] = useState<number>(0); 
  const [fees, setFees] = useState("");
  const [openValidatePayoutModal, setOpenValidatePayoutModal] = useState(false);
  const [credentials, setCredentials] = React.useState({});

  const handleCloseValidatePayoutModal = () =>
    setOpenValidatePayoutModal(false);

  const handleValidatePayout = async (clientId: string, amount: number) => {
 
    setOpenValidatePayoutModal(true);
      const credentials = {
        clientId: clientId,
        amount: Number(amount),
      };
    setCredentials(credentials)
  };

  const handleClientIdTextChange = (event: any) => {
    setClientId(event.target.value);
  };
  const handleAmountTextChange = (event: any) => {
    setAmount(event.target.value);
  };
  const handleFeeTextChange = (event: any) => {
    setFees(event.target.value);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "25px",
        justifyContent: "flex-start",
        padding: "30px",
        margin: "30px auto ",
        alignItems: "flex-start",
        boxShadow: 1,
        borderRadius: "8px",
        width: "80%",
      }}
    >
      <Typography sx={{ fontSize: "14px", fontWeight: 600, marginBottom: 0 }}>
        Manual Payout
      </Typography>
      <Box
        sx={{
          display: "flex",
          // justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          gap: "30px",
          // margin: "20px auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            // justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column", 
          }}
        >
          <TextField
            type="text"
            placeholder="Enter Client ID"
            variant="outlined"
            fullWidth
            value={clientId}
            onChange={handleClientIdTextChange}
            sx={{
              marginTop: "5px",
              "&:focus": {
                border: "2px solid red",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column", 
          }}
        >
          <TextField
            type="number"
            placeholder="Enter Amount"
            variant="outlined"
            fullWidth
            value={amount}
            onChange={handleAmountTextChange}
            sx={{
              marginTop: "5px",
              "&:focus": {
                border: "2px solid red",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            // justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column", 
          }}
        >
<TextField
          type="text"
          placeholder="Enter Fee"
          variant="outlined"
          fullWidth
          value={fees}
          onChange={handleFeeTextChange}
          sx={{
            marginTop: "5px",
            "&:focus": {
              border: "2px solid red",
              // width: "100%",
            },
          }}
        />
        </Box>
        
      </Box>
      {/* Buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          gap: "50px",
          // margin: "20px auto",
        }}
      >

        <Button
          sx={{
            height: "55px",
            backgroundColor: "#F59E0B",
            width: "50%",
            "&:hover": {
              backgroundColor: "#E18D00", // Change this to your desired hover color
            },
          }}
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => handleValidatePayout(clientId, amount)}
          disabled={!clientId || !amount || !fees}
        >
          {loading ? (
            <CircularProgress sx={{ width: "10px", color: "white" }} />
          ) : (
            <Typography sx={{ display: "flex" }}>Send</Typography>
          )}
        </Button>
      </Box>
      <ValidatePayoutModal
        open={openValidatePayoutModal}
        handleCloseValidatePayoutModal={handleCloseValidatePayoutModal}
        credentials={credentials}
      />
    </Box>
  );
};

export default PayoutOverview;

import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  isUsing2FA: localStorage.getItem("isUsing2FA"),
};

export const isUsing2FASlice = createSlice({
  name: "isusing2fa",
  initialState,
  reducers: {
    change2FAStatus: (state, action) => {
      state.isUsing2FA = action.payload;
    },
  },
});

export const { change2FAStatus } = isUsing2FASlice.actions;
export default isUsing2FASlice.reducer;

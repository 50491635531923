import MerchantPaymentOverview from "../../../components/pages/finance/merchantpayment/MerchantPaymentOverview";
const MerchantPayment = () => {
  return (
    <div>
      <MerchantPaymentOverview />
    </div>
  );
};

export default MerchantPayment;

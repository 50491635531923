import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Endpoints } from "../../../api/EndPoints";
import axios from "axios";
import {  toast } from "react-toastify";


const initialState: any = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null,
  data: [],
};

export const GetMerchantRates = createAsyncThunk(
  "merchantrates",
  async () => {
    try {
      const response = await axios.get(
        Endpoints.ManageRates.AllMerchantRate,
        {
          headers: {
            Accept: "application/json",
          "Content-Type": "application/json",
           Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );     
       toast.success("SUCCESS");
    return response.data.datas;
    } catch (error: any) {       
       toast.error("ERROR");
      if (error.response && error.response.data.message) {
        return (error.response.data.message);
      } else {
        return (error.message);
      }
    }
  }
);

export const merchantRateSlice = createSlice({
  name: "merchantrates",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetMerchantRates.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetMerchantRates.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(GetMerchantRates.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
        state.data = [];
      })
  },
});

export default merchantRateSlice.reducer;

import TopupOverview from "../../../components/pages/finance/topup/TopupOverview";
const Topup = () => {
  return (
    <div>
      <TopupOverview />
    </div>
  );
};

export default Topup;

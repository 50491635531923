import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Endpoints } from "../../../api/EndPoints";
import axios from "axios";
import { toast } from "react-toastify";
const initialState: any = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null,
  data: null,
};

export const GetCustomerList = createAsyncThunk(
  "customerlist",
  async () => {
    try {
      const response = await axios.get(
        `${Endpoints.Customer.customerList} `,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success(response.data.responseMessage);
      return response.data;
    } catch (error: any) {
      console.log(error.response.data.responseMessage);
      if (error.response && error.response.data.responseMessage) {
        return   error.response.data.responseMessage ;
      } else {
        return   error.response.data.responseMessage ;
      }
    }
  }
);

export const customerListSlice = createSlice({
  name: "customerlist",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetCustomerList.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetCustomerList.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(GetCustomerList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
        state.data = null;
      });
  },
});

export default customerListSlice.reducer;

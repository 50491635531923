import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Button,
  CardMedia,
  CircularProgress,
  TextareaAutosize,
  Skeleton,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ActivateCustomer, GetRetrieveDetail } from "../../../../api/Data";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SummaryModal from "../../../modals/SummaryModal";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import Compliance from "../../../../pages/private/Compliance";
import { ROUTES } from "../../../constants/RouteData";

const LoginArrow = `${process.env.PUBLIC_URL}/assets/images/loginarrow.svg`;

const label = { inputProps: { "aria-label": "Checkbox demo" } };

// Tab Functions
function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MerchantTableSummary = () => {
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingCall, setLoadingCall] = useState(false);
  const [activationMessage, setActivationMessage] = useState("");
  const [data, setData] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [activationData, setActivationDate] = useState("");
  const [comment, setComment] = useState("");
  const [documentDescription, setDocumentDescription] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [documentUrl, setDocumentUrl] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [docMerchantId, setDocMerchantId] = useState("");
  const [documentStatus, setDocumentStatus] = useState("");
  const [uploadDate, setUploadDate] = useState("");
  const [modalData, setModalData] = useState<any>([
    activationData,
    comment,
    documentDescription,
  ]);
  const pathname = window.location.pathname;
  const merchantId = pathname.split("/")[2]; // Assuming "/summary/:id" route pattern

  const handleOpenII = (
    actDate: any,
    comment: any,
    docDescription: any,
    docName: any,
    docType: any,
    docUrl: any,
    docId: any,
    docMId: any,
    docStatus: any,
    docUpDate: any
  ) => {
    setOpen(true);
    setActivationDate(actDate);
    setComment(comment);
    setDocumentDescription(docDescription);
    setDocumentName(docName);
    setDocumentType(docType);
    setDocumentUrl(docUrl);
    setDocumentId(docId);
    setDocMerchantId(docMId);
    setDocumentStatus(docStatus);
    setUploadDate(docUpDate);
    setModalData({
      actDate: actDate,
      comment: comment,
      docDescription: docDescription,
      docName: docName,
      docType: docType,
      docUrl: docUrl,
      docId: docId,
      docMId: docMId,
      docStatus: docStatus,
      docUpDate: docUpDate,
    });
  };
  const handleClose = () => setOpen(false);
  useEffect(() => {
    const fetchRetrieveDetail = async () => {
      try {
        const data = await GetRetrieveDetail(merchantId);
        setData(data?.datas);
        setLoading(false);
        toast.success("Success");
      } catch (error) {
        toast.error("Failed");
        setLoading(false); // Set loading to false in case of an error
      }
    };

    fetchRetrieveDetail(); // Invoke the async function to fetch customer data
  }, []);

  const handleActivationChange = (event: any) => {
    setActivationMessage(event.target.value);
  };

  const handleActivation = async (userId: any) => {
    setLoadingCall(true);
    try {
      const credentials = {
        merchandId: userId,
        status: "approved",
        commentaire: activationMessage,
      };
      const result = await ActivateCustomer(credentials);
      toast.success("Account Activation Successful");
      setLoadingCall(false);
      setTimeout(() => {
        history.push(ROUTES.MERCHANT_LIST);
        window.location.reload();
      }, 3400);
    } catch (error) {
      toast.error("Account Activation Failed");
      setLoadingCall(false);
    }
  };

  const handleSuspend = async (userId: any) => {
    setLoadingCall(true);
    try {
      const credentials = {
        merchandId: userId,
        status: "suspended",
        commentaire: activationMessage,
      };
      const result = await ActivateCustomer(credentials);
      toast.success("Account Suspended Successful");
      setLoadingCall(false);
      setTimeout(() => {
        history.push(ROUTES.MERCHANT_LIST);
        window.location.reload();
      }, 3400);
    } catch (error) {
      toast.error("Account Suspend Failed");
      setLoadingCall(false);
    }
  };
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  if (merchantId === "null") {
    return <Box>Merchant ID is invalid</Box>;
  }

  if (loading && data === null) {
    return (
      <>
        <ToastContainer />
        <Skeleton
          variant="rectangular"
          sx={{
            height: "140vh",
            backgroundColor: "#FFFFFF",
            border: "solid 1px #E0E0E0",
            borderRadius: "8px",
          }}
          animation="wave"
        />
      </>
    );
  }
  return (
    <Box sx={{ textAlign: "left" }}>
      <ToastContainer />
      {/* Tabs Head*/}
      <Box sx={{ borderBottom: 0, marginBottom: "10px", marginTop: "30px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            borderBottom: "none", // Remove the default bottom border
          }}
        >
          <Tab
            label="Account Information"
            className={value === 0 ? "activeTab" : ""}
            sx={{
              border: value === 0 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 0 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(0)}
          />
          <Tab
            label="Manage Account"
            className={value === 1 ? "activeTab" : ""}
            sx={{
              border: value === 1 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 1 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      {/* Tabs Content */}
      <Box sx={{}} aria-label="scrollable auto tabs example">
        {/* All Account List */}
        <TabPanel value={value} index={0}>
          <Typography>
            <Box>
              {/* User Profile */}
              <Box
                sx={{
                  border: "1px solid #F1F3F9",
                  borderRadius: "8px",
                  padding: "22px 40px",
                }}
              >
                <Typography
                  sx={{ fontSize: "18px", color: "#45464E", fontWeight: 600 }}
                >
                  Step 1. User Profile
                </Typography>
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gridGap: "10px",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                      First Name
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#45464E",
                        marginTop: "12px",
                      }}
                    >
                      {data?.usersProfile.firstname === null
                        ? "N/A"
                        : data?.usersProfile.firstname}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                      Last Name
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#45464E",
                        marginTop: "12px",
                      }}
                    >
                      {data?.usersProfile.lastname === null
                        ? "N/A"
                        : data?.usersProfile.lastname}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                      Phone Number
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#45464E",
                        marginTop: "12px",
                      }}
                    >
                      {data?.usersProfile.phoneNo === null
                        ? "N/A"
                        : data?.usersProfile.phoneNo}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                      Email
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#45464E",
                        marginTop: "12px",
                      }}
                    >
                      {data?.usersProfile.email === null
                        ? "N/A"
                        : data?.usersProfile.email}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    backgroundColor: "#F5F5F5",
                    borderRadius: "8px",
                    display: "flex",
                    padding: "2px 5px 2px 13px",
                    width: "max-content",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "20px",
                    marginLeft: "auto",
                  }}
                >
                  <Typography sx={{ color: "#B2B2B2" }}>Checked</Typography>
                  <Checkbox sx={{ color: "#B2B2B2" }} {...label} />
                </Box>
              </Box>
              {/* Account information */}
              <Box
                sx={{
                  border: "1px solid #F1F3F9",
                  borderRadius: "8px",
                  padding: "22px 40px",
                  marginTop: "40px",
                }}
              >
                <Typography
                  sx={{ fontSize: "18px", color: "#45464E", fontWeight: 600 }}
                >
                  Step 2. Account information
                </Typography>
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr 1fr",
                    gridGap: "10px",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                      Legal business name
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#45464E",
                        marginTop: "12px",
                      }}
                    >
                      {data?.accountInformation.legalBusinessName === null
                        ? "N/A"
                        : data?.accountInformation.legalBusinessName}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                      Description
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#45464E",
                        marginTop: "12px",
                      }}
                    >
                      {data?.accountInformation.description === null
                        ? "N/A"
                        : data?.accountInformation.description}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                      Type of business
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#45464E",
                        marginTop: "12px",
                      }}
                    >
                      {data?.accountInformation.typeOfBusiness === null
                        ? "N/A"
                        : data?.accountInformation.typeOfBusiness}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gridGap: "10px",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                      App name
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#45464E",
                        marginTop: "12px",
                      }}
                    >
                      {data?.accountInformation.appName === null
                        ? "N/A"
                        : data?.accountInformation.appName}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                      Industry
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#45464E",
                        marginTop: "12px",
                      }}
                    >
                      {data?.accountInformation.industry === null
                        ? "N/A"
                        : data?.accountInformation.industry}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                      Category
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#45464E",
                        marginTop: "12px",
                      }}
                    >
                      {data?.accountInformation.category === null
                        ? "N/A"
                        : data?.accountInformation.category}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                      Staff size
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#45464E",
                        marginTop: "12px",
                      }}
                    >
                      {data?.accountInformation.staff === null
                        ? "N/A"
                        : data?.accountInformation.staff}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    backgroundColor: "#F5F5F5",
                    borderRadius: "8px",
                    display: "flex",
                    padding: "2px 5px 2px 13px",
                    width: "max-content",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "20px",
                    marginLeft: "auto",
                  }}
                >
                  <Typography sx={{ color: "#B2B2B2" }}>Checked</Typography>
                  <Checkbox sx={{ color: "#B2B2B2" }} {...label} />
                </Box>
              </Box>
              {/* Business information */}
              <Box
                sx={{
                  border: "1px solid #F1F3F9",
                  borderRadius: "8px",
                  padding: "22px 40px",
                  marginTop: "40px",
                }}
              >
                <Typography
                  sx={{ fontSize: "18px", color: "#45464E", fontWeight: 600 }}
                >
                  Step 3. Business information
                </Typography>
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gridGap: "10px",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                      Registration number
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#45464E",
                        marginTop: "12px",
                      }}
                    >
                      {data?.businessInformation === null
                        ? "N/A"
                        : data?.businessInformation.registrationNumber}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                      Tax number
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#45464E",
                        marginTop: "12px",
                      }}
                    >
                      {data?.businessInformation === null
                        ? "N/A"
                        : data?.businessInformation.taxNumber}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gridGap: "10px",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                      Registration Doc
                    </Typography>
                    <Typography
                      sx={{
                        width: "80%",
                        borderRadius: "4px",
                        fontSize: "13px",
                        color: "#B2B2B2",
                        marginTop: "12px",
                        border: "1px solid #B2B2B2",
                        padding: "9px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleRoundedIcon sx={{ marginRight: "8px" }} />
                      {data?.businessInformation === null ? "N/A" : "N:A"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                      Tax Document
                    </Typography>
                    <Typography
                      sx={{
                        width: "80%",
                        borderRadius: "4px",
                        fontSize: "13px",
                        color: "#B2B2B2",
                        marginTop: "12px",
                        border: "1px solid #B2B2B2",
                        padding: "9px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleRoundedIcon sx={{ marginRight: "8px" }} />
                      {data?.businessInformation === null ? "N/A" : "N:A"}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    backgroundColor: "#F5F5F5",
                    borderRadius: "8px",
                    display: "flex",
                    padding: "2px 5px 2px 13px",
                    width: "max-content",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "20px",
                    marginLeft: "auto",
                  }}
                >
                  <Typography sx={{ color: "#B2B2B2" }}>Checked</Typography>
                  <Checkbox sx={{ color: "#B2B2B2" }} {...label} />
                </Box>
              </Box>
              {/* Contact information */}
              <Box
                sx={{
                  border: "1px solid #F1F3F9",
                  borderRadius: "8px",
                  padding: "22px 40px",
                  marginTop: "40px",
                }}
              >
                <Typography
                  sx={{ fontSize: "18px", color: "#45464E", fontWeight: 600 }}
                >
                  Step 4. Contact information
                </Typography>
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gridGap: "10px",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                      Business email
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#45464E",
                        marginTop: "12px",
                      }}
                    >
                      {data?.contactInformation === null
                        ? "N/A"
                        : data?.contactInformation.businessEmail}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                      Phone number
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#45464E",
                        marginTop: "12px",
                      }}
                    >
                      {data?.contactInformation === null
                        ? "N/A"
                        : data?.contactInformation.phoneNo}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                      Website
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#45464E",
                        marginTop: "12px",
                      }}
                    >
                      {data?.contactInformation === null
                        ? "N/A"
                        : data?.contactInformation.website}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gridGap: "10px",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                      Office address
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#45464E",
                        marginTop: "12px",
                      }}
                    >
                      {data?.contactInformation === null
                        ? "N/A"
                        : data?.contactInformation.officeAddress}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                      Office address doc
                    </Typography>
                    <Typography
                      sx={{
                        width: "80%",
                        borderRadius: "4px",
                        fontSize: "13px",
                        color: "#B2B2B2",
                        marginTop: "12px",
                        border: "1px solid #B2B2B2",
                        padding: "9px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleRoundedIcon sx={{ marginRight: "8px" }} />
                      {data?.contactInformation === null ? "N/A" : "N:A"}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    backgroundColor: "#F5F5F5",
                    borderRadius: "8px",
                    display: "flex",
                    padding: "2px 5px 2px 13px",
                    width: "max-content",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "20px",
                    marginLeft: "auto",
                  }}
                >
                  <Typography sx={{ color: "#B2B2B2" }}>Checked</Typography>
                  <Checkbox sx={{ color: "#B2B2B2" }} {...label} />
                </Box>
              </Box>
              {/*  Owner’s information */}
              {data?.accountInformation.typeOfBusiness ===
                "REGISTREDBUSINESS" && (
                <Box
                  sx={{
                    border: "1px solid #F1F3F9",
                    borderRadius: "8px",
                    padding: "22px 40px",
                    marginTop: "40px",
                  }}
                >
                  <Typography
                    sx={{ fontSize: "18px", color: "#45464E", fontWeight: 600 }}
                  >
                    Step 5. Owner’s information
                  </Typography>
                  <Box
                    sx={{
                      marginTop: "20px",
                      display: "grid",
                      gridTemplateColumns: "repeat(4, 1fr)",
                      gridGap: "10px",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                        First name
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "#45464E",
                          marginTop: "12px",
                        }}
                      >
                        {data?.ownersInformation === null ? "N/A" : "N:A"}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                        Last name
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "#45464E",
                          marginTop: "12px",
                        }}
                      >
                        {data?.ownersInformation === null ? "N/A" : "N:A"}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                        Nationality
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "#45464E",
                          marginTop: "12px",
                        }}
                      >
                        {data?.ownersInformation === null ? "N/A" : "N:A"}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                        Role
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "#45464E",
                          marginTop: "12px",
                        }}
                      >
                        {data?.ownersInformation === null ? "N/A" : "N:A"}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      marginTop: "20px",
                      display: "grid",
                      gridTemplateColumns: "repeat(4, 1fr)",
                      gridGap: "10px",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                        Date of birth
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "#45464E",
                          marginTop: "12px",
                        }}
                      >
                        {data?.ownersInformation === null ? "N/A" : "N:A"}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                        Document selection
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "#45464E",
                          marginTop: "12px",
                        }}
                      >
                        {data?.ownersInformation === null ? "N/A" : "N:A"}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                        Identification number
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "#45464E",
                          marginTop: "12px",
                        }}
                      >
                        {data?.ownersInformation === null ? "N/A" : "N:A"}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                        ID Doc
                      </Typography>
                      <Typography
                        sx={{
                          width: "80%",
                          borderRadius: "4px",
                          fontSize: "13px",
                          color: "#B2B2B2",
                          marginTop: "12px",
                          border: "1px solid #B2B2B2",
                          padding: "9px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CheckCircleRoundedIcon sx={{ marginRight: "8px" }} />
                        {data?.ownersInformation === null ? "N/A" : "N:A"}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: "#F5F5F5",
                      borderRadius: "8px",
                      display: "flex",
                      padding: "2px 5px 2px 13px",
                      width: "max-content",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "20px",
                      marginLeft: "auto",
                    }}
                  >
                    <Typography sx={{ color: "#B2B2B2" }}>Checked</Typography>
                    <Checkbox sx={{ color: "#B2B2B2" }} {...label} />
                  </Box>
                </Box>
              )}
              {/*  Payout information */}
              <Box
                sx={{
                  border: "1px solid #F1F3F9",
                  borderRadius: "8px",
                  padding: "22px 40px",
                  marginTop: "40px",
                }}
              >
                <Typography
                  sx={{ fontSize: "18px", color: "#45464E", fontWeight: 600 }}
                >
                  {data?.accountInformation.typeOfBusiness ===
                  "REGISTREDBUSINESS"
                    ? "Step 6. Payout information"
                    : "Step 5. Payout information"}
                </Typography>
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gridGap: "10px",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                      Bank name
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#45464E",
                        marginTop: "12px",
                      }}
                    >
                      {data?.payoutInformation === null
                        ? "N/A"
                        : data?.payoutInformation.bankName}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                      Bank account number
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#45464E",
                        marginTop: "12px",
                      }}
                    >
                      {data?.payoutInformation === null ? "N/A" : "N:A"}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gridGap: "10px",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                      Mobile money number: MTN
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#45464E",
                        marginTop: "12px",
                      }}
                    >
                      {data?.payoutInformation === null
                        ? "N/A"
                        : data?.payoutInformation.mtnBeninNumber}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "16px", color: "#B2B2B2" }}>
                      Mobile money number: MOOV
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#45464E",
                        marginTop: "12px",
                      }}
                    >
                      {data?.payoutInformation === null
                        ? "N/A"
                        : data?.payoutInformation.moovBeninNumber}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    backgroundColor: "#F5F5F5",
                    borderRadius: "8px",
                    display: "flex",
                    padding: "2px 5px 2px 13px",
                    width: "max-content",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "20px",
                    marginLeft: "auto",
                  }}
                >
                  <Typography sx={{ color: "#B2B2B2" }}>Checked</Typography>
                  <Checkbox sx={{ color: "#B2B2B2" }} {...label} />
                </Box>
              </Box>
              {/*  View Documents */}
              <Box
                sx={{
                  border: "1px solid #F1F3F9",
                  borderRadius: "8px",
                  padding: "22px 40px",
                  marginTop: "40px",
                }}
              >
                <Typography
                  sx={{ fontSize: "18px", color: "#45464E", fontWeight: 600 }}
                >
                  {" "}
                  {data?.accountInformation.typeOfBusiness ===
                  "REGISTREDBUSINESS"
                    ? "Step 7. View Documents"
                    : "Step 6. View Documents"}
                </Typography>
                <Box sx={{ marginTop: "20px" }}>
                  <TableContainer sx={{ boxShadow: 0 }} component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Document</TableCell>
                          <TableCell align="center">Status</TableCell>
                          <TableCell align="center">View</TableCell>
                        </TableRow>
                      </TableHead>
                      {data?.documentList.length === 0 ? (
                        <Box
                          sx={{
                            height: "50px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          No Document To Show
                        </Box>
                      ) : (
                        <TableBody>
                          {data?.documentList.map((row: any) => (
                            <TableRow
                              key={row.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{ width: "70%" }}
                              >
                                <Typography
                                  sx={{
                                    width: "100%",
                                    borderRadius: "4px",
                                    fontSize: "12px",
                                    color: "#B2B2B2",
                                    border: "1px solid #B2B2B2",
                                    padding: "9px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <CheckCircleRoundedIcon
                                    sx={{ marginRight: "8px" }}
                                  />
                                  {row.documentName}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    color: "white",
                                    backgroundColor:
                                      row.status === "ACCEPTED"
                                        ? "green"
                                        : row.status === "REJECTED"
                                        ? "red"
                                        : "gray",
                                    padding: "5px 15px",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {row.status === "ACCEPTED"
                                    ? "APPROVED"
                                    : row.status === "REJECTED"
                                    ? "REJECTED"
                                    : "PROCESSING"}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  //  onClick={handleOpen}
                                  onClick={() =>
                                    handleOpenII(
                                      String(row.activationDate),
                                      String(row.comment),
                                      String(row.documentDescription),
                                      String(row.documentName),
                                      String(row.documentType),
                                      String(row.documentURL),
                                      String(row.id),
                                      String(row.merchantId),
                                      String(row.status),
                                      String(row.uploadDate)
                                    )
                                  }
                                >
                                  <MoreVertOutlinedIcon
                                    sx={{ cursor: "pointer" }}
                                  />
                                </Button>
                                <SummaryModal
                                  open={open}
                                  onClose={handleClose}
                                  data={modalData}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Box>
                <Box
                  sx={{
                    backgroundColor: "#F5F5F5",
                    borderRadius: "8px",
                    display: "flex",
                    padding: "2px 5px 2px 13px",
                    width: "max-content",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "20px",
                    marginLeft: "auto",
                  }}
                >
                  <Typography sx={{ color: "#B2B2B2" }}>Checked</Typography>
                  <Checkbox sx={{ color: "#B2B2B2" }} />
                </Box>
              </Box>
              {/* Buttons */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px auto",
                  width: "60%",
                }}
              >
                <Link
                  to={ROUTES.MERCHANT_LIST}
                  style={{
                    textDecoration: "none",
                    color: "#F59E0B",
                    marginRight: "20px",
                    width: "100%",
                  }}
                >
                  <Button
                    sx={{
                      color: "#F59E0B",
                      height: "50px",
                      border: "solid 1px #F59E0B",
                      backgroundColor: "#FFF",
                      "&:hover": {
                        backgroundColor: "whitesmoke", // Change this to your desired hover color
                      },
                    }}
                    type="submit"
                    variant="contained"
                    fullWidth
                    // onClick={onClose}
                  >
                    Back
                  </Button>
                </Link>
              </Box>
            </Box>
          </Typography>
        </TabPanel>
        {/* Manage Account */}
        <TabPanel value={value} index={1}>
          <Box
            sx={{
              border: "1px solid #F1F3F9",
              borderRadius: "8px",
              padding: "22px 40px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Typography
                sx={{ fontSize: "18px", color: "#45464E", fontWeight: 600 }}
              >
                {data?.accountInformation.accountStatus === "ACTIVE"
                  ? "Suspend Account"
                  : "Activate Account"}
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: { sm: "100%", md: "50%" },
                margin: "auto",
                textAlign: "center",
              }}
            >
              {data?.accountInformation.accountStatus === "ACTIVE" ? (
                <Typography>
                  This Account is currently
                  <b style={{ color: "green" }}>
                    {data?.accountInformation.accountStatus}
                  </b>
                  <br /> Are you sure you want to Suspend It?
                </Typography>
              ) : data?.accountInformation.accountStatus === "SUSPENDED" ? (
                <Typography>
                  This Account is currently
                  <b style={{ color: "red" }}>
                    {data?.accountInformation.accountStatus}
                  </b>
                  <br /> Are you sure you want to Activate It?
                </Typography>
              ) : (
                <Typography>
                  Go to
                  <Link to={ROUTES.ACTIVATION_REQUEST}>
                    <b style={{ textDecoration: "underline" }}>Compliance</b>
                  </Link>
                  to activate
                </Typography>
              )}
            </Box>
            {data?.accountInformation.accountStatus !== "INACTIVE" && (
              <Box>
                <Box sx={{ width: { sm: "100%", md: "50%" }, margin: "auto" }}>
                  <TextareaAutosize
                    minRows={3}
                    maxRows={6}
                    placeholder="Type your comment here..."
                    value={activationMessage}
                    onChange={handleActivationChange}
                    style={{
                      width: "100%",
                      padding: "10px",
                      marginTop: "15px",
                    }}
                  />
                </Box>
                {/* API Buttons */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "30px auto 0",
                    width: "60%",
                  }}
                >
                  <Button
                    sx={{
                      marginRight: "20px",
                      color: "#F59E0B",
                      height: "50px",
                      border: "solid 1px #F59E0B",
                      backgroundColor: "#FFF",
                      "&:hover": {
                        backgroundColor: "whitesmoke", // Change this to your desired hover color
                      },
                    }}
                    type="submit"
                    variant="contained"
                    fullWidth
                  >
                    Cancel
                  </Button>
                  {data?.accountInformation.accountStatus === "SUSPENDED" ? (
                    <Button
                      sx={{
                        height: "50px",
                        backgroundColor: "#F59E0B",
                        "&:hover": {
                          backgroundColor: "#E18D00", // Change this to your desired hover color
                        },
                      }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        handleActivation(data?.accountInformation.merchantId)
                      }
                      fullWidth
                    >
                      {loadingCall ? (
                        <CircularProgress
                          sx={{ width: "10px", color: "white" }}
                        />
                      ) : (
                        <Box>
                          <Typography sx={{ display: "flex" }}>
                            Activate
                            <CardMedia
                              component="img"
                              width="40px"
                              image={LoginArrow}
                              alt="arrow"
                              sx={{
                                objectFit: "contain",
                                marginLeft: "8px",
                                "&:hover": {
                                  marginLeft: "8.5px",
                                },
                              }}
                            />
                          </Typography>
                        </Box>
                      )}
                    </Button>
                  ) : data?.accountInformation.accountStatus === "INACTIVE" ? (
                    <></>
                  ) : (
                    <Button
                      sx={{
                        height: "50px",
                        backgroundColor: "red",
                        "&:hover": {
                          backgroundColor: "red", // Change this to your desired hover color
                        },
                      }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        handleSuspend(data?.accountInformation.merchantId)
                      }
                      fullWidth
                    >
                      {loadingCall ? (
                        <CircularProgress
                          sx={{ width: "10px", color: "white" }}
                        />
                      ) : (
                        <Typography sx={{ display: "flex" }}>
                          Suspend
                          <CardMedia
                            component="img"
                            width="40px"
                            image={LoginArrow}
                            alt="arrow"
                            sx={{
                              objectFit: "contain",
                              marginLeft: "8px",
                              "&:hover": {
                                marginLeft: "8.5px",
                              },
                            }}
                          />
                        </Typography>
                      )}
                    </Button>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </TabPanel>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            border: "solid 1px #E0E0E0",
            borderRadius: "8px",
            marginTop: "1rem",
          }}
        ></Box>
      </Box>
    </Box>
  );
};

export default MerchantTableSummary;

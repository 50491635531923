import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Endpoints } from "../../../api/EndPoints";
import axios from "axios";
import { toast } from "react-toastify";

const initialState: any = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null,
  data: [],
};

export const GetAllAccount = createAsyncThunk(
  "allaccount",
  async (credential: any) => { 
    let url  
         if(credential.state){
            url =  `${Endpoints.User.AllAccounts}?state=${credential.state}&page=${credential.page}&size=${credential.size}`
         } else {
            url = `${Endpoints.User.AllAccounts}?page=${credential.page}&size=${credential.size}`
         }
    try {
      const response = await axios.get(url,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      ); 
        // toast.success("Successful: All Accounts"); 
      return response.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (error.response && error.response.data.message) {
        return error.response.data.message;
      } else {
        return error.message;
      }
    }
  }
);

export const allAccountSlice = createSlice({
  name: "allaccount",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetAllAccount.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        GetAllAccount.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = false;
          state.data = action.payload;
        }
      )
      .addCase(
        GetAllAccount.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
          state.data = [];
        }
      );
  },
});

export default allAccountSlice.reducer;

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CardMedia,
  Divider,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ComplianceTable from "./ComplianceTable";
import { useQuery } from "react-query";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";

import { GetActiveRequestList } from "../../../api/Data";
const ActiveIcon = `${process.env.PUBLIC_URL}/assets/images/active.svg`;
const InactiveIcon = `${process.env.PUBLIC_URL}/assets/images/inactive.svg`;
const SuspendedIcon = `${process.env.PUBLIC_URL}/assets/images/suspended.svg`;
const TogoIcon = `${process.env.PUBLIC_URL}/assets/images/togo.svg`;
const BeninIcon = `${process.env.PUBLIC_URL}/assets/images/benin.svg`;
const CotedivoireIcon = `${process.env.PUBLIC_URL}/assets/images/cotedivoire.svg`;

// Tab Functions
function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// Table Functions
function createData(
  id: number,
  name: string,
  email: string,
  status: string,
  actdate: string,
  type: string,
  credate: string,
  actidate: string,
  country: string,
  nameii: string,
  emailii: string,
  phone: string
) {
  return {
    id,
    name,
    email,
    status,
    actdate,
    type,
    credate,
    actidate,
    country,
    nameii,
    emailii,
    phone,
  };
}

// const rows = [
//   createData(
//     232001,
//     "Justin Septimus",
//     "example@email.com",
//     "inactive",
//     "14/02/2020",
//     "indidiual",
//     "14/02/2020",
//     "14/02/2020",
//     "Nigeria",
//     "Justin Septimus",
//     "example@email.com",
//     "07036060982"
//   ),
//   createData(
//     132301,
//     "Justin Septimus",
//     "example@email.com",
//     "inactive",
//     "14/02/2020",
//     "registered",
//     "14/02/2020",
//     "14/02/2020",
//     "Nigeria",
//     "Justin Septimus",
//     "example@email.com",
//     "07036060892"
//   ),
//   createData(
//     532001,
//     "Justin Septimus",
//     "example@email.com",
//     "inactive",
//     "14/02/2020",
//     "indidiual",
//     "14/02/2020",
//     "14/02/2020",
//     "Nigeria",
//     "Justin Septimus",
//     "example@email.com",
//     "07036060982"
//   ),
//   createData(
//     732001,
//     "Justin Septimus",
//     "example@email.com",
//     "inactive",
//     "14/02/2020",
//     "registered",
//     "14/02/2020",
//     "14/02/2020",
//     "Nigeria",
//     "Justin Septimus",
//     "example@email.com",
//     "07036060982"
//   ),
//   createData(
//     632001,
//     "Justin Septimus",
//     "example@email.com",
//     "inactive",
//     "14/02/2020",
//     "indidiual",
//     "14/02/2020",
//     "14/02/2020",
//     "Nigeria",
//     "Justin Septimus",
//     "example@email.com",
//     "07036060982"
//   ),
// ];

const Index = () => {
  const [ActiveRequestList, setActiveRequestList] = useState<any>("");
  const [AllActivationRequest, setAllActivationRequest] = useState<any>("");
  const [RegisteredBusinessRequest, setRegisteredBusinessRequest] =
    useState<any>("");
  const [IndividualBusinessRequest, setIndividualBusinessRequest] =
    useState<any>("");
  const [errorMessage, setErrorMessage] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchActiveRequestList = async () => {
      try {
        const data = await GetActiveRequestList();
        setActiveRequestList(data.datas.activationRequestList); // Update the state with the customer data
        setRegisteredBusinessRequest(data.datas.registeredBusinessRequest);
        setIndividualBusinessRequest(data.datas.individualBusinessRequest);
        setAllActivationRequest(data.datas.allActivationRequest);

        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        setErrorMessage(true);
        setLoading(false); // Set loading to false in case of an error
      }
    };

    fetchActiveRequestList(); // Invoke the async function to fetch customer data
  }, []);

  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  if (errorMessage) {
    return <Box>Something Went Wrong while loading your page</Box>;
  }
  return (
    <Box>
      {/* Heading */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          marginBottom: "20px",
        }}
      >
        <Typography
          sx={{ fontSize: "24px", color: "#45464E", marginBottom: "-5px" }}
        >
          Activation Requests
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            color: "#B2B2B2",
            fontWeight: 500,
            marginTop: "0",
          }}
        >
          View and approve your activation requests
        </Typography>
      </Box>
      {/* Four Cards */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gridGap: "1rem",
          margin: "0",
        }}
      >
        {/* All Activation Request */}
        {loading ? (
          <Skeleton
            variant="rectangular"
            sx={{
              height: "140px",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
            }}
            animation="wave"
          />
        ) : (
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
              padding: "25px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "#797D8C",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                All Activation Request
              </Typography>
              <CardMedia
                component="img"
                height="20px"
                image={ActiveIcon}
                alt="Icon"
                sx={{
                  objectFit: "contain",
                  marginLeft: "10px",
                  width: "20px",
                }}
              />
            </Box>
            <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
              {loading ? <CircularProgress /> : AllActivationRequest}
            </Typography>
          </Box>
        )}
        {/* Registered Business Request */}
        {loading ? (
          <Skeleton
            variant="rectangular"
            sx={{
              height: "140px",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
            }}
            animation="wave"
          />
        ) : (
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
              padding: "25px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "#797D8C",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                Registered Business Request
              </Typography>
              <BusinessIcon />
              {/* <CardMedia
                component="img"
                height="20px"
                image={InactiveIcon}
                alt="Icon"
                sx={{
                  objectFit: "contain",
                  marginLeft: "10px",
                  width: "20px",
                }}
              /> */}
            </Box>
            <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
              {loading ? <CircularProgress /> : RegisteredBusinessRequest}
            </Typography>
          </Box>
        )}
        {/* Individual Business Request */}
        {loading ? (
          <Skeleton
            variant="rectangular"
            sx={{
              height: "140px",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
            }}
            animation="wave"
          />
        ) : (
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
              padding: "25px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "#797D8C",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                Individual Business Request
              </Typography>
              <PersonIcon />
              {/* <CardMedia
                component="img"
                height="20px"
                image={SuspendedIcon}
                alt="Icon"
                sx={{
                  objectFit: "contain",
                  marginLeft: "10px",
                  width: "20px",
                }}
              /> */}
            </Box>
            <Typography sx={{ fontSize: "27px", fontWeight: "600" }}>
              {loading ? <CircularProgress /> : IndividualBusinessRequest}
            </Typography>
          </Box>
        )}
        {/* PER COUNTRY */}
        {loading ? (
          <Skeleton
            variant="rectangular"
            sx={{
              height: "140px",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
            }}
            animation="wave"
          />
        ) : (
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
              padding: "20px 25px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              sx={{
                color: "#797D8C",
                fontSize: "12px",
                fontWeight: 500,
                marginBottom: "4px",
              }}
            >
              Requests Per Country
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateRows: "1fr 1fr 1fr",
                gridGap: "5px",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CardMedia
                  component="img"
                  height="25px"
                  image={BeninIcon}
                  alt="Icon"
                  sx={{
                    objectFit: "contain",
                    marginRight: "15px",
                    width: "20px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  N/A
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CardMedia
                  component="img"
                  height="25px"
                  image={TogoIcon}
                  alt="Icon"
                  sx={{
                    objectFit: "contain",
                    marginRight: "15px",
                    width: "20px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  N/A
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CardMedia
                  component="img"
                  height="25px"
                  image={CotedivoireIcon}
                  alt="Icon"
                  sx={{
                    objectFit: "contain",
                    marginRight: "15px",
                    width: "20px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  N/A
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {loading ? (
        <Box sx={{}}>
          <Skeleton
            variant="rectangular"
            sx={{
              height: "440px",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
              marginTop: "40px",
            }}
            animation="wave"
          />
        </Box>
      ) : (
        <Box sx={{ marginTop: "40px" }}>
          <ComplianceTable data={ActiveRequestList} />
        </Box>
      )}
    </Box>
  );
};

export default Index;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Endpoints } from "../../../api/EndPoints";
import axios from "axios";
import { toast } from "react-toastify";

const initialState: any = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null,
  data: [],
};

export const UpdateDefaultRate = createAsyncThunk(
  "updatedefaultrate",
  async (credential: any, thunkAPI) => {
    const data = {
      id: credential.id,
      operationType: credential.operationType,
      operator: credential.operator,
      fee: credential.fee,
    };
    try {
      const response = await axios.post(
        Endpoints.ManageRates.UpdateRate,
        {
          id: data.id,
          operator: data.operator,
          operationType: data.operationType,
          fee: data.fee,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("SUCCESS");
      return response.data;
    } catch (error: any) {
      toast.error("ERROR");
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const updateDefaultRateSlice = createSlice({
  name: "updatedefaultrate",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(UpdateDefaultRate.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        UpdateDefaultRate.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = null;
          state.data = action.payload;
        }
      )
      .addCase(
        UpdateDefaultRate.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
          state.data = [];
        }
      );
  },
});

export default updateDefaultRateSlice.reducer;

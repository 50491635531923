import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Endpoints } from "../../../api/EndPoints";
import axios from "axios";
import { toast } from "react-toastify";

const initialState: any = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null,
  data: [],
};

export const UpdateMerchantRate = createAsyncThunk(
  "updatemerchantrate",
  async (credential: any, thunkAPI) => {
    try {
      const response = await axios.post(
        Endpoints.ManageRates.UpdateMerchantRate,
        {
          merchandId: credential.merchandId,
          clientId: credential.clientId,
          businessName: credential.businessName,
          contactInformation: credential.contactInformation,
          type: credential.type,
          country: credential.country,
          requestPayment: credential.requestPayment,
          makeDeposit: credential.makeDeposit,
          cardPayment: credential.cardPayment,
          chargeBack: credential.chargeBack,
          topUp: credential.topUp,
          transfert: credential.transfert,
          refund: credential.refund,
          payout: credential.payout,
          modifiedAt: credential.modifiedAt,
          modifiedBy: credential.modifiedBy,
          operator: credential.operator,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("SUCCESS");
      return response.data;
    } catch (error: any) {
      toast.error("ERROR");
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const updateMerchantRateSlice = createSlice({
  name: "updatemerchantrate",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(UpdateMerchantRate.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        UpdateMerchantRate.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = null;
          state.data = action.payload;
        }
      )
      .addCase(
        UpdateMerchantRate.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
          state.data = [];
        }
      );
  },
});

export default updateMerchantRateSlice.reducer;

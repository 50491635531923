import React from "react";
interface Props {
  jsonData: Record<string, any>; // Adjust the type according to your JSON structure
}

const DownloadCSV: React.FC<Props> = ({ jsonData }) => {
  const downloadCSV = () => {
    // Convert JSON to CSV format
    const csvContent =
      "data:text/csv;charset=utf-8," +
      jsonData
        .map((entry: any) => {
          const row = [];
          for (const key in entry) {
            if (Object.hasOwnProperty.call(entry, key)) {
              row.push(`${key}: ${entry[key]}`);
            }
          }
          return row.join(",");
        })
        .join("\n");

    // Create a data URI
    const encodedUri = encodeURI(csvContent);

    // Create a temporary anchor element
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "data.csv");

    // Simulate a click to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  };
  return <span onClick={downloadCSV}>Download</span>;
};

export default DownloadCSV;

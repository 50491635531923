import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CardMedia,
  Skeleton,
  CircularProgress,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import MerchantTable from "./MerchantTable";
import {
  TogoIcon,
  BeninIcon,
  CotedivoireIcon,
  ActiveIcon,
  InactiveIcon,
  MerchantIcon,
} from "../../../common/assets/icons";
import { GetAllAccount } from "../../../state/feautures/user/getAllAccountSlice";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import { GetDashboardOverview } from "../../../state/feautures/dashboard/getDashboardOverviewSlice";
import { GetMerchantList } from "../../../api/Data";
import { Endpoints } from "../../../api/EndPoints";
import { GetCustomerInformation } from "../../../state/feautures/customer/getCustomerInformationSlice";
import { ROUTES } from "../../constants/RouteData";
// Tab Functions
function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Index = () => {
  const dispatch = useAppDispatch();
  const DashboardOverview = useAppSelector(
    (state: any) => state.dashboardOverview
  );
  const DashboardOverviewData = DashboardOverview.data;
  const DashboardOverviewLoading = DashboardOverview.loading;
  const DashboardOverviewCacheStatus = DashboardOverview.cacheStatus;
  const CustomerInformationData: any = useAppSelector(
    (state) => state.customerInformation
  );
  const SQData = CustomerInformationData?.data?.datas;
  console.log("CustomerInformationData", CustomerInformationData?.data?.datas);
  const AllAccountList = useAppSelector((state: any) => state.allAccount);
  const AllAccountData = AllAccountList.data;
  const AllAccountLoading = AllAccountList.loading;
  const AllAccountErrorMessage = AllAccountList.error;
  const [value, setValue] = useState(0);
  const [merchants, setMerchants] = useState([]);
  const [error, setError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [sQErrorMessage, setSQErrorMessage] = useState("");
  useEffect(() => {
    if (DashboardOverviewCacheStatus === "idle") {
      dispatch(GetDashboardOverview());
    }
  }, [dispatch, DashboardOverviewCacheStatus]);

  const fetchMerchants = async (credential: any) => {
    try {
      const data = await GetMerchantList(credential);
      setMerchants(data);
    } catch (error) {
      setError("Failed to fetch merchants");
    }
  };

  useEffect(() => {
    if (value === 0) {
      const credential = {
        page: 0,
        size: 10,
      };
      fetchMerchants(credential);
    } else if (value === 1) {
      const credential = {
        state: "active",
        page: 0,
        size: 10,
      };
      fetchMerchants(credential);
    } else if (value === 2) {
      const credential = {
        state: "inactive",
        page: 0,
        size: 10,
      };
      fetchMerchants(credential);
    } else if (value === 3) {
      const credential = {
        state: "suspended",
        page: 0,
        size: 10,
      };
      fetchMerchants(credential);
    }
  }, [value]);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  const handleSearchQuery = (e: any) => {
    setSQErrorMessage("");
    setSearchQuery(e.target.value);
  };
  const handleGetCustomerInformation = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(searchQuery)) {
      setSQErrorMessage("Please enter a valid email address.");
      return;
    }
    const credential = {
      email: searchQuery,
    };
    dispatch(GetCustomerInformation(credential));
  };
  if (AllAccountErrorMessage) {
    return <Box>Something Went Wrong while loading your page</Box>;
  }
  return (
    <Box sx={{ width: "100%" }}>
      {/* Heading */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          marginBottom: "20px",
        }}
      >
        <Typography
          sx={{ fontSize: "24px", color: "#45464E", marginBottom: "-5px" }}
        >
          Merchants
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            color: "#B2B2B2",
            fontWeight: 500,
            marginTop: "0",
          }}
        >
          Manage your merchants account here
        </Typography>
      </Box>
      {/* Four Cards */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gridGap: "1rem",
          margin: "0",
        }}
      >
        {/* All ACTIVE */}
        {DashboardOverviewLoading ? (
          <Skeleton
            variant="rectangular"
            sx={{
              height: "140px",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
            }}
            animation="wave"
          />
        ) : (
          <Box
            sx={{
              height: "140px",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
              padding: "14px 25px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "#797D8C",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  All Accounts
                </Typography>
                <CardMedia
                  component="img"
                  height="20px"
                  image={MerchantIcon}
                  alt="Icon"
                  sx={{
                    objectFit: "contain",
                    marginLeft: "10px",
                    width: "20px",
                  }}
                />
              </Box>
              <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                {DashboardOverviewData.totalUsers}
              </Typography>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "#797D8C",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  (V2)
                </Typography>
              </Box>
              <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                {DashboardOverviewData.totalAccountsV2}
              </Typography>
            </Box>
          </Box>
        )}
        {/* ACTIVE */}
        {DashboardOverviewLoading ? (
          <Skeleton
            variant="rectangular"
            sx={{
              height: "140px",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
            }}
            animation="wave"
          />
        ) : (
          <Box
            sx={{
              height: "140px",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
              padding: "14px 25px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "#797D8C",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  Active Accounts
                </Typography>
                <CardMedia
                  component="img"
                  height="20px"
                  image={ActiveIcon}
                  alt="Icon"
                  sx={{
                    objectFit: "contain",
                    marginLeft: "10px",
                    width: "20px",
                  }}
                />
              </Box>
              <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                {DashboardOverviewData.totalActiveUsers}
              </Typography>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "#797D8C",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  (V2)
                </Typography>
              </Box>
              <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                {DashboardOverviewData.totalActiveAccountsV2}
              </Typography>
            </Box>
          </Box>
        )}
        {/* Inactive Accounts */}
        {DashboardOverviewLoading ? (
          <Skeleton
            variant="rectangular"
            sx={{
              height: "140px",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
            }}
            animation="wave"
          />
        ) : (
          <Box
            sx={{
              height: "140px",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
              padding: "14px 25px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "#797D8C",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  Inactive Accounts
                </Typography>
                <CardMedia
                  component="img"
                  height="20px"
                  image={InactiveIcon}
                  alt="Icon"
                  sx={{
                    objectFit: "contain",
                    marginLeft: "10px",
                    width: "20px",
                  }}
                />
              </Box>
              <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                {DashboardOverviewData.totalInactiveUsers}
              </Typography>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "#797D8C",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  (V2)
                </Typography>
              </Box>
              <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
                {DashboardOverviewData.totalInactiveAccountsV2}
              </Typography>
            </Box>
          </Box>
        )}
        {/* PER COUNTRY */}
        {DashboardOverviewLoading ? (
          <Skeleton
            variant="rectangular"
            sx={{
              height: "140px",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
            }}
            animation="wave"
          />
        ) : (
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
              padding: "20px 25px",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              gap: "30px",
              height: "140px",
            }}
          >
            <Box sx={{}}>
              <Typography
                sx={{
                  color: "#797D8C",
                  fontSize: "12px",
                  fontWeight: 500,
                  marginBottom: "4px",
                }}
              >
                Accounts Per Country
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateRows: "1fr 1fr 1fr",
                  gridGap: "5px",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CardMedia
                    component="img"
                    height="25px"
                    image={BeninIcon}
                    alt="Icon"
                    sx={{
                      objectFit: "contain",
                      marginRight: "15px",
                      width: "20px",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    {DashboardOverviewData.totalBeninUsers}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CardMedia
                    component="img"
                    height="25px"
                    image={TogoIcon}
                    alt="Icon"
                    sx={{
                      objectFit: "contain",
                      marginRight: "15px",
                      width: "20px",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    {DashboardOverviewData.totalTogoUsers}
                  </Typography>
                </Box>{" "}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CardMedia
                    component="img"
                    height="25px"
                    image={CotedivoireIcon}
                    alt="Icon"
                    sx={{
                      objectFit: "contain",
                      marginRight: "15px",
                      width: "20px",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    {DashboardOverviewData.totalIvoryCoastUsers}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#797D8C",
                  fontSize: "12px",
                  fontWeight: 500,
                  marginBottom: "4px",
                }}
              >
                (V2)
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateRows: "1fr 1fr 1fr",
                  gridGap: "5px",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    {DashboardOverviewData.totalBeninAccountsV2}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    {DashboardOverviewData.totalTogoAccountsV2}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    {DashboardOverviewData.totalIvoryCoastUsers}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {/* Tabs Head*/}
      <Box sx={{ borderBottom: 0, marginBottom: "10px", marginTop: "30px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            borderBottom: "none", // Remove the default bottom border
          }}
        >
          <Tab
            label="All Accounts"
            className={value === 0 ? "activeTab" : ""}
            sx={{
              border: value === 0 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 0 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(0)}
          />
          <Tab
            label="Active Accounts"
            className={value === 1 ? "activeTab" : ""}
            sx={{
              border: value === 1 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 1 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(1)}
          />
          <Tab
            label="Inactive Account"
            className={value === 2 ? "activeTab" : ""}
            sx={{
              border: value === 2 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 2 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(2)}
          />
          <Tab
            label="Suspended Account"
            className={value === 3 ? "activeTab" : ""}
            sx={{
              border: value === 3 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 3 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(3)}
          />
                    <Tab
            label="Merchant Search"
            className={value === 4 ? "activeTab" : ""}
            sx={{
              border: value === 4 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 4 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(4)}
          />
        </Tabs>
      </Box>
      {/* Tabs Content */}
      <Box sx={{ width: "100%" }} aria-label="scrollable auto tabs example">
        {/* All Account List */}
        <TabPanel value={value} index={0}>
          {AllAccountLoading ? (
            <Box sx={{}}>
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "140px",
                  backgroundColor: "#FFFFFF",
                  border: "solid 1px #E0E0E0",
                  borderRadius: "8px",
                }}
                animation="wave"
              />
            </Box>
          ) : (
            <MerchantTable
              data={merchants}
              title="All Account List"
              value={value}
            />
          )}
        </TabPanel>
        {/* Active Account List */}
        <TabPanel value={value} index={1}>
          {AllAccountLoading ? (
            <Box sx={{}}>
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "140px",
                  backgroundColor: "#FFFFFF",
                  border: "solid 1px #E0E0E0",
                  borderRadius: "8px",
                }}
                animation="wave"
              />
            </Box>
          ) : (
            <MerchantTable
              data={merchants}
              title="Active Account List"
              value={value}
            />
          )}
        </TabPanel>
        {/* InActive Account List */}
        <TabPanel value={value} index={2}>
          {AllAccountLoading ? (
            <Box sx={{}}>
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "140px",
                  backgroundColor: "#FFFFFF",
                  border: "solid 1px #E0E0E0",
                  borderRadius: "8px",
                }}
                animation="wave"
              />
            </Box>
          ) : (
            <MerchantTable
              data={merchants}
              title="Inactive Account List"
              value={value}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={3}>
          <MerchantTable
            data={merchants}
            title="Suspended Account List"
            value={value}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
        {/* SEARCH MERCHANT */}
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "25px",
            justifyContent: "flex-start",
            padding: "30px",
            margin: "30px auto ",
            alignItems: "flex-start",
            boxShadow: 1,
            borderRadius: "8px",
          }}
        >
          <Typography
            sx={{ fontSize: "14px", fontWeight: 600, marginBottom: 0 }}
          >
            Search Merchant
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              gap: "50px",
              // margin: "20px auto",
            }}
          >
            <Box
              sx={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "column",
                gap: "25px",
              }}
            >
              <TextField
                type="text"
                placeholder="Enter merchant email"
                variant="outlined"
                fullWidth
                value={searchQuery}
                onChange={handleSearchQuery}
                sx={{
                  marginTop: "5px",
                  "&:focus": {
                    border: "2px solid red",
                  },
                }}
              />{" "}
              {sQErrorMessage && (
                <Typography
                  sx={{
                    textAlign: "left",
                    width: "100%",
                    fontSize: "12px",
                    color: "red",
                    marginTop: "-25px",
                  }}
                >
                  {sQErrorMessage}
                </Typography>
              )}
            </Box>

            {/* Buttons */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                width: "50%",
              }}
            >
              <Button
                sx={{
                  height: "55px",
                  backgroundColor: "#F59E0B",
                  "&:hover": {
                    backgroundColor: "#E18D00", // Change this to your desired hover color
                  },
                }}
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleGetCustomerInformation}
                disabled={!searchQuery}
              >
                {/* {TransactionLoading ? (
                      <CircularProgress
                        sx={{ width: "10px", color: "white" }}
                      />
                    ) : ( */}
                <Typography sx={{ display: "flex" }}>Search</Typography>
                {/* )} */}
              </Button>
            </Box>
          </Box>

          <TableContainer
            component={Paper}
            sx={{
              width: "100%",
              overflowX: "auto",
              borderRadius: "0",
              border: "none",
              boxShadow: 0,
            }}
          >
            <Table
              aria-label="collapsible table"
              sx={{ padding: 0, margin: 0 }}
            >
              <TableHead>
                <TableRow
                  sx={{ backgroundColor: "#FCDFAE1A", textTransform: "" }}
                >
                  <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>
                    Merchant ID
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>
                    Account Information
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: 600, fontSize: "14px" }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: 600, fontSize: "14px" }}
                  >
                    Type
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: 600, fontSize: "14px" }}
                  >
                    Timeline
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: 600, fontSize: "14px" }}
                  >
                    Country
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: 600, fontSize: "14px" }}
                  >
                    User Profile
                  </TableCell> 
                  <TableCell
                    align="right"
                    sx={{ fontWeight: 600, fontSize: "14px" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {TransactionData === null ? (
                      <TableRow>
                        <TableCell>Search for a transaction</TableCell>
                      </TableRow>
                    ) : TransactionData === "Transaction Not Found" ? (
                      <TableRow>
                        <TableCell>No Transaction Found</TableCell>
                      </TableRow>
                    ) : ( */}
                <>
                  {" "}
                  {SQData === undefined ? (
                    <Typography>Search for a merchant</Typography>
                  ) : (
                    <TableRow 
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {SQData?.merchanId === null || !SQData.merchanId
                          ? "N/A"
                          : SQData?.merchanId}
                      </TableCell>
                      {/* <TableCell>{row.name}{row.email}</TableCell> */}
                      <TableCell
                        align="left"
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <Box>
                          {SQData?.businessName === null || !SQData.businessName
                            ? "N/A"
                            : SQData?.businessName}
                        </Box>
                        <Box>
                          {SQData?.email === null || !SQData? "N/A" : SQData?.email}
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "left",
                            width: "100px",
                          }}
                        >
                          {SQData?.status === "INACTIVE" ? (
                            <Box
                              sx={{
                                fontSize: "11px",
                                borderRadius: "10px",
                                padding: "2px 10px",
                                backgroundColor: "#F2F0F9",
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "space-between",
                                width: "100px",
                                marginBottom: "2px",
                                color: "#6E6893",
                              }}
                            >
                              <Box
                                sx={{
                                  height: "7px",
                                  width: "7px",
                                  borderRadius: "50%",
                                  backgroundColor: "#6E6893",
                                  marginRight: "5px",
                                }}
                              ></Box>
                              {SQData?.status}
                            </Box>
                          ) : SQData?.status === "REJECTED" ? (
                            <Box
                              sx={{
                                fontSize: "11px",
                                borderRadius: "10px",
                                padding: "2px 10px",
                                backgroundColor: "#F2F0F9",
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "space-between",
                                width: "100px",
                                marginBottom: "2px",
                                color: "red",
                              }}
                            >
                              <Box
                                sx={{
                                  height: "7px",
                                  width: "7px",
                                  borderRadius: "50%",
                                  backgroundColor: "red",
                                  marginRight: "5px",
                                }}
                              ></Box>
                              {SQData?.status}
                            </Box>
                          ) : SQData?.status === "SUSPENDED" ? (
                            <Box
                              sx={{
                                fontSize: "11px",
                                borderRadius: "10px",
                                padding: "2px 10px",
                                backgroundColor: "#F2F0F9",
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "space-between",
                                width: "100px",
                                marginBottom: "2px",
                                color: "red",
                              }}
                            >
                              <Box
                                sx={{
                                  height: "7px",
                                  width: "7px",
                                  borderRadius: "50%",
                                  backgroundColor: "red",
                                  marginRight: "5px",
                                }}
                              ></Box>
                              {SQData?.status}
                            </Box>
                          ) : SQData?.status === null || !SQData.status ? (
                            <Box
                              sx={{
                                fontSize: "11px",
                                borderRadius: "10px",
                                padding: "2px 10px",
                                backgroundColor: "lightgray",
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "center",
                                width: "100px",
                                marginBottom: "2px",
                                color: "grey",
                              }}
                            >
                              <Box
                                sx={{
                                  height: "8px",
                                  width: "8px",
                                  borderRadius: "50%",
                                  backgroundColor: "grey",
                                  marginRight: "5px",
                                }}
                              ></Box>
                              N/A
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                fontSize: "11px",
                                borderRadius: "10px",
                                padding: "2px 10px",
                                backgroundColor: "#D8FFD8",
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "center",
                                width: "100px",
                                marginBottom: "2px",
                                color: "#07751A",
                              }}
                            >
                              <Box
                                sx={{
                                  height: "8px",
                                  width: "8px",
                                  borderRadius: "50%",
                                  backgroundColor: "#07751A",
                                  marginRight: "5px",
                                }}
                              ></Box>
                              {SQData?.status}
                            </Box>
                          )}
                          <Box sx={{ fontSize: "12px" }}>
                            <b>Date:</b>{" "}
                            {SQData?.activationDate === null ||
                            !SQData?.activationDate
                              ? "N/A"
                              : SQData?.activationDate.split("T")[0]}
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        {SQData?.type === "INDIVIDUAL BUSINESS" ? (
                          <Box
                            sx={{
                              backgroundColor: "#F59E0B",
                              padding: "3px 10px",
                              border: "1px solid #F59E0B",
                              borderRadius: "10px",
                              color: "#FFEEE5",
                              textAlign: "center",
                            }}
                          >
                            IND
                          </Box>
                        ) : SQData?.type === null || !SQData.type ? (
                          <Box
                            sx={{
                              backgroundColor: "lighgrey",
                              padding: "3px 10px",
                              border: "1px solid grey",
                              borderRadius: "10px",
                              color: "grey",
                              textAlign: "center",
                            }}
                          >
                            N/A
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              backgroundColor: "#FFEEE5",
                              padding: "3px 10px",
                              border: "1px solid #F59E0B",
                              borderRadius: "10px",
                              color: "#F59E0B",
                              textAlign: "center",
                            }}
                          >
                            REG
                          </Box>
                        )}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "190px",
                        }}
                      >
                        {!SQData?.createDate ? (
                          <Box sx={{ fontSize: "12px", marginBottom: "2px" }}>
                            <b>Created Date:</b>
                            N/A
                          </Box>
                        ) : (
                          <Box sx={{ fontSize: "12px", marginBottom: "2px" }}>
                            <b>Created Date:</b>
                            {SQData?.createDate === null ||
                            !SQData?.createDate
                              ? "N/A"
                              : SQData?.createDate.split("T")[0]}
                          </Box>
                        )}
                        {SQData?.activationDate === null || !SQData?.activationDate ? (
                          <Box sx={{ fontSize: "12px", marginBottom: "2px" }}>
                            <b>Activation Date:</b>
                            N/A
                          </Box>
                        ) : (
                          <Box sx={{ fontSize: "12px" }}>
                            <b>Activation Date:</b>
                            {SQData?.activationDate}
                          </Box>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {SQData?.country === null || !SQData.country ? "N/A" : SQData?.country}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <Box>
                          {SQData?.firstname === null  || !SQData.firstname ? (
                            "N/A"
                          ) : (
                            <b>
                              {SQData?.firstname} {SQData?.lastname}
                            </b>
                          )}
                        </Box>
                        <Box>
                        {SQData?.contactInformation === null || !SQData.contactInformation
                          ? "N/A"
                          : `+${SQData?.contactInformation}`}
                        </Box> 
                      </TableCell> 
                      <TableCell align="right">
                        <Link
                          to={`${ROUTES.MERCHANT_DETAILS}/${SQData?.merchandId}`}
                        >
                          <MoreVertOutlinedIcon
                            // onClick={handleOpen}

                            sx={{ cursor: "pointer" }}
                          />
                        </Link>
                      </TableCell>
                    </TableRow>
                  )}
                </>
                {/* )} */}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
        </TabPanel>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            border: "solid 1px #E0E0E0",
            borderRadius: "8px",
            marginTop: "1rem",
          }}
        ></Box>
      </Box>
    </Box>
  );
};

export default Index;
